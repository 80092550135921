import { ArrowRight } from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'
import type { searchHistoryItem } from '@/store/api/SearchFace/SearchFace.types'
import { formatDate } from '@/utils/date'

import { FaceMatchAvatars } from './FaceMatchAvatars'

interface FaceMatchItemTypes {
  data: searchHistoryItem
  isAccountReport?: boolean
}

export const FaceMatchItem = ({
  data,
  isAccountReport,
}: FaceMatchItemTypes) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const dontIsDesktop = isTablet || isSmallMobile || isMobile

  return (
    <div className="bg-baseColor rounded-lg flex flex-row justify-normal p-3 h-[65px] w-full max-lg:h-[120px] max-lg:flex-col max-lg:gap-4 lg:items-center mb-5">
      <div className="flex w-1/2 max-lg:justify-between max-lg:w-full ">
        <FaceMatchAvatars items={data.input} />
      </div>

      <div className="flex lg:w-1/3 ">
        <div className="flex" />
        <div
          className={`flex flex-col w-full ${!isAccountReport && 'gap-1.5'}`}
        >
          <p className="text-textNormal text-sm font-medium tracking-tight text-nowrap ">
            {t('dashboard.reportsScreen.faceMatch')}
          </p>
          <div
            className={`flex flex-row gap-1 items-center ${
              isAccountReport && 'mb-[-4px]'
            }  `}
          >
            {isAccountReport && (
              <>
                <p className="text-textAccent text-xs font-medium tracking-tight text-nowrap">
                  {data.requester.firstname} {data.requester.lastname}
                </p>
                <p className="mb-0.5">·</p>
              </>
            )}
            <p className="text-textAccent text-xs font-medium tracking-tight">
              {formatDate(data.createdAt, 'MM/dd/yyyy')}
            </p>
          </div>
        </div>

        {dontIsDesktop && (
          <div className="flex w-3/12 justify-end ">
            <Button
              asChild
              onClick={() =>
                navigate(AppRoutes.FaceMatchResults, { state: data._id })
              }
              size={'default'}
              isLoading={false}
              className="rounded-lg "
              variant={'default'}
            >
              <div className="flex flex-row gap-2">
                <p>{t('dashboard.reportsScreen.view')}</p>
                <ArrowRight width={20} height={20} variant="Linear" />
              </div>
            </Button>
          </div>
        )}
      </div>

      {!dontIsDesktop && (
        <div className="flex w-1/3 md:justify-end items-center">
          <Button
            asChild
            onClick={() =>
              navigate(AppRoutes.FaceMatchResults, { state: data._id })
            }
            size={'default'}
            isLoading={false}
            variant={'default'}
          >
            <div className="flex flex-row gap-2">
              <p>{t('dashboard.reportsScreen.view')}</p>
            </div>
          </Button>
        </div>
      )}
    </div>
  )
}
