import React from 'react'

import { Switch } from '@/components/ui/switch'

interface SwitchCardTypes {
  title: string
  description: string
  disabled?: boolean
  checked: boolean
  onCheckedChange: (checked: boolean) => void
}

export const BigSwitchCard = ({
  title,
  description,
  disabled,
  checked,
  onCheckedChange,
}: SwitchCardTypes) => {
  return (
    <div
      className={`lg:w-1/5 w-full flex flex-col ${
        checked
          ? 'bg-primaryColor5 border border-primaryColor30'
          : 'bg-baseColorHighlight'
      }  p-4 justify-between gap-2 rounded-lg space-y-0`}
    >
      <div className="flex flex-row justify-between gap-1">
        <p
          className={`${
            disabled ? 'text-neutralColor30' : 'text-neutralColor'
          } text-base font-medium tracking-tight `}
        >
          {title}
        </p>
        <div>
          <Switch
            className="w-12 h-6"
            checked={checked}
            onCheckedChange={onCheckedChange}
            disabled={!checked && disabled}
          />
        </div>
      </div>
      <div>
        <p
          className={`${
            disabled ? 'text-neutralColor30' : 'text-neutralColor60'
          } text-sm font-medium tracking-tight`}
        >
          {description}
        </p>
      </div>
    </div>
  )
}
