import React from 'react'

import { Card } from '@/components/ui/card'
interface SheetCardTypes {
  input: string
  content: string | undefined
}
export const InputCard = ({ input, content }: SheetCardTypes) => {
  return (
    content && (
      <Card
        className={
          'flex md:h-12 px-5 py-4 bg-primaryColor5 rounded-[200px] border-0 shadow-none justify-start items-center gap-3 '
        }
      >
        <p className="text-neutralColor text-xs font-medium tracking-tight">
          {input}
        </p>
        <p className="text-neutralColor text-xs font-medium tracking-tight">
          {content}
        </p>
      </Card>
    )
  )
}
