import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import { useUpdateEnterpriseUserRoleMutation } from '@/store/api/Enterprise/Enterprise.query'
import type { updateEnterpriseUserRoleRequest } from '@/store/api/Enterprise/Enterprise.types'

interface MembersRoleSelectTypes {
  actualRole: string
  id: string
  availableRoles: string[] | undefined
}

export const MembersRoleSelect = ({
  actualRole,
  id,
  availableRoles,
}: MembersRoleSelectTypes) => {
  const isSmallMobile = useSmallMobileMediaQuery()
  const { t } = useTranslation()

  const [updateRole, { isLoading }] = useUpdateEnterpriseUserRoleMutation()

  const updateRoleFunction = async (value: string) => {
    const updateRoleRequest: updateEnterpriseUserRoleRequest = {
      role: value,
      id: id,
    }
    try {
      await updateRole(updateRoleRequest).unwrap()
      toast.success(t('feedback.toast.roleUpdated'))
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.errorUpdatingRole'))
    }
  }

  const handleRoleChange = async (value: string) => {
    await updateRoleFunction(value)
  }

  return (
    <Select
      onValueChange={handleRoleChange}
      disabled={actualRole === 'owner'}
      value={actualRole}
    >
      <SelectTrigger
        disabled={isLoading}
        className={`flex md:w-36 h-10 justify-center gap-2 rounded-xl border border-primaryColor30 bg-primaryColor5 px-3 py-1 shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 text-textNormal text-sm font-medium tracking-widest`}
      >
        <SelectValue
          placeholder={
            (actualRole === 'operational_user' && 'Operational') ||
            (actualRole === 'view_user' && 'Reader') ||
            (actualRole === 'manager' && 'Manager') ||
            (actualRole === 'owner' && 'Owner') ||
            actualRole
          }
        />
      </SelectTrigger>
      <SelectContent className="w-56">
        <SelectGroup>
          <SelectLabel className="border-b-2 border-slate-100 h-11 p-2 text-base text-textNormal font-medium">
            Role
          </SelectLabel>
          {availableRoles?.map((role) => (
            <SelectItem
              key={role}
              className="h-11 p-2 text-sm text-textAccent font-medium"
              value={role}
            >
              {(role === 'operational_user' && 'Operational') ||
                (role === 'view_user' && 'Reader') ||
                (role === 'manager' && 'Manager')}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
