import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Trash from '@/assets/trash.svg?react'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import { getUsernameFromUrl } from '@/utils/strings'

interface SocialMediaItemTypes {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  url: string | undefined
  deleteUrl: (url: string) => void
}

export const SocialMediaItem = ({
  Icon,
  url,
  deleteUrl,
}: SocialMediaItemTypes) => {
  const active = useMemo(() => url !== undefined, [url])
  const { t } = useTranslation()
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex items-center">
        <div
          className={`p-4 border-dashed border  border-disabled rounded-full ${
            active && 'bg-primaryColor border-0'
          }`}
        >
          <Icon
            color={
              active ? weCheckPalette.baseColor : weCheckPalette.neutralColor30
            }
          />
        </div>
        <div className="ml-4">
          {url && (
            <div className="truncate">{getUsernameFromUrl(url) ?? url}</div>
          )}
          {!url && (
            <div className="text-disabled">
              {t('dashboard.newSearch.common.username')}
            </div>
          )}
        </div>
      </div>
      <div
        role={active ? 'button' : undefined}
        onClick={active ? () => deleteUrl(url!) : undefined}
        tabIndex={0}
      >
        <Trash
          color={
            active ? weCheckPalette.dynamicRed : weCheckPalette.neutralColor30
          }
        />
      </div>
    </div>
  )
}
