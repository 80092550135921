import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'
import { PlatformTypes, type Source } from '@/store/api/Person/Person.types'

import Facebook from '../../../assets/socialMedia/Facebook.svg?react'
import Instagram from '../../../assets/socialMedia/Instagram.svg?react'
import Linkedin from '../../../assets/socialMedia/LinkedIn.svg?react'
import Pinterest from '../../../assets/socialMedia/Pinterest.svg?react'
import Reddit from '../../../assets/socialMedia/Reddit.svg?react'
import Tiktok from '../../../assets/socialMedia/TikTok.svg?react'
import WebSearch from '../../../assets/socialMedia/Web.svg?react'
import X from '../../../assets/socialMedia/X.svg?react'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface SocialMediaButtonsTypes {
  sources: Source[] | undefined
  id: string
}

export const SocialMediaButtons = ({
  sources,
  id,
}: SocialMediaButtonsTypes) => {
  const navigate = useNavigate()
  const buttonsData = [
    {
      title: 'LinkedIn',
      icon: <Linkedin color={weCheckPalette.staticWhite} />,
      disable: sources?.some((source) => source.LinkedIn === false),
      onClick: () => {
        navigate(AppRoutes.postList, {
          state: {
            id,
            plataform: PlatformTypes.LINKEDIN,
          },
        })
      },
    },
    {
      title: 'Facebook',
      icon: <Facebook color={weCheckPalette.staticWhite} />,
      disable: sources?.some((source) => source.Facebook === false),
      onClick: () => {
        navigate(AppRoutes.postList, {
          state: {
            id,
            plataform: PlatformTypes.FACEBOOK,
          },
        })
      },
    },
    {
      title: 'Instagram',
      icon: <Instagram color={weCheckPalette.staticWhite} />,
      disable: sources?.some((source) => source.Instagram === false),
      onClick: () => {
        navigate(AppRoutes.postList, {
          state: {
            id,
            plataform: PlatformTypes.INSTAGRAM,
          },
        })
      },
    },
    {
      title: 'Pinterest',
      icon: <Pinterest color={weCheckPalette.staticWhite} />,
      disable: sources?.some((source) => source.Pinterest === false),
      onClick: () => {
        navigate(AppRoutes.postList, {
          state: {
            id,
            plataform: PlatformTypes.PINTEREST,
          },
        })
      },
    },
    {
      title: 'TikTok',
      icon: <Tiktok color={weCheckPalette.staticWhite} />,
      disable: sources?.some((source) => source.TikTok === false),
      onClick: () => {
        navigate(AppRoutes.postList, {
          state: {
            id,
            plataform: PlatformTypes.TIKTOK,
          },
        })
      },
    },
    {
      title: 'Twitter',
      icon: <X color={weCheckPalette.staticWhite} />,
      disable: sources?.some((source) => source.Twitter === false),
      onClick: () => {
        navigate(AppRoutes.postList, {
          state: {
            id,
            plataform: PlatformTypes.TWITTER,
          },
        })
      },
    },
    {
      title: 'Reddit',
      icon: <Reddit color={weCheckPalette.staticWhite} />,
      disable: sources?.some((source) => source.Reddit === false),
      onClick: () => {
        navigate(AppRoutes.postList, {
          state: {
            id,
            plataform: PlatformTypes.REDDIT,
          },
        })
      },
    },
    {
      title: 'WebSearch',
      disable: sources?.some((source) => source.WebSearch === false),
      icon: <WebSearch color={weCheckPalette.staticWhite} />,
      onClick: () => {
        navigate(AppRoutes.postList, {
          state: {
            id,
            plataform: PlatformTypes.WEBSEARCH,
          },
        })
      },
    },
  ]

  // Sort buttons by enables
  buttonsData.sort((a, b) => {
    const aDisabled = a.disable ? 1 : 0
    const bDisabled = b.disable ? 1 : 0
    return aDisabled - bDisabled
  })

  return (
    <div className="flex gap-4 max-md:flex-col">
      {buttonsData.map((data, index) => (
        <Button
          key={index}
          onClick={data.onClick}
          className="flex gap-3 h-[46px] px-5 py-3 rounded-lg"
          disabled={data.disable}
        >
          <p className="text-textNormal text-base font-medium tracking-wide">
            {data.title === 'Twitter' ? 'X' : data.title}
          </p>
          {data.icon}
        </Button>
      ))}
    </div>
  )
}
