import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft2 } from 'iconsax-react'
import React from 'react'
import Autocomplete from 'react-google-autocomplete'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import { selectSignUpSecondStep } from '@/store/api/SignUp/SignUp.selector'
import { setSecondStep } from '@/store/api/SignUp/SignUp.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { signUpSecondStepType } from '@/utils/schemas'
import { signUpSecondStepSchema } from '@/utils/schemas'

import Env from '../../env/env'

import { useSignUpSecondStepGuard } from './guards/useSignUpSecondStepGuard'

export const SignUpSecondStep = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  useSignUpSecondStepGuard()
  const navigation = useNavigate()
  const onPressBack = () => {
    navigation(-1)
  }
  const signUpSecondStepData = useAppSelector(selectSignUpSecondStep)

  const form = useForm<signUpSecondStepType>({
    resolver: zodResolver(signUpSecondStepSchema),
    defaultValues: {
      companyName: signUpSecondStepData.companyName || '',
      companyAddress: signUpSecondStepData.companyAddress || '',
    },
  })

  const key = Env.GOOGLE_KEY as string
  const { handleSubmit, control, setValue } = form

  const onSubmit = (data: any) => {
    dispatch(
      setSecondStep({
        companyName: data.companyName,
        companyAddress: data.companyAddress,
      }),
    )
    navigate(AppRoutes.registerFinalStep)
  }

  const Skip = () => {
    dispatch(
      setSecondStep({
        companyName: null,
        companyAddress: null,
      }),
    )
    navigate(AppRoutes.registerFinalStep)
  }

  return (
    <div className="flex flex-col gap-8 md:w-1/3 ">
      <div className="flex flex-row gap-5">
        <Button
          onClick={onPressBack}
          variant={'ghost'}
          type="button"
          className="py-3 px-2 font-medium tracking-widest border"
        >
          <ArrowLeft2 color={weCheckPalette.neutralColor} />
        </Button>
        <p className="text-textNormal text-2xl font-semibold">
          {t('dashboard.landingSingUp.singUpSecondStep.back')}
        </p>
      </div>
      <div className="flex flex-col gap-5 w-full">
        <div className="flex justify-between gap-5 items-center">
          <p className="text-[40px] font-bold tracking-wide max-w-[411px]">
            {t('dashboard.landingSingUp.singUpSecondStep.isThis')}
          </p>
          <p className="text-xl font-medium text-textAccent ">2/3</p>
        </div>
        <p className="text-1xl text-textAccent tracking-tight">
          {t('dashboard.landingSingUp.singUpSecondStep.fillOut')}
        </p>
      </div>

      <Form {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-6 "
        >
          <FormField
            control={control}
            name="companyName"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t(
                      'dashboard.landingSingUp.singUpSecondStep.companyName',
                    )}
                  />
                </FormControl>
                <FormMessage data-name="companyName" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="companyAddress"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Autocomplete
                    {...field}
                    language="en"
                    className="flex h-11 w-full rounded-md border border-neutralColor30 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                    apiKey={key}
                    placeholder={t(
                      'dashboard.landingSingUp.singUpSecondStep.enterALocation',
                    )}
                    aria-placeholder={t(
                      'dashboard.landingSingUp.singUpSecondStep.companyAddress',
                    )}
                    options={{ types: ['locality'] }}
                    onPlaceSelected={(place) => {
                      setValue('companyAddress', place.formatted_address)
                    }}
                  />
                </FormControl>
                <FormDescription>
                  {t(
                    'dashboard.landingSingUp.singUpSecondStep.companyAddressText',
                  )}
                </FormDescription>
                <FormMessage data-name="companyAddress" />
              </FormItem>
            )}
          />
          <Button
            disabled={!form.formState.isValid}
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
          >
            {t('dashboard.landingSingUp.singUpSecondStep.continue')}
          </Button>
        </form>
        <Button
          onClick={Skip}
          variant="outline"
          type="submit"
          className="py-3 px-5 font-medium tracking-widest h-11"
        >
          {t('dashboard.landingSingUp.singUpSecondStep.skip')}
        </Button>
      </Form>
    </div>
  )
}
