import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import DobleCheck from '@/assets/home/dobleCheck.svg?react'
import FacialMatching from '@/assets/home/facialMatchingIcon.svg?react'
import PendingReport from '@/assets/home/pendingReportsIcon.svg?react'
import FacialMatchingImage from '@/assets/RunSearchAssets/facialMatchingHome.png'
import SocialAnalysisImage from '@/assets/RunSearchAssets/socialAnalysisHome.png'
import { AppRoutes } from '@/constants/appRoutes'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { setInitialReportSetupToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import { useGetStatsQuery } from '@/store/api/NewSearch/NewSearch.query'
import { clearNewSearch } from '@/store/api/NewSearch/NewSearch.slice'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import {
  selectAdverseMediaReportCredits,
  selectAiAnalystReportCredits,
  selectAiReportCredits,
  selectAssistedReportCredits,
  selectFacialMatchingCredits,
  selectIsPending,
  selectItsDefaultState,
} from '@/store/api/Users/Users.selector'

import { DueDiligenceCard } from './components/DueDiligenceCard/DueDiligenceCard'
import { GetStartedCard } from './components/GetStartedCard/GetStartedCard'

export const Home = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isDefaultState = useSelector(selectItsDefaultState)

  const {
    data: userData,
    aiReportCredits,
    aiAnalystReportCredits,
    adverseMediaReportCredits,
    assistedReportCredits,
    facialMatchingCredits,
  } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      aiReportCredits: selectAiReportCredits(data),
      aiAnalystReportCredits: selectAiAnalystReportCredits(data),
      adverseMediaReportCredits: selectAdverseMediaReportCredits(data),
      assistedReportCredits: selectAssistedReportCredits(data),
      facialMatchingCredits: selectFacialMatchingCredits(data),
      data,
    }),
  })

  const { data: statsData, isLoading: isLoadingStatsData } = useGetStatsQuery()

  const { hasHandleReports, permissions } = useRedirectBasedOnPermissions()

  const { isLoading: isLoadingUserData } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      isPending: selectIsPending(data),
    }),
  })

  useEffect(() => {
    if (!isDefaultState) {
      hasHandleReports()
    }
  }, [isLoadingUserData, permissions])

  const handleSearchNavigate = () => {
    dispatch(clearNewSearch())
    dispatch(setInitialReportSetupToken({ setUseCasesToken: true }))
    navigate(AppRoutes.newSearchInitialReportSetup, {
      state: { clearForm: true },
    })
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="lg:pb-0 flex flex-col md:flex-row items-center gap-12">
        <GetStartedCard
          desktopImage={SocialAnalysisImage}
          mobileImage={SocialAnalysisImage}
          title={t('dashboard.home.socialAnalysis')}
          text={t('dashboard.home.socialAnalysisText')}
          aiReportCredits={aiReportCredits ?? 0}
          aiAnalystReportCredits={aiAnalystReportCredits ?? 0}
          adverseMediaReportCredits={adverseMediaReportCredits ?? 0}
          assistedReportCredits={assistedReportCredits ?? 0}
          onClick={
            aiReportCredits > 0 ||
            aiAnalystReportCredits > 0 ||
            adverseMediaReportCredits > 0 ||
            assistedReportCredits > 0
              ? handleSearchNavigate
              : () => navigate(AppRoutes.contactUs)
          }
        />

        <GetStartedCard
          desktopImage={FacialMatchingImage}
          mobileImage={FacialMatchingImage}
          title={t('dashboard.home.facialMatching')}
          text={t('dashboard.home.facialMatchingText')}
          facialMatchingCredits={facialMatchingCredits}
          onClick={
            facialMatchingCredits && facialMatchingCredits > 0
              ? () => navigate(AppRoutes.faceSearch)
              : () => navigate(AppRoutes.contactUs)
          }
        />
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <p className="text-neutralColor text-xl font-medium">
            {t('dashboard.home.dueDiligenceOverview')}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row gap-5">
          <DueDiligenceCard
            title={t('dashboard.home.completedReportsCard')}
            number={statsData?.info.completedReports}
            icon={<DobleCheck />}
            isLoading={isLoadingStatsData}
          />
          <DueDiligenceCard
            title={t('dashboard.home.pendingReportsCard')}
            number={statsData?.info.pendingReports}
            icon={<PendingReport />}
            isLoading={isLoadingStatsData}
          />
          <DueDiligenceCard
            title={t('dashboard.home.facialMatchingCard')}
            number={statsData?.info.faceSearchReports}
            icon={<FacialMatching />}
            isLoading={isLoadingStatsData}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <p className="text-neutralColor text-xl font-medium">
            {t('dashboard.home.balance')}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row gap-5">
          <DueDiligenceCard
            title={t('dashboard.home.aIAnalystReport')}
            number={userData?.enterprise.credits.aiAnalystReportCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
          <DueDiligenceCard
            title={t('dashboard.home.aIReport')}
            number={userData?.enterprise.credits.aiReportCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
          <DueDiligenceCard
            title={t('dashboard.home.adverseMedia')}
            number={userData?.enterprise.credits.adverseMediaReportCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
          <DueDiligenceCard
            title={t('dashboard.home.facialMatchingTitle')}
            number={userData?.enterprise.credits.facialMatchingCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
          <DueDiligenceCard
            title={t('dashboard.home.weCheckAssisted')}
            number={userData?.enterprise.credits.assistedReportCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
        </div>
      </div>
    </div>
  )
}
