import { createSlice } from '@reduxjs/toolkit'

import { LocalStorageKey } from '@/constants/localStorageKeys'
import localStorageService from '@/utils/localStorage'

export interface AuthSliceState {
  token: string | undefined
  userStatus: 'pending_on_boarding' | 'active'
  id: string | undefined
}

const initialState: AuthSliceState = {
  token: undefined,
  userStatus: 'pending_on_boarding',
  id: undefined,
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action) => {
      const session = action.payload
      localStorageService.add(LocalStorageKey.token, session.token)
      state.token = session.token
      if (session.userStatus) {
        state.userStatus = session.userStatus
      }
    },
    setUserID: (state, action) => {
      const userID = action.payload
      if (userID) {
        state.id = userID
      }
    },
    removeSession: (state) => {
      localStorageService.remove(LocalStorageKey.token)
      state.id = initialState.id
      state.token = initialState.token
      state.userStatus = initialState.userStatus
    },
  },
})
export const { setSession, setUserID, removeSession } = sessionSlice.actions

export default sessionSlice.reducer
