import env from '@/env/env'

export const firebaseConfig = {
  apiKey: env.FIREBASE_CONFIG_APIKEY,
  authDomain: env.FIREBASE_CONFIG_AUTHDOMAIN,
  projectId: env.FIREBASE_CONFIG_PROJECTID,
  storageBucket: env.FIREBASE_CONFIG_STORAGEBUCKET,
  messagingSenderId: env.FIREBASE_CONFIG_MESSAGINGSENDERID,
  appId: env.FIREBASE_CONFIG_APPID,
  measurementId: env.FIREBASE_CONFIG_MEASUREMENTID,
}
