const localStorageService = {
  add: (key: string, value: any) => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
      return true // Indica que la operación fue exitosa
    } catch (error) {
      console.error('error on add in localStorage:', error)
      return false // Indica que la operación falló
    }
  },

  remove: (key: string) => {
    try {
      localStorage.removeItem(key)
      return true // Indica que la operación fue exitosa
    } catch (error) {
      console.error('error on remove in localStorage:', error)
      return false // Indica que la operación falló
    }
  },

  get: (key: string) => {
    try {
      const storedValue = localStorage.getItem(key)
      return storedValue ? JSON.parse(storedValue) : null
    } catch (error) {
      console.error('error on get in localStorage:', error)
      return null
    }
  },
}

export default localStorageService
