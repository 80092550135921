import React from 'react'

interface IPostAnalysisCard {
  text: string
  number?: number
  frontNumber?: number
  isActive?: boolean
}

export const PostsInsightsCardTag = ({
  number,
  text,
  frontNumber,
  isActive,
}: IPostAnalysisCard) => {
  const containerClasses =
    isActive || number || frontNumber
      ? `flex flex-row justify-center items-center gap-2 py-2 px-3 border rounded-full border-primaryColor5 bg-primaryColor5`
      : 'flex flex-row justify-center items-center gap-2 py-2 px-3 border rounded-full bg-neutralColor30 border-0'

  const textClasses =
    isActive || number || frontNumber
      ? `text-sm font-normal text-neutralColor`
      : 'text-sm font-normal text-neutralColor60'

  const numberClasses = `text-sm font-normal`

  return (
    <div className={containerClasses}>
      {frontNumber && (
        <div className={`rounded-full py-[0,5px] px-[10px] bg-[#087fff]`}>
          <span className={`${numberClasses} text-[#F6F0FF]`}>
            {frontNumber}
          </span>
        </div>
      )}
      <span className={textClasses}>{text}</span>
      {number && (
        <div className={`rounded-full py-[0,5px] px-[10px] bg-[#087fff]`}>
          <span className={`${numberClasses} text-[#F6F0FF]`}>{number}</span>
        </div>
      )}
    </div>
  )
}
