import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectIsDarkMode } from '@/store/api/ui/ui.selector'
import { switchTheme } from '@/store/api/ui/ui.slice'

const useTheme = () => {
  const dispatch = useDispatch()
  const theme = useSelector(selectIsDarkMode)
  const [isDarkMode, setIsDarkMode] = useState(theme)

  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDarkMode)

    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode))

    dispatch(switchTheme(isDarkMode))
  }, [isDarkMode, dispatch])

  const toggleTheme = () => {
    setIsDarkMode((prevMode: boolean) => !prevMode)
  }

  return { isDarkMode, toggleTheme }
}

export default useTheme
