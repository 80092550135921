import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectNewSearch } from '@/store/api/NewSearch/NewSearch.selector'
import { setReportSetup } from '@/store/api/NewSearch/NewSearch.slice'
import { selectUserInfo } from '@/store/api/Users/Users.selector'
import type { pdfCustomConfig } from '@/store/api/Users/Users.types'

import { BigSwitchCard } from './BigSwitchCard'

interface cardsDataTypes {
  name: keyof pdfCustomConfig
  title: string
  description: string
}

interface ReportSetupSwitchSectionTypes {
  useDefaultValue: boolean
}

export const ReportSetupSwitchSection = ({
  useDefaultValue,
}: ReportSetupSwitchSectionTypes) => {
  const { t } = useTranslation()

  const cardsData: cardsDataTypes[] = [
    {
      name: 'pdfCoreFindings',
      title: t('dashboard.newSearch.reportSetup.coreFinding'),
      description: t('dashboard.newSearch.reportSetup.coreFindingDescription'),
    },
    {
      name: 'pdfSocialMediaAccounts',
      title: t('dashboard.newSearch.reportSetup.socialMediaAccounts'),
      description: t(
        'dashboard.newSearch.reportSetup.socialMediaAccountsDescription',
      ),
    },
    {
      name: 'pdfSentimentAnalysis',
      title: t('dashboard.newSearch.reportSetup.sentimentAnalysis'),
      description: t(
        'dashboard.newSearch.reportSetup.sentimentAnalysisDescription',
      ),
    },
    {
      name: 'pdfFlaggedBehaviors',
      title: t('dashboard.newSearch.reportSetup.flaggedBehaviors'),
      description: t(
        'dashboard.newSearch.reportSetup.flaggedBehaviorsDescription',
      ),
    },
    {
      name: 'pdfPositivePosts',
      title: t('dashboard.newSearch.reportSetup.positivePosts'),
      description: t(
        'dashboard.newSearch.reportSetup.positivePostsDescription',
      ),
    },
    {
      name: 'pdfNeutralPosts',
      title: t('dashboard.newSearch.reportSetup.moderatePosts'),
      description: t(
        'dashboard.newSearch.reportSetup.moderatePostsDescription',
      ),
    },
    {
      name: 'pdfNegativePosts',
      title: t('dashboard.newSearch.reportSetup.negativePosts'),
      description: t(
        'dashboard.newSearch.reportSetup.negativePostsDescription',
      ),
    },
    {
      name: 'pdfWebSearchPosts',
      title: t('dashboard.newSearch.reportSetup.webNewsPosts'),
      description: t('dashboard.newSearch.reportSetup.webNewsPostsDescription'),
    },
  ]
  const dispatch = useDispatch()

  const reportSetupConfig = useSelector(selectNewSearch).reportSetup
  const defaultConfigReport = useSelector(selectUserInfo).pdfConfig

  const initialValues = {
    pdfCoreFindings: useDefaultValue
      ? defaultConfigReport.pdfCoreFindings
      : reportSetupConfig.pdfCoreFindings,
    pdfFlaggedBehaviors: useDefaultValue
      ? defaultConfigReport.pdfFlaggedBehaviors
      : reportSetupConfig.pdfFlaggedBehaviors,
    pdfNegativePosts: useDefaultValue
      ? defaultConfigReport.pdfNegativePosts
      : reportSetupConfig.pdfNegativePosts,
    pdfNeutralPosts: useDefaultValue
      ? defaultConfigReport.pdfNeutralPosts
      : reportSetupConfig.pdfNeutralPosts,
    pdfPositivePosts: useDefaultValue
      ? defaultConfigReport.pdfPositivePosts
      : reportSetupConfig.pdfPositivePosts,
    pdfSentimentAnalysis: useDefaultValue
      ? defaultConfigReport.pdfSentimentAnalysis
      : reportSetupConfig.pdfSentimentAnalysis,
    pdfSocialMediaAccounts: useDefaultValue
      ? defaultConfigReport.pdfSocialMediaAccounts
      : reportSetupConfig.pdfSocialMediaAccounts,
    pdfWebSearchPosts: useDefaultValue
      ? defaultConfigReport.pdfWebSearchPosts
      : reportSetupConfig.pdfWebSearchPosts,
  }

  useEffect(() => {
    dispatch(
      setReportSetup({
        ...initialValues,
        pdfLanguage: reportSetupConfig.pdfLanguage,
        useDefaultValue: false,
      }),
    )
  }, [])

  const [formValues, setFormValues] = useState<any>(initialValues)

  const handleChange = (name: keyof pdfCustomConfig, value: boolean) => {
    const newValues = {
      ...formValues,
      [name]: value,
    }
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }))

    dispatch(
      setReportSetup({
        ...newValues,
        pdfLanguage: reportSetupConfig.pdfLanguage,
        useDefaultValue: false,
      }),
    )
  }

  return (
    <div className="flex gap-8 rounded-lg w-full justify-center">
      <div className="flex flex-col lg:flex-row flex-wrap justify-center gap-6">
        {cardsData?.map((data, index) => (
          <BigSwitchCard
            key={index}
            title={data.title}
            description={data.description}
            checked={formValues[data.name]}
            onCheckedChange={(value) => handleChange(data.name, value)}
          />
        ))}
      </div>
    </div>
  )
}
