import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './en.json'
import es from './es.json'

const resources = {
  es: {
    translation: es,
  },
  en: {
    translation: en,
  },
}

const languageDetector = new LanguageDetector(null, {
  convertDetectedLanguage: (lng) => {
    if (lng.includes('es')) {
      return 'es'
    }

    return 'en'
  },
})

i18n.use(initReactI18next).use(languageDetector).init({
  resources,
  fallbackLng: 'en',
})

export default i18n
