import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import type { ChipTypes } from './ChipType.types'

export const Chip = ({
  green,
  blue,
  primaryBlue,
  yellow,
  red,
  text,
  disabled,
}: ChipTypes) => {
  const colorMap = {
    blue: {
      bgColor: '#C6F5FF',
      textColor: '#037797',
      border: 'transparent',
    },
    green: {
      bgColor: '#C8FFD4',
      textColor: '#1E924D',
      border: 'transparent',
    },
    primaryBlue: {
      bgColor: '#087FFF',
      textColor: '#F5F5F5',
      border: '#087FFF',
    },
    disabled: {
      bgColor: 'neutral-200',
      textColor: 'neutral-400',
      border: 'transparent',
    },
    default: {
      bgColor: '',
      textColor: '',
      border: '',
    },
    yellow: {
      bgColor: '#FFFDCB',
      textColor: '#DFB014',
      border: 'transparent',
    },
    red: {
      bgColor: weCheckPalette.dynamicRed,
      textColor: weCheckPalette.alertRedSecondary,
      border: weCheckPalette.dynamicRed,
    },
  }

  const { bgColor, textColor, border } =
    colorMap[
      blue
        ? 'blue'
        : green
          ? 'green'
          : primaryBlue
            ? 'primaryBlue'
            : yellow
              ? 'yellow'
              : red
                ? 'red'
                : disabled
                  ? 'disabled'
                  : 'default'
    ]

  return (
    <div
      className="px-1.5 py-1 rounded h-6 flex items-center justify-center"
      style={{ backgroundColor: bgColor, border: `0.5px solid ${border}` }}
    >
      <p
        className="text-xs font-medium tracking-tight text-nowrap"
        style={{ color: textColor }}
      >
        {text}
      </p>
    </div>
  )
}
