import React from 'react'

import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar'
import type { Input, item } from '@/store/api/SearchFace/SearchFace.types'
interface FaceMatchAvatarsProps {
  items: Input[]
}
export const FaceMatchAvatars: React.FC<FaceMatchAvatarsProps> = ({
  items,
}) => {
  return (
    <div className="flex flex-row items-center h-10">
      {items.map((avatar: any, index: number) => (
        <Avatar key={index} className={`relative ${blur}`}>
          <AvatarImage src={avatar.base64} />
        </Avatar>
      ))}
    </div>
  )
}
