export const fileToBase64 = (
  file: File,
  callback: (base64: string) => void,
) => {
  const reader = new FileReader()
  reader.onloadend = () => {
    callback(reader.result as string)
  }
  reader.readAsDataURL(file)
}

export const base64ToFormData = (base64: string, fileName: string) => {
  const [header, data] = base64.split(',')
  const mimeMatch = header.match(/data:(.*);base64/)
  if (!mimeMatch) {
    throw new Error('Invalid base64 format')
  }
  const mime = mimeMatch[1]
  const byteCharacters = atob(data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], { type: mime })
  const formData = new FormData()
  formData.append('document', blob, fileName)
  return formData
}
