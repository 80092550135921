import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useRudderStack } from '@/hooks/useRudderStack'
import { clearTokens } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  useSaveComplianceSignedPdfMutation,
  useSendEmailWithComplianceDocumentMutation,
  useStartNewPersonSearchMutation,
} from '@/store/api/NewSearch/NewSearch.query'
import { selectNewSearch } from '@/store/api/NewSearch/NewSearch.selector'
import { clearNewSearch } from '@/store/api/NewSearch/NewSearch.slice'
import { selectUserInfo } from '@/store/api/Users/Users.selector'
import { base64ToFormData } from '@/utils/binary'

import { Chip } from '../Home/components/Chip/Chip'

import { GenerateReportHeader } from './Components/GenerateReportHeader'
import { InputsSection } from './Components/InputsSection'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'
import { ReportSetup } from './ReportSetup'

export const NewSearchLegalDisclaimer = () => {
  useHasCurrentStepToken('finalStep')
  const { t } = useTranslation()
  const { trackEvent } = useRudderStack()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const searchInfo = useSelector(selectNewSearch)
  const userID = useSelector(selectUserInfo).userId

  const [isChecked, setIsChecked] = useState(false)
  const [newSearch, { isLoading: isLoadingNewSearch }] =
    useStartNewPersonSearchMutation()
  const [sendEmailWithDocument, { isLoading: isLoadingSendEmailWithDocument }] =
    useSendEmailWithComplianceDocumentMutation()
  const [saveSignedPdf, { isLoading: isLoadingSaveSignedPdf }] =
    useSaveComplianceSignedPdfMutation()

  const isLoading =
    isLoadingNewSearch ||
    isLoadingSendEmailWithDocument ||
    isLoadingSaveSignedPdf

  const fullName = `${searchInfo.subjectInfo.firstName} ${searchInfo.subjectInfo.middleName} ${searchInfo.subjectInfo.lastName}`

  const sortedTopics =
    searchInfo.firstStep.topics !== null &&
    searchInfo.firstStep.topics !== undefined
      ? Object.values(searchInfo.firstStep.topics).sort()
      : []

  const filterEmptyFields = (obj: any) => {
    const filteredObj: any = {}
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
        filteredObj[key] = obj[key]
      }
    })
    return filteredObj
  }
  const makeRequest = useCallback(async () => {
    try {
      const requestPayload = {
        name: searchInfo.subjectInfo.firstName
          ? fullName
          : searchInfo.companyInfo.name,
        nickname: searchInfo.subjectInfo.alias,
        city: searchInfo.subjectInfo.cityLongName
          ? searchInfo.subjectInfo.cityLongName
          : searchInfo.companyInfo.cityLongName,
        state: searchInfo.subjectInfo.stateLongName
          ? searchInfo.subjectInfo.stateLongName
          : searchInfo.companyInfo.stateLongName,
        country: searchInfo.subjectInfo.countryLongName
          ? searchInfo.subjectInfo.countryLongName
          : searchInfo.companyInfo.countryLongName,
        email: searchInfo.subjectInfo.email,
        college: searchInfo.subjectInfo.collage,
        phone: searchInfo.subjectInfo.phoneNumber,
        company: searchInfo.subjectInfo.companyName,
        twitterUrl: searchInfo.socialMedia.x,
        facebookUrl: searchInfo.socialMedia.facebook,
        instagramUrl: searchInfo.socialMedia.instagram,
        tikTokUrl: searchInfo.socialMedia.tikTok,
        linkedInUrl: searchInfo.socialMedia.linkedIn,
        ageOrDateOfBirth: searchInfo.subjectInfo.dob,
        pdfCoreFindings: searchInfo.reportSetup.pdfCoreFindings,
        pdfFlaggedBehaviors: searchInfo.reportSetup.pdfFlaggedBehaviors,
        pdfLanguage: searchInfo.reportSetup.pdfLanguage,
        pdfNegativePosts: searchInfo.reportSetup.pdfNegativePosts,
        pdfNeutralPosts: searchInfo.reportSetup.pdfNeutralPosts,
        pdfPositivePosts: searchInfo.reportSetup.pdfPositivePosts,
        pdfSentimentAnalysis: searchInfo.reportSetup.pdfSentimentAnalysis,
        pdfSocialMediaAccounts: searchInfo.reportSetup.pdfSocialMediaAccounts,
        pdfWebSearchPosts: searchInfo.reportSetup.pdfWebSearchPosts,
        topics: sortedTopics,
        topicsIds: searchInfo.firstStep.topicsIds || [],
        reportType: searchInfo.selectSearchType.searchType,
        useCase: searchInfo.useCases.selectedUseCaseId,
        optionalCompliance: searchInfo.useCases.consentSwitchValue,
        website: searchInfo.companyInfo.website,
        industry: searchInfo.companyInfo.industry,
      }
      const filteredPayload = filterEmptyFields(requestPayload)

      const newSearchRequest = await newSearch(filteredPayload).unwrap()
      const { info } = newSearchRequest

      if (
        searchInfo.consent.file !== null &&
        searchInfo.consent.file !== undefined &&
        searchInfo.consent.file
      ) {
        const formData = base64ToFormData(searchInfo.consent.file, 'signed.pdf')
        await saveSignedPdf({
          id: info.id,
          document: formData,
        }).unwrap()
      } else if (searchInfo.useCases.consentSwitchValue) {
        await sendEmailWithDocument({
          id: info.id,
          documentToSend: searchInfo.consent.subjectLocationValue,
          businessDocument: searchInfo.consent.businessLocationValue,
          email: searchInfo.consent.consentEmail,
        }).unwrap()
      }
      trackEvent(Events.REPORT_NEW, userID, {
        type_of_report: searchInfo.selectSearchType.searchType,
        type_of_topics:
          searchInfo.selectSearchType.searchType !== 'ai_report'
            ? searchInfo.firstStep.topics.join(', ')
            : 'No topics were selected.',
      })
      dispatch(clearNewSearch())
      dispatch(clearTokens())
      navigate(AppRoutes.reportInProgress)
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.errorOnSearch'))
    }
  }, [searchInfo])

  const handleCheckboxChange = (newChecked: boolean) => {
    setIsChecked(newChecked)
  }

  return (
    <div className="flex flex-col gap-8 h-full ">
      <GenerateReportHeader
        form="socialMedia"
        onClickNext={makeRequest}
        isLoading={isLoading}
        disabled={!isChecked}
        customButton={
          <Button
            variant="secondary"
            onClick={() => navigate(AppRoutes.dashboard)}
          >
            Cancel
          </Button>
        }
      />
      <div className="flex flex-col gap-8 lg:px-8 px-4 py-4 flex-1">
        <ReportSetup />
        <div className="flex flex-col justify-center items-center gap-6">
          <div className="flex flex-col items-center gap-3 justify-center text-center">
            <p className="text-neutralColor text-textNormal text-xl font-medium tracking-wide">
              {t('dashboard.newSearch.legalDisclaimer.doubleCheckYourInputs')}
            </p>
            <p className="text-neutralColor80 text-textAccent text-base font-medium tracking-tight text-center">
              {t('dashboard.newSearch.legalDisclaimer.theDetailsBelow')}
            </p>
            <p className="text-lg text-neutralColor mt-6">
              {t('dashboard.newSearch.legalDisclaimer.subjectInputs')}
            </p>
          </div>
          <div className="flex flex-col items-center gap-6">
            <InputsSection searchInfo={searchInfo} />
            {searchInfo?.firstStep.topics.length !== 0 && (
              <div className="flex flex-col items-center gap-3">
                <p className="text-center text-lg text-neutralColor mt-6">
                  {t('dashboard.newSearch.legalDisclaimer.focusedTopics')}
                </p>
                <div className="flex flex-row gap-3">
                  {searchInfo?.firstStep.topics?.map((topic) => (
                    <Chip green text={topic} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col items-center gap-4 ">
          <div className="flex gap-2 items-center">
            <Checkbox
              className="w-5 h-5 rounded-md"
              onCheckedChange={handleCheckboxChange}
            />

            <p className="text-neutralColor text-textNormal text-sm font-medium tracking-tight">
              {t('dashboard.newSearch.legalDisclaimer.checkingBox')}
              <span
                className="text-linkColor"
                role="button"
                onClick={() =>
                  window.open(
                    t('dashboard.common.termsAndConditionsLink'),
                    '_blank',
                  )
                }
                tabIndex={0}
              >
                &nbsp;
                {t('dashboard.newSearch.legalDisclaimer.termsAndConditions')}
              </span>
              {t('dashboard.newSearch.legalDisclaimer.and')}&nbsp;
              <span
                className="text-linkColor"
                role="button"
                onClick={() =>
                  window.open(t('dashboard.common.privacyPolicyLink'), '_blank')
                }
                tabIndex={0}
              >
                {t('dashboard.newSearch.legalDisclaimer.privacyPolicy')}
              </span>
              . 1 {t('dashboard.newSearch.legalDisclaimer.creditDeducted')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
