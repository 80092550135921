import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import AdverseMediaCreditIcon from '@/assets/sidebar/adverseMediaCreditIcon.svg?react'
import AiPlusAnalistCreditIcon from '@/assets/sidebar/aiPlusAnalistReportCrediticon.svg?react'
import AiReportCreditIcon from '@/assets/sidebar/aiReportCreditIcon.svg?react'
import FacialMatchingCrediticon from '@/assets/sidebar/facialMatchingCreditIcon.svg?react'
import WeCheckAssistedCreditIcon from '@/assets/sidebar/weCheckAssistedCreditIcon.svg?react'
import { EReportType } from '@/store/api/Enterprise/Enterprise.types'
import { ECreditRecordType } from '@/store/api/Plan/Plan.types'

import type { IHistoryPlanItem } from './HistoryPlanItem.type'

export const HistoryPlanItem = ({ data }: IHistoryPlanItem) => {
  const { t } = useTranslation()

  const getCreditName = (reportType: EReportType) => {
    switch (reportType) {
      case EReportType.AdverseMedia:
        return t('dashboard.reportsScreen.adverseMedia')
      case EReportType.Ai:
        return t('dashboard.reportsScreen.aIReport')
      case EReportType.AiAnalyst:
        return t('dashboard.reportsScreen.aIAnalystReport')
      case EReportType.Assited:
        return t('dashboard.reportsScreen.weCheckAssisted')
      case EReportType.FacialMatching:
        return t('dashboard.reportsScreen.facialMatchingCredit')
    }
  }

  const getTransactionType = (recordType: ECreditRecordType) => {
    switch (recordType) {
      case ECreditRecordType.ACCREDITATION:
        return t('dashboard.reportsScreen.added')
      case ECreditRecordType.REFOUND:
        return t('dashboard.reportsScreen.refund')
      case ECreditRecordType.BURNED:
        return t('dashboard.reportsScreen.used')
      case ECreditRecordType.DISCOUNT:
        return t('dashboard.reportsScreen.discount')
    }
  }

  return (
    <div className="bg-baseColorHighlight rounded-md flex justify-between px-6 py-3  mb-5 items-center">
      <div className="flex flex-col gap-2">
        <p className=" font-medium text-base">
          {getTransactionType(data.recordType)}
        </p>
        <p className="text-neutralColor80 text-s font-light tracking-wider">
          {format(new Date(data.date), 'MM/dd/yyyy')}
        </p>
      </div>

      <div className="flex gap-4 md:min-w-52">
        <div>
          {data.creditType === EReportType.AdverseMedia && (
            <AdverseMediaCreditIcon />
          )}
          {data.creditType === EReportType.Ai && <AiReportCreditIcon />}
          {data.creditType === EReportType.AiAnalyst && (
            <AiPlusAnalistCreditIcon />
          )}
          {data.creditType === EReportType.FacialMatching && (
            <FacialMatchingCrediticon />
          )}
          {data.creditType === EReportType.Assited && (
            <WeCheckAssistedCreditIcon />
          )}
        </div>
        <div>
          <h1 className="text-neutralColor text-base font-medium tracking-tight hidden md:flex">
            {getCreditName(data.creditType)}
          </h1>
        </div>
      </div>

      <div className="flex flex-row items-center">
        {(data.recordType === ECreditRecordType.ACCREDITATION ||
          data.recordType === ECreditRecordType.REFOUND) && (
          <p className="text-dynamicGreen">{`+${data.quantity}`}</p>
        )}
        {data.recordType === ECreditRecordType.BURNED && (
          <p className="text-dynamicRed">{`${data.quantity}`}</p>
        )}
        {data.recordType === ECreditRecordType.DISCOUNT && (
          <p className="text-dynamicRed">{`${data.quantity}`}</p>
        )}
      </div>
    </div>
  )
}
