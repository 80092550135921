import React from 'react'
import { useTranslation } from 'react-i18next'

import type { getSubjectInfo } from '@/store/api/Person/Person.types'

import { PostsInsightsCardTag } from './PostsInsightsCardTag'

interface IPostAnalysisCard {
  data: getSubjectInfo
  title: string
  text: string
  isCustomBehavior?: boolean
}

export enum ECoreBehavior {
  DrugAlcoholMention = 'Drug/Alcohol Mention',
  Threats = 'Threats',
  Suggestive = 'Suggestive',
  NudityImage = 'Nudity Image',
  Disparaging = 'Disparaging',
  DrugImage = 'Drug Image',
  GoryImage = 'Gory Image',
  PoliticsGovernment = 'Politics/Government',
  Prejudice = 'Prejudice',
  Profanity = 'Profanity',
  SuicideMention = 'Suicide Mention',
}

const getMatchingRisks = (
  topics: string[],
  risks: { name: string; count: number }[],
) => {
  return topics.map((topic) => {
    const matchingRisk = risks.find((risk) => risk.name === topic)
    return {
      topic,
      count: matchingRisk ? matchingRisk.count : undefined,
    }
  })
}

export const PostsInsightsCard = ({
  title,
  text,
  isCustomBehavior,
  data,
}: IPostAnalysisCard) => {
  const { t } = useTranslation()

  const riskWithoutTopics = data?.risks.filter(
    (risk) => !data.inputs.topics.includes(risk.name),
  )
  const matchingRisks = isCustomBehavior
    ? getMatchingRisks(data?.inputs.topics ?? [], data.risks)
    : []

  const coreFrontNumber = riskWithoutTopics.reduce(
    (sum, risk) => sum + risk.count,
    0,
  )
  const customFrontNumber = matchingRisks.reduce(
    (sum, risk) => sum + (risk.count ?? 0),
    0,
  )

  return (
    <div className="bg-baseColorHighlight flex flex-col gap-2 p-4 rounded-lg">
      <div className="flex flex-col gap-3 lg:gap-0 mb-3 lg:mb-0 lg:flex-row lg:justify-between">
        <h1
          className={`text-neutralColor text-textNormal text-xl font-medium tracking-wide`}
        >
          {title}
        </h1>

        <PostsInsightsCardTag
          text={t('dashboard.postsInsights.behaviorFlagsFound')}
          frontNumber={isCustomBehavior ? customFrontNumber : coreFrontNumber}
        />
      </div>

      <div>
        <p className="text-sm text-neutralColor80">{text}</p>
      </div>
      {isCustomBehavior ? (
        <div className="flex flex-wrap gap-3 pt-4">
          {matchingRisks.map(({ topic, count }) => (
            <PostsInsightsCardTag
              key={topic}
              text={topic}
              number={count ?? undefined}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-wrap gap-3 pt-4">
          {riskWithoutTopics.map((risk) => (
            <PostsInsightsCardTag
              key={risk.name}
              text={risk.name}
              number={risk.count ?? 0}
            />
          ))}
        </div>
      )}
    </div>
  )
}
