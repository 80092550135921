import { createSlice } from '@reduxjs/toolkit'

export interface SignUpSliceState {
  userAuth: {
    email: string
    authToken: string
  }
  firstStep: {
    firstName: string
    lastName: string
    phoneNumber: string
  }
  secondStep: {
    companyName: string
    companyAddress: string
  }
  lastStep: {
    backgroundChecks: number
  }
}

export const signUpSliceInitialState: SignUpSliceState = {
  userAuth: {
    email: '',
    authToken: '',
  },
  firstStep: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  secondStep: {
    companyName: '',
    companyAddress: '',
  },
  lastStep: {
    backgroundChecks: 0,
  },
}

const signUpSlice = createSlice({
  name: 'signUp',
  initialState: signUpSliceInitialState,
  reducers: {
    setUserCredentials: (state, action) => {
      const userAuth = action.payload
      state.userAuth.email = userAuth.email
      state.userAuth.authToken = userAuth.authToken
    },
    setFirstStep: (state, action) => {
      const userFirstStep = action.payload
      state.firstStep = { ...userFirstStep }
    },
    setSecondStep: (state, action) => {
      const userSecondStep = action.payload
      state.secondStep = { ...userSecondStep }
    },
    setLastStep: (state, action) => {
      const userLastStep = action.payload
      state.secondStep = { ...userLastStep }
    },
    clearUserCredentials: (state) => {
      state.userAuth = {
        email: '',
        authToken: '',
      }
    },
    clearSignUpState: (state) => {
      state.firstStep = signUpSliceInitialState.firstStep
      state.secondStep = signUpSliceInitialState.secondStep
      state.lastStep = signUpSliceInitialState.lastStep
      state.userAuth = signUpSliceInitialState.userAuth
    },
  },
})
export const {
  setUserCredentials,
  setFirstStep,
  setSecondStep,
  setLastStep,
  clearUserCredentials,
  clearSignUpState,
} = signUpSlice.actions

export default signUpSlice.reducer
