import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxStoreProvider } from 'react-redux'

import './index.css'
import App from './App'
import env from './env/env'
import { useRemoteConfigInit } from './hooks/useRemoteConfigInit'
import { store } from './store/store'

const firebaseConfig = {
  apiKey: env.FIREBASE_CONFIG_APIKEY,
  authDomain: env.FIREBASE_CONFIG_AUTHDOMAIN,
  projectId: env.FIREBASE_CONFIG_PROJECTID,
  storageBucket: env.FIREBASE_CONFIG_STORAGEBUCKET,
  messagingSenderId: env.FIREBASE_CONFIG_MESSAGINGSENDERID,
  appId: env.FIREBASE_CONFIG_APPID,
  measurementId: env.FIREBASE_CONFIG_MEASUREMENTID,
}

const app = initializeApp(firebaseConfig)
app.automaticDataCollectionEnabled = true
getAnalytics(app)

const root = ReactDOM.createRoot(document.getElementById('root')!)

if (env.NODE_ENV === 'mockserver') {
  // Prepare MSW in a Service Worker
  import('../mocks/browser')
    .then(({ worker }) => {
      worker.start()
      useRemoteConfigInit()
    })
    // Launched mock server, and then start client React app
    .then(() =>
      root.render(
        <ReduxStoreProvider store={store}>
          <App />
        </ReduxStoreProvider>,
      ),
    )
} else {
  // Production
  root.render(
    <ReduxStoreProvider store={store}>
      <App />
    </ReduxStoreProvider>,
  )
}
