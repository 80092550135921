import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch } from '@/hooks/redux'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useLoginMutation } from '@/store/api/Auth/Auth.query'
import { setSession } from '@/store/api/Auth/Auth.slice'
import { setUserCredentials } from '@/store/api/SignUp/SignUp.slice'
import type { LoginType } from '@/utils/schemas'
import { loginSchema } from '@/utils/schemas'

export const LoginForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const form = useForm<LoginType>({
    resolver: zodResolver(loginSchema),

    defaultValues: {
      email: '',
      password: '',
    },
  })

  const {
    handleSubmit,
    control,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors = {} },
  } = form

  const [login, { isLoading }] = useLoginMutation()

  const makeLogin = async (password: string, email: string) => {
    try {
      const response = await login({
        email,
        password,
      }).unwrap()
      dispatch(setSession(response.info))
      if (response.info.userStatus !== 'pending_on_boarding') {
        navigate(AppRoutes.dashboard)
      } else {
        dispatch(
          setUserCredentials({
            email,
            password,
          }),
        )
        navigate(AppRoutes.registerEmailVerification)
      }
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.invalidCredentials'))
    }
  }

  const onSubmit = (data: any) => {
    makeLogin(data.password, data.email)
  }

  return (
    <div className="flex flex-col gap-8 w-full max-w-[436px]">
      <div className="flex flex-col gap-5">
        <p className="text-[40px] font-bold tracking-wide text-center">
          {t('dashboard.landingLogIn.login')}
        </p>
        <p className="text-1xl text-textAccent tracking-tight text-center">
          {t('dashboard.landingLogIn.welcomeBack')}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t('dashboard.landingLogIn.example')}
                  />
                </FormControl>
                <FormMessage data-name="email" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="password"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md  ">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t('dashboard.landingLogIn.password')}
                    type="password"
                    showPassIcon
                  />
                </FormControl>
                <FormMessage data-name="password" />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
            isLoading={isLoading}
          >
            {t('dashboard.landingLogIn.login')}
          </Button>
        </form>
      </Form>
      <div className="text-center max-w-[436px]">
        <p className="pb-6 text-center text-linkColor font-medium">
          <Link to={AppRoutes.forgotPassword}>
            {t('dashboard.landingLogIn.forgotPassword')}
          </Link>
        </p>
      </div>
    </div>
  )
}
