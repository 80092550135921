import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Card } from '@/components/ui/card'
import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import { useRudderStack } from '@/hooks/useRudderStack'
import { FilterByTypeEnum } from '@/store/api/Person/Person.types'
import { selectUserInfo } from '@/store/api/Users/Users.selector'

import { CardWithButton } from './CardWithButton'

interface ContentSummarySectionTypes {
  positivePosts: string
  negativePosts: string
  flaggedPosts: string
  topicPosts: string
  id: string
}

export const ContentSummarySection = ({
  negativePosts,
  positivePosts,
  flaggedPosts,
  topicPosts,
  id,
}: ContentSummarySectionTypes) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userInfo = useSelector(selectUserInfo)
  const { trackEvent } = useRudderStack()

  const handleTrackEvent = (postType: string) => {
    trackEvent(Events.REPORTS_VIEW_POST_CATEGORY, userInfo.userId, {
      email: userInfo.email,
      post_category: postType,
    })
    navigate(AppRoutes.postList, {
      state: {
        id,
        filterBy:
          postType === 'Negative'
            ? FilterByTypeEnum.NEGATIVE
            : postType === 'Positive'
              ? FilterByTypeEnum.POSITIVE
              : postType === 'Topic'
                ? FilterByTypeEnum.TOPIC
                : FilterByTypeEnum.FLAGGED,
      },
    })
  }

  return (
    <Card className="flex p-4 bg-baseColorHighlight shadow-none border-0 rounded-2xl flex-col gap-6">
      <div className="flex flex-col gap-3">
        <p className="text-neutralColor text-textNormal text-xl font-medium tracking-wide">
          {t('dashboard.postsInsights.socialMediaContentSummary')}
        </p>
        <p className="text-neutralColor80 text-base font-normal tracking-tight">
          {t('dashboard.postsInsights.socialMediaContentSummaryText')}
        </p>
      </div>
      <CardWithButton
        topText={t('dashboard.postsInsights.focusedTopics')}
        bottomText={t('dashboard.postsInsights.focusedTopicsText')}
        bgColor={'bg-primaryColor5'}
        onClick={() => handleTrackEvent('Topic')}
        button
        chipNumber={topicPosts}
      />
      <CardWithButton
        topText={t('dashboard.postsInsights.flaggedPosts')}
        bottomText={t('dashboard.postsInsights.flaggedPostsText')}
        bgColor={'bg-primaryColor5'}
        onClick={() => handleTrackEvent('Flagged')}
        button
        chipNumber={flaggedPosts}
      />
      <CardWithButton
        topText={t('dashboard.postsInsights.negativePosts')}
        bottomText={t('dashboard.postsInsights.negativePostsText')}
        bgColor={'bg-primaryColor5'}
        onClick={() => handleTrackEvent('Negative')}
        button
        chipNumber={negativePosts}
      />

      <CardWithButton
        topText={t('dashboard.postsInsights.positivePosts')}
        bottomText={t('dashboard.postsInsights.positivePostsText')}
        bgColor={'bg-primaryColor5'}
        onClick={() => handleTrackEvent('Positive')}
        button
        chipNumber={positivePosts}
      />
    </Card>
  )
}
