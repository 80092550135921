/* eslint-disable jsx-a11y/tabindex-no-positive */
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Progress } from '@/components/ui/progress'
import { WeScreenV2 } from '@/components/WeScreen/WeScreenV2'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { setFinalStepGuardToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  selectNewSearch,
  selectHasSocialMediaData,
  selectCompanyInfo,
} from '@/store/api/NewSearch/NewSearch.selector'
import { setCompanyInfo } from '@/store/api/NewSearch/NewSearch.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { companyInfoSchemaType } from '@/utils/schemas'
import { companyInfoSchema } from '@/utils/schemas'

import Env from '../../env/env'
import { SocialMediaSelector } from '../Reports/Components/SocialMediaSelector'

import { Stepper } from './Components/Stepper'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const NewSearchCompanyInfo = () => {
  useHasCurrentStepToken('companyInfoStep')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isMobile = useMobileMediaQuery()
  const key = Env.GOOGLE_KEY as string
  const companyInfo = useAppSelector(selectCompanyInfo)
  const hasSocialMediaData = useSelector(selectHasSocialMediaData)
  const searchType = useSelector(selectNewSearch).selectSearchType.searchType

  const [countryShortName, setCountryShortName] = useState(
    companyInfo.countryShortName,
  )
  const [stateShortName, setStateShortName] = useState(
    companyInfo.stateShortName,
  )
  const [cityShortName, setCityShortName] = useState(companyInfo.cityShortName)

  const form = useForm<companyInfoSchemaType>({
    resolver: zodResolver(companyInfoSchema),
    defaultValues: {
      companyName: companyInfo.name,
      companyWebsite: companyInfo.website,
      industry: companyInfo.industry,
      country: companyInfo.countryLongName,
      state: companyInfo.stateLongName,
      city: companyInfo.cityLongName,
    },
    mode: 'onChange',
  })

  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    watch,
    setValue,
  } = form

  useEffect(() => {
    Object.keys(companyInfo).forEach((key) => {
      const subjectInfoCast = companyInfo as any
      if (subjectInfoCast[key] !== '') {
        setValue(key as keyof companyInfoSchemaType, subjectInfoCast[key], {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
      }
    })
  }, [companyInfo, setValue])

  const steps = [
    searchType === 'ai_report' || searchType === 'adverse_media_report'
      ? undefined
      : t('dashboard.newSearch.common.focusedTopics'),
    t('dashboard.newSearch.common.companyInformation'),
    t('dashboard.newSearch.common.submit'),
  ]

  const activeStep =
    searchType === 'adverse_media_report' || searchType === 'ai_report' ? 0 : 1

  const onSubmit = (data: any) => {
    dispatch(
      setCompanyInfo({
        name: data.companyName,
        website: data.companyWebsite,
        industry: data.industry,
        countryShortName: countryShortName,
        countryLongName: data.country,
        stateShortName: stateShortName,
        stateLongName: data.state,
        cityShortName: cityShortName,
        cityLongName: data.city,
      }),
    )
    dispatch(setFinalStepGuardToken({ finalStepGuardToken: true }))
    navigate(AppRoutes.newSearchLegalDisclaimer)
  }

  const requiredFields: (keyof companyInfoSchemaType)[] = [
    'companyName',
    'country',
    'state',
    'city',
  ]
  const formWatch = watch()

  const completePercentage = useMemo(() => {
    const formValues = form.getValues() as any
    const totalFields = Object.keys(formValues).length + 1

    const dirtyFieldsKeys = Object.keys(dirtyFields).filter(
      (item) => item !== 'socialMediaUrl',
    )

    const completedFields = dirtyFieldsKeys.filter((field: any) => {
      return formValues[field] !== ''
    })

    const errorFields = Object.keys(errors)

    const areAllErrorsCompleted = errorFields.every((field) =>
      completedFields.includes(field),
    )

    if (areAllErrorsCompleted && !isEmpty(errorFields)) {
      return ((completedFields.length - errorFields.length) / totalFields) * 100
    }
    const numberOfCompletedFields = hasSocialMediaData
      ? completedFields.length + 1
      : completedFields.length

    return (numberOfCompletedFields / totalFields) * 100
  }, [form, dirtyFields, formWatch, errors, hasSocialMediaData])

  const areRequiredFieldsValid = (
    formValues: companyInfoSchemaType,
  ): boolean => {
    return requiredFields.every((field) => {
      const value = formValues[field]
      return value !== undefined && value !== null && value !== ''
    })
  }

  const inputStrength = useMemo(() => {
    if (completePercentage < 20 || !areRequiredFieldsValid(formWatch)) {
      return {
        text: t('dashboard.newSearch.common.insufficient'),
        color: weCheckPalette.neutralColor60,
      }
    }
    if (completePercentage < 40) {
      return {
        text: t('dashboard.newSearch.common.essential'),
        color: weCheckPalette.dynamicBlue,
      }
    } else if (completePercentage < 70) {
      return {
        text: t('dashboard.newSearch.common.advanced'),
        color: weCheckPalette.dynamicBlue,
      }
    } else {
      return {
        text: t('dashboard.newSearch.common.precision'),
        color: weCheckPalette.dynamicGreen,
      }
    }
  }, [completePercentage, formWatch, companyInfo])

  return (
    <WeScreenV2
      onClickNextButton={handleSubmit(onSubmit)}
      isDisabledNextButton={!form.formState.isValid}
    >
      <div className="flex flex-col items-center h-full p-6">
        <div className="flex">
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        <div className="flex flex-col h-full w-full justify-center">
          <Form {...form}>
            <form className="flex flex-col gap-14">
              {isMobile && (
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center justify-center">
                      <p>{t('dashboard.newSearch.common.inputStrength')}</p>
                      <p
                        className="text-textAccent ml-2 font-medium"
                        style={{ color: inputStrength.color }}
                      >
                        {inputStrength.text}
                      </p>
                    </div>
                    <span className="text-neutralColor60">
                      {t('dashboard.newSearch.common.estimateAccuracy')}
                      <span className="text-neutralColor font-bold ml-1">
                        {completePercentage.toFixed(0)} {'%'}
                      </span>
                    </span>
                  </div>

                  <Progress
                    value={completePercentage}
                    className="h-4"
                    color={inputStrength.color}
                  />
                </div>
              )}
              <div className="flex flex-col gap-5">
                <p className="text-base font-medium">
                  {t('dashboard.newSearch.stepCompanyInfo.companyInformation')}
                </p>
                <div className="flex lg:flex-row flex-col gap-6">
                  <FormField
                    control={control}
                    name="companyName"
                    render={({ field }) => (
                      <FormItem className="w-full h-20">
                        <FormLabel>
                          <span className="text-neutralColor">
                            {t(
                              'dashboard.newSearch.stepCompanyInfo.companyName',
                            )}
                            <span className="text-neutralColor80 ml-1">
                              {t('dashboard.newSearch.common.required')}
                            </span>
                          </span>
                        </FormLabel>
                        <FormControl>
                          <Input {...field} tabIndex={1} />
                        </FormControl>
                        <FormMessage data-name="companyName" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="companyWebsite"
                    render={({ field }) => (
                      <FormItem className="w-full h-20">
                        <FormLabel>
                          <span className="text-neutralColor">
                            {t(
                              'dashboard.newSearch.stepCompanyInfo.companyWebsite',
                            )}
                          </span>
                        </FormLabel>
                        <FormControl>
                          <Input {...field} tabIndex={2} />
                        </FormControl>
                        <FormMessage data-name="companyWebsite" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="industry"
                    render={({ field }) => (
                      <FormItem className="w-full h-20">
                        <FormLabel>
                          <span className="text-neutralColor">
                            {t('dashboard.newSearch.stepCompanyInfo.industry')}
                          </span>
                        </FormLabel>
                        <FormControl>
                          <Input {...field} tabIndex={3} />
                        </FormControl>
                        <FormMessage data-name="industry" />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-5">
                <p className="text-base font-medium">
                  {t('dashboard.newSearch.stepCompanyInfo.location')}
                </p>
                <div className="flex lg:flex-row flex-col gap-6">
                  <FormField
                    control={control}
                    name="country"
                    render={({ field }) => (
                      <FormItem className="w-full h-20">
                        <FormLabel className="text-neutralColor">
                          <span className="text-neutralColor">
                            {t('dashboard.newSearch.stepCompanyInfo.country')}
                            <span className="text-neutralColor80 ml-1">
                              {t('dashboard.newSearch.common.required')}
                            </span>
                          </span>
                        </FormLabel>
                        <FormControl>
                          <Autocomplete
                            {...field}
                            language="en"
                            tabIndex={4}
                            className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                            apiKey={key}
                            placeholder={t(
                              'dashboard.newSearch.common.enterLocation',
                            )}
                            options={{ types: ['(regions)'] }}
                            onPlaceSelected={(place) => {
                              setValue(
                                'country',
                                place.address_components[0].long_name,
                                { shouldValidate: true, shouldDirty: true },
                              )
                              setCountryShortName(
                                place.address_components[0].short_name,
                              )
                            }}
                          />
                        </FormControl>
                        <FormMessage data-name="country" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="state"
                    render={({ field }) => (
                      <FormItem className="w-full h-20">
                        <FormLabel>
                          <span className="text-neutralColor">
                            {t('dashboard.newSearch.stepCompanyInfo.state')}
                            <span className="text-neutralColor80 ml-1">
                              {t('dashboard.newSearch.common.required')}
                            </span>
                          </span>
                        </FormLabel>
                        <FormControl>
                          <Autocomplete
                            {...field}
                            language="en"
                            tabIndex={5}
                            className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-primaryColor5 file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                            apiKey={key}
                            placeholder={t(
                              'dashboard.newSearch.common.enterLocation',
                            )}
                            options={{ types: ['(regions)'] }}
                            onPlaceSelected={(place) => {
                              setValue(
                                'state',
                                place.address_components[0].long_name
                                  ? place.address_components[0].long_name
                                  : '',
                              )
                              setStateShortName(
                                place.address_components[0].short_name
                                  ? place.address_components[0].short_name
                                  : '',
                              )
                            }}
                          />
                        </FormControl>
                        <FormMessage data-name="state" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="city"
                    render={({ field }) => (
                      <FormItem className="w-full h-20">
                        <FormLabel>
                          <span className="text-neutralColor">
                            {t('dashboard.newSearch.stepCompanyInfo.city')}
                            <span className="text-neutralColor80 ml-1">
                              {t('dashboard.newSearch.common.required')}
                            </span>
                          </span>
                        </FormLabel>
                        <FormControl>
                          <Autocomplete
                            {...field}
                            language="en"
                            tabIndex={6}
                            className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-primaryColor5 file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                            apiKey={key}
                            placeholder={t(
                              'dashboard.newSearch.common.enterLocation',
                            )}
                            options={{ types: ['(cities)'] }}
                            onPlaceSelected={(place) => {
                              setValue(
                                'city',
                                place.address_components[0].long_name,
                              )
                              setCityShortName(
                                place.address_components[0].short_name,
                              )
                            }}
                          />
                        </FormControl>
                        <FormMessage data-name="city" />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-base font-medium">
                  {t('dashboard.newSearch.stepCompanyInfo.socialMediaAccounts')}
                </p>
                <SocialMediaSelector />
              </div>
            </form>
          </Form>
        </div>
        {!isMobile && (
          <div className="flex flex-col gap-2 w-full">
            <div className="flex justify-between items-center">
              <div className="flex items-center justify-center">
                <p>{t('dashboard.newSearch.common.inputStrength')}</p>
                <p
                  className="text-textAccent ml-2 font-medium"
                  style={{ color: inputStrength.color }}
                >
                  {inputStrength.text}
                </p>
              </div>
              <span className="text-neutralColor60">
                {t('dashboard.newSearch.common.estimateAccuracy')}
                <span className="text-neutralColor font-bold ml-1">
                  {completePercentage.toFixed(0)} {'%'}
                </span>
              </span>
            </div>

            <Progress
              value={completePercentage}
              className="h-4"
              color={inputStrength.color}
            />
          </div>
        )}
      </div>
    </WeScreenV2>
  )
}
