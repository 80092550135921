import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import emptyProfilePhoto from '@/assets/reportInfo/emptyProfilePhoto.png'
import { BackHeader } from '@/components/BackHeader/BackHeader'
import { Card } from '@/components/ui/card'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useGetSubjectBasicInformationQuery } from '@/store/api/Person/Person.query'

import { CardWithButton } from './Components/CardWithButton'
import { ChannelVolume } from './Components/ChannelVolume'
import { ContentSummarySection } from './Components/ContentSummarySection'
import { Header } from './Components/Header'
import { PostsInsightsCard } from './Components/PostsInsightsCard'

export const PostsInsights = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { data, isLoading, isError } = useGetSubjectBasicInformationQuery({
    id: state,
  })
  const postWithTopicFound = useMemo(() => {
    if (!data) return '0'
    const searchedTopics = data.info.inputs.topics
    const result = data.info.risks.filter((risk) =>
      searchedTopics.includes(risk.name),
    ).length
    return `${result}`
  }, [data])

  return (
    <WeScreen
      isError={isError}
      isLoading={isLoading}
      title={''}
      content={
        <div className="flex flex-col gap-6">
          <BackHeader
            backText={''}
            onPressBack={() =>
              navigate(AppRoutes.reportGeneralInfo, { state: state })
            }
          />
          <Card className="p-4 bg-base bg-baseColorHighlight shadow-none border-0 rounded-xl">
            <Header
              fullName={data?.info.fullName}
              profileImage={
                data?.info.profileImgUrl
                  ? data?.info.profileImgUrl
                  : emptyProfilePhoto
              }
              followers={data?.info.followers}
              following={data?.info.following}
              posts={data?.info.posts}
              inputs={data?.info.inputs}
            />
          </Card>
          <CardWithButton
            topText={t('dashboard.postsInsights.postsAnalysis')}
            bottomText={t('dashboard.postsInsights.postsAnalysisText')}
            bgColor={'bg-baseColorHighlight'}
          />
          {data && (
            <>
              <PostsInsightsCard
                title={t('dashboard.postsInsights.coreBehaviorDetection')}
                text={t('dashboard.postsInsights.coreBehaviorDetectionText')}
                data={data?.info}
              />
              <PostsInsightsCard
                isCustomBehavior
                title={t('dashboard.postsInsights.focusedTopics')}
                text={t('dashboard.postsInsights.focusedTopicsText')}
                data={data?.info}
              />
            </>
          )}
          <ContentSummarySection
            positivePosts={data?.info.positivePosts || '0'}
            negativePosts={data?.info.negativePosts || '0'}
            flaggedPosts={data?.info.flaggedPosts || '0'}
            topicPosts={postWithTopicFound}
            id={state}
          />

          <ChannelVolume
            channelsValueData={data?.info.channelsValue}
            totalPosts={data?.info.posts}
          />
        </div>
      }
    />
  )
}
