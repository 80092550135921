import { api } from '@/store/api'

import Env from '../../../env/env'

import type {
  CreateCheckoutSessionReponse,
  CreateCheckoutSessionTypes,
  PlanHistoryCrudresponse,
  PlanHistoryResponse,
} from './Plan.types'

export const planApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation<
      CreateCheckoutSessionReponse,
      CreateCheckoutSessionTypes
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v3/plan/one-time/checkout`,
        method: 'POST',
        body: {
          ...request,
        },
      }),
    }),
    planHistory: builder.query<PlanHistoryResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/recharge-history`,
        method: 'GET',
      }),
      transformResponse: (response: PlanHistoryCrudresponse) => {
        if (response.info && response.info.length) {
          return response.info.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          )
        }
        return response.info
      },
    }),
  }),
})

export const { useCreateCheckoutSessionMutation, usePlanHistoryQuery } = planApi
