import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select'
import { useAppDispatch } from '@/hooks/redux'
import { selectNewSearch } from '@/store/api/NewSearch/NewSearch.selector'
import { changeReportLang } from '@/store/api/NewSearch/NewSearch.slice'
import { selectUserInfo } from '@/store/api/Users/Users.selector'

import { ReportSetupSwitchSection } from './Components/ReportSetupSwitchSection'

export const ReportSetup = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const searchType = useSelector(selectNewSearch).selectSearchType.searchType

  const reportSetupLeng = useSelector(selectNewSearch).reportSetup.pdfLanguage
  const pdfCustomConfigLeng = useSelector(selectUserInfo).pdfConfig.pdfLanguage
  const useDefaultValueReportSetup =
    useSelector(selectNewSearch).reportSetup.useDefaultValue

  const [language, setLanguage] = useState<string>(
    useDefaultValueReportSetup ? pdfCustomConfigLeng : reportSetupLeng,
  )
  const handleLanguageChange = (newLanguage: string) => {
    dispatch(changeReportLang(newLanguage))
    setLanguage(newLanguage)
  }

  return (
    <>
      <div className="flex flex-col gap-4 items-center">
        <div className="flex flex-col gap-3 justify-center items-center">
          <div className="flex flex-col items-center gap-3">
            <p className="text-neutralColor text-xl font-medium tracking-wide">
              {t('dashboard.newSearch.reportSetup.reportGenerationSetup')}
            </p>
            <p className="text-neutralColor80 text-base font-normal ">
              {t('dashboard.newSearch.reportSetup.description')}
            </p>
          </div>

          <Select onValueChange={handleLanguageChange}>
            <SelectTrigger className="w-9/12 h-10 mb-6 bg-baseColorHighlight">
              {language === 'es'
                ? t('dashboard.account.spanish')
                : t('dashboard.account.english')}
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="es">
                  {t('dashboard.account.spanish')}
                </SelectItem>
                <SelectItem value="en">
                  {t('dashboard.account.english')}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className={`${searchType === 'adverse_media_report' && 'hidden'}`}>
          <ReportSetupSwitchSection
            useDefaultValue={useDefaultValueReportSetup}
          />
        </div>
      </div>
    </>
  )
}
