import React from 'react'

import { FormControl, FormField, FormItem } from '@/components/ui/form'
import { Switch } from '@/components/ui/switch'

interface SwitchCardTypes {
  title: string
  description: string
  formName: string
  disabled?: boolean
  checked?: boolean
  defaultValue?: boolean
}

export const SwitchCard = ({
  title,
  description,
  formName,
  disabled,
  checked,
  defaultValue,
}: SwitchCardTypes) => {
  return (
    <FormField
      name={formName}
      render={({ field }) => (
        <FormItem className="flex p-[6.5px] justify-start items-start gap-2 rounded-lg space-y-0">
          <FormControl>
            <Switch
              defaultChecked={defaultValue}
              className="w-12 h-6"
              checked={checked || field.value}
              onCheckedChange={field.onChange}
              disabled={!field.value && disabled}
            />
          </FormControl>
          <div className="flex flex-col gap-1">
            <p
              className={`${
                disabled ? 'text-neutralColor30' : 'text-neutralColor'
              } text-base font-medium tracking-tight `}
            >
              {title}
            </p>
            <p
              className={`${
                disabled ? 'text-neutralColor30' : 'text-neutralColor'
              } text-xs font-medium tracking-tight`}
            >
              {description}
            </p>
          </div>
        </FormItem>
      )}
    />
  )
}
