import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface ConfigState {
  forceUpdate: IForceUpdate
  inMaintenance: boolean
}

export interface IForceUpdate {
  version: string
  isForceUpdate: boolean
}

const initialState = {
  inMaintenance: false,
  forceUpdate: {
    version: '1.0.0',
    isForceUpdate: false,
  },
} as ConfigState

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig: (state, { payload }: PayloadAction<ConfigState>) => {
      state.inMaintenance = payload.inMaintenance
      state.forceUpdate = payload.forceUpdate
    },
  },
})

export const { updateConfig } = configSlice.actions

export default configSlice.reducer
