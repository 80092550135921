import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

import type { SignUpSliceState } from './SignUp.slice'

export const selectSignUp = (state: RootState) => state.signUp

export const selectCredentials = createSelector(
  selectSignUp,
  (signUpState: SignUpSliceState) => signUpState.userAuth,
)

export const selectSignUpFirstStep = createSelector(
  selectSignUp,
  (signUpState: SignUpSliceState) => signUpState.firstStep,
)

export const selectSignUpSecondStep = createSelector(
  selectSignUp,
  (signUpState: SignUpSliceState) => signUpState.secondStep,
)

export const selectOnboardingRequest = createSelector(
  selectSignUp,
  (signUpState: SignUpSliceState) => ({
    phoneNumber: signUpState.firstStep.phoneNumber,
    firstName: signUpState.firstStep.firstName,
    lastName: signUpState.firstStep.lastName,
    companyName: signUpState.secondStep.companyName,
    companyAddress: signUpState.secondStep.companyAddress,
    backgroundCredits: signUpState.lastStep.backgroundChecks,
  }),
)
