import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { AlertDialog } from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/text-area'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  useUserContactMutation,
  useUserInfoQuery,
} from '@/store/api/Users/Users.query'
import {
  selectEmail,
  selectOrgName,
  selectPhoneNumber,
  selectUserFullname,
} from '@/store/api/Users/Users.selector'
import { BackgroundCheckContactUsSchema } from '@/utils/schemas'
import type { BackgroundCheckContactUsType } from '@/utils/schemas'

import { ContactUsAlertDialog } from '../Home/components/ContactUsAlertDialog/ContactUsAlertDialog'

export const ContactUs = () => {
  const { t } = useTranslation()
  const [submitContact, { isLoading }] = useUserContactMutation()
  const [isOpen, setIsOpen] = useState(false)
  const form = useForm<BackgroundCheckContactUsType>({
    resolver: zodResolver(BackgroundCheckContactUsSchema),

    defaultValues: {
      subject: '',
      comment: '',
    },
  })
  const {
    userEmail,
    orgName,
    phoneNumber,
    personName,
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
  } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      userEmail: selectEmail(data),
      orgName: selectOrgName(data),
      phoneNumber: selectPhoneNumber(data),
      personName: selectUserFullname(data),
      data,
    }),
  })

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = form

  const onSubmit = async (data: any) => {
    try {
      await submitContact({
        personEmail: userEmail,
        subject: data.subject,
        message: data.comment,
        organizationName: orgName,
        phoneNumber: phoneNumber,
        personName: personName,
      })
      setIsOpen(true)
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.formError'))
    }
  }

  return (
    <WeScreen
      title={t('dashboard.contactUs.contactUs')}
      isLoading={isLoadingUserInfo}
      isError={isErrorUserInfo}
      cardContent={
        <div className="p-6">
          <AlertDialog open={isOpen}>
            <div className="flex flex-col">
              <p className="text-neutralColor text-base font-medium  mb-4">
                {t('dashboard.contactUs.contactUsText')}
              </p>

              <Form {...form}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col gap-6 mb-4"
                >
                  <FormField
                    control={control}
                    name="subject"
                    render={({ field }) => (
                      <FormItem className="w-full rounded-md ">
                        <FormControl className="flex w-full focus:outline-none">
                          <Input
                            className="bg-transparent"
                            {...field}
                            placeholder={t('dashboard.contactUs.subject')}
                          />
                        </FormControl>
                        <FormMessage data-name="subject" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="comment"
                    render={({ field }) => (
                      <FormItem className="w-full rounded-md">
                        <FormControl className="flex w-full focus:outline-none">
                          <Textarea
                            className="bg-transparent"
                            {...field}
                            placeholder={t('dashboard.contactUs.comment')}
                          />
                        </FormControl>
                        <FormMessage data-name="comment" />
                      </FormItem>
                    )}
                  />

                  <Button
                    className="sm:w-1/12 text-base font-light tracking-widest w-[101px] h-[46px]"
                    size={'lg'}
                    isLoading={isLoading}
                    disabled={!isDirty}
                    type="submit"
                  >
                    {t('dashboard.contactUs.submit')}
                  </Button>
                </form>
              </Form>
            </div>

            <ContactUsAlertDialog />
          </AlertDialog>
        </div>
      }
    />
  )
}
