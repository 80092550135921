import React, { useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import backgroundImage1 from '@/assets/wecheckLights/1.png'
import backgroundImage2 from '@/assets/wecheckLights/2.png'
import { AppRoutes } from '@/constants/appRoutes'

import { FormsScreensHeader } from '../FormsScreensHeader/FormsScreensHeader'

export const UnloggedScreenLayout = () => {
  const location = useLocation()
  const pathname = location.pathname

  const headerProps = useMemo(() => {
    switch (pathname) {
      case AppRoutes.registerStart:
        return {
          onlyLogo: false,
          signUp: false,
          login: true,
        }
      case AppRoutes.login:
        return {
          onlyLogo: false,
          signUp: true,
          login: false,
        }
      case AppRoutes.forgotPassword:
      case AppRoutes.createPassword:
        return {
          onlyLogo: false,
          signUp: false,
          login: true,
        }
      default:
        return {
          onlyLogo: true,
          signUp: false,
          login: false,
        }
    }
  }, [pathname])

  return (
    <div className="relative flex flex-row max-lg:flex-col w-full h-screen bg-white">
      <FormsScreensHeader {...headerProps} />
      <div
        className="fixed inset-0 bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: `url(${backgroundImage1})`,
          zIndex: -1,
          height: '100%',
        }}
      ></div>
      <div
        className="absolute inset-0 bg-cover bg-no-repeat bg-center animate-bg-scroll"
        style={{
          backgroundImage: `url(${backgroundImage2})`,
          zIndex: -1,
          height: '100%',
        }}
      ></div>
      <div className="flex w-full h-full justify-center items-center bg-white  px-32 max-sm:px-4 max-lg:basis-full">
        <Outlet />
      </div>
    </div>
  )
}
