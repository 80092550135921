import React from 'react'

import StepperCheck from '@/assets/stepper/StepperCheck.svg?react'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const Stepper = ({
  steps,
  activeStep,
}: {
  steps: (string | undefined)[]
  activeStep: number
}) => {
  const isSmallDesktop = useSmallDesktopQuery()
  const isTablet = useTabletMediaQuery()
  const sanetizedSteps = steps.filter((step) => step)
  return (
    <div className="flex items-center w-full text-sm font-medium text-center text-dynamicBlue md:text-base">
      {sanetizedSteps.map((step, index) => {
        if (!step) return null

        const [part1, part2, part3, part4] = step.split(' ', 4)

        return (
          <div
            key={index}
            className={`flex md:w-full items-center ${
              index < sanetizedSteps.length - 1 &&
              `after:content-[''] after:w-full after:h-1 after:border-b  
             after:border-1 after:hidden md:after:inline-block after:mx-6 xl:after:mx-10 
             `
            } ${
              index < activeStep
                ? 'text-dynamicBlue after:border-dynamicBlue'
                : 'after:border-gray-200'
            }`}
          >
            <span
              className={`flex items-center ${
                index < sanetizedSteps.length - 1 &&
                "after:content-['/'] md:after:hidden after:mx-2 after:text-neutralColor30"
              }`}
            >
              {index < activeStep ? (
                <StepperCheck
                  className="w-3.5 h-3.5 md:w-5 md:h-5 mr-2.5 "
                  fill={weCheckPalette.dynamicBlue}
                />
              ) : (
                <span
                  className={`me-2 ${
                    index !== activeStep && 'text-neutralColor30'
                  }`}
                >
                  {index + 1}
                </span>
              )}
              <span
                className={`${
                  index <= activeStep ? 'font-bold' : 'text-neutralColor30'
                } hidden sm:inline-flex md:ms-2 w-full`}
              >
                {isSmallDesktop || isTablet ? (
                  <span className={`${steps.length === 3 && 'lg:text-nowrap'}`}>
                    {step}
                  </span>
                ) : (
                  <>
                    {part1}&nbsp;
                    <span className="hidden md:inline-flex">{part2}&nbsp;</span>
                    <span className="hidden md:inline-flex">{part3}&nbsp;</span>
                    <span className="hidden md:inline-flex">{part4}</span>
                  </>
                )}
              </span>
            </span>
          </div>
        )
      })}
    </div>
  )
}
