import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { usePlanHistoryQuery } from '@/store/api/Plan/Plan.query'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'

import { HistoryPlanItem } from '../HistoryPlanItem/HistoryPlanItem'

export const HistoryPlanComponent = () => {
  const { t } = useTranslation()
  const { hasHandleBilling } = useRedirectBasedOnPermissions()
  const isDefaultState = useAppSelector(selectItsDefaultState)

  useEffect(() => {
    hasHandleBilling()
  }, [isDefaultState])

  const { isLoading, data: historyPlanData, isError } = usePlanHistoryQuery()

  return (
    <WeScreen
      title={t('dashboard.historyPlan.accountBalance')}
      isLoading={isLoading}
      cardTransparent
      isError={isError || (!isLoading && !historyPlanData)}
      emptyStateText={t('dashboard.historyPlan.emptyState')}
      cardContent={historyPlanData?.map((purchase) => (
        <HistoryPlanItem data={purchase} />
      ))}
    />
  )
}
