import { useNavigate } from 'react-router-dom'

import { AppRoutes } from '@/constants/appRoutes'
import { LocalStorageKey } from '@/constants/localStorageKeys'
import { useLogoutMutation } from '@/store/api/Auth/Auth.query'
import localStorageService from '@/utils/localStorage'

export const useLogout = () => {
  const navigate = useNavigate()
  const [makeLogoutRequest] = useLogoutMutation()
  const logout = async () => {
    localStorageService.remove(LocalStorageKey.token)
    navigate(AppRoutes.login)
    await makeLogoutRequest()
  }

  return { logout }
}
