import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useLazySearchResultByIdQuery } from '@/store/api/SearchFace/SearchFace.query'

import { FaceSearchResult } from '../FaceSearch/components/FaceSearchResults/FaceSearchResults'

export const FaceMatchResultsScreen = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [searchResult, { data, isError, isLoading }] =
    useLazySearchResultByIdQuery(state)

  useEffect(() => {
    if (state) {
      searchResult({ searchId: state })
    }
  }, [state])
  return (
    <WeScreen
      title={t('dashboard.faceMatchResultsScreen.results')}
      isError={isError}
      isLoading={isLoading}
      content={
        data && (
          <FaceSearchResult
            data={data.output?.items ?? []}
            searchedImage={data.input[0].base64}
            onNewSearch={() => navigate(AppRoutes.dashboard)}
          />
        )
      }
    />
  )
}
