import { useNavigate } from 'react-router-dom'

import { AppRoutes } from '@/constants/appRoutes'

import { useDisableBasedOnPermissions } from './useDisableBasedOnPermissions'

export const useRedirectBasedOnPermissions = () => {
  const navigate = useNavigate()

  const {
    handleReports,
    handleBilling,
    handleEnterprises,
    handleUsers,
    viewReports,
  } = useDisableBasedOnPermissions()

  const hasHandleReportsRedirect = () => {
    if (!handleReports) {
      navigate(AppRoutes.reports)
    }
  }

  const hasHandleBillingRedirect = () => {
    if (!handleBilling) {
      navigate(AppRoutes.dashboard)
    }
  }
  const hasHandleEnterprisesRedirect = () => {
    if (!handleEnterprises) {
      navigate(AppRoutes.dashboard)
    }
  }
  const hasHandleUsersRedirect = () => {
    if (!handleUsers) {
      navigate(AppRoutes.dashboard)
    }
  }
  const hasViewReportsRedirect = () => {
    if (!viewReports) {
      navigate(AppRoutes.dashboard)
    }
  }

  return {
    hasHandleReports: hasHandleReportsRedirect,
    hasHandleBilling: hasHandleBillingRedirect,
    hasHandleEnterprises: hasHandleEnterprisesRedirect,
    hasHandleUsers: hasHandleUsersRedirect,
    hasViewReports: hasViewReportsRedirect,
    permissions: {
      handleReports,
      handleBilling,
      handleEnterprises,
      handleUsers,
      viewReports,
    },
  }
}
