import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query'

import { LocalStorageKey } from '@/constants/localStorageKeys'
import getJWTToken from '@/utils/getJWTToken'
import localStorageService from '@/utils/localStorage'

import { removeSession } from './api/Auth/Auth.slice'

const tokenExcludedEndpoints = ['login', 'register']

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: '', // This will be overriden by any api inject.

    prepareHeaders: (headers, api) => {
      const token = getJWTToken()
      const tokenNeeded = !tokenExcludedEndpoints.includes(api.endpoint)

      if (token && tokenNeeded) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  { maxRetries: 0 },
)

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // implement refresh token here
    localStorageService.remove(LocalStorageKey.token)
    api.dispatch(removeSession())
  }
  return result
}
