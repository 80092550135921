import { DocumentUpload } from 'iconsax-react'
import { isEmpty } from 'ramda'
import { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import DragAndDropEmpty from '@/assets/dragAndDropEmpty.svg?react'
import { BackHeader } from '@/components/BackHeader/BackHeader'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Progress } from '@/components/ui/progress'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import {
  useSearchResultsMutation,
  useUploadPicMutation,
} from '@/store/api/SearchFace/SearchFace.query'
import type { SearchPhotoResponse } from '@/store/api/SearchFace/SearchFace.types'
import { useLazyUserInfoQuery } from '@/store/api/Users/Users.query'
import { sleep } from '@/utils/sleep'

import { FaceSearchResult } from './components/FaceSearchResults/FaceSearchResults'

export const FaceSearch = () => {
  const [searchResponse, setSearchResponse] = useState<
    SearchPhotoResponse | undefined
  >()

  const navigation = useNavigate()
  const { t } = useTranslation()

  const [fileName, setFileName] = useState<string | undefined>()

  const [
    uploadPicMutation,
    {
      data: uploadPicData,
      isError,
      reset: resetUpload,
      isLoading: isLoadingFaceUpload,
    },
  ] = useUploadPicMutation()

  const [refreshUserData] = useLazyUserInfoQuery()

  const [searchPhoto, { reset: resetSearch }] = useSearchResultsMutation()

  const onDropRejected = (e: any) => {
    if (
      !isEmpty(e) &&
      e[0].errors &&
      e[0].errors[0].code === 'file-too-large'
    ) {
      toast.error(t('dashboard.facialMatching.fileError1'))
    } else {
      toast.error(t('dashboard.facialMatching.fileError2'))
    }
  }

  const onDrop = useCallback(async (acceptedFiles: any) => {
    if (!acceptedFiles || isEmpty(acceptedFiles)) return

    const formData = new FormData()
    formData.append('images', acceptedFiles[0])
    await uploadPicMutation(formData).unwrap()
    setFileName(acceptedFiles[0].name)
  }, [])

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    onDropRejected: onDropRejected,
    disabled: false,
    noClick: true,
    accept: {
      'image/png': ['.png', '.jpg', '.webp', '.jpeg'],
    },
    maxFiles: 1,
    maxSize: 6000000,
  })

  const onNewSearch = () => {
    setSearchResponse(undefined)
    resetUpload()
    resetSearch()
    setFileName(undefined)
  }

  const userImage = useMemo(() => {
    if (uploadPicData && uploadPicData.code === 'IMAGE_ERROR') {
      toast.error(uploadPicData.error)
      onNewSearch()
      return
    }
    if (uploadPicData && !isEmpty(uploadPicData.input)) {
      return uploadPicData.input[0].base64
    }
  }, [uploadPicData])

  const makeSearchPhoto = async () => {
    if (!uploadPicData?.id_search) return

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const response = await searchPhoto({
        searchId: uploadPicData.id_search,
      }).unwrap()
      setSearchResponse(response)
      if (response.output) {
        refreshUserData()
        return
      }
      await sleep(500)
    }
  }

  const onPressLoadFile = () => {
    // `current` points to the mounted file input element
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <WeScreen
      title={
        searchResponse && searchResponse.output
          ? t('dashboard.facialMatching.results')
          : ''
      }
      isError={isError}
      cardTransparent
      customVh={'100'}
      cardContent={
        searchResponse && searchResponse.output ? (
          <FaceSearchResult
            data={searchResponse.output.items}
            searchedImage={searchResponse.input[0].base64}
            onNewSearch={onNewSearch}
          />
        ) : (
          <>
            <BackHeader
              onPressBack={() => navigation(AppRoutes.dashboard)}
              backText=""
            />

            <div className="flex flex-col gap-6 w-full justify-center items-center max-xl:flex-col mt-8">
              <Card
                className={`flex justify-between flex-col  w-full ${
                  searchResponse ? 'h-[70vh]' : 'h-[80vh]'
                }  `}
              >
                <div
                  {...getRootProps()}
                  className="flex flex-col m-2 lg:m-6 border border-dashed rounded-lg  flex-1 justify-center items-center p-6"
                >
                  <div className=" flex flex-col justify-center items-center flex-1 ">
                    <input {...getInputProps()} />
                    <div className="mb-8">
                      {userImage ? (
                        <img
                          src={userImage}
                          alt="Uploaded"
                          className="max-w-40 max-h-40 "
                        />
                      ) : (
                        <DragAndDropEmpty />
                      )}
                    </div>

                    <h1 className="text-lg font-semibold tracking-wide mb-2">
                      {t('dashboard.facialMatching.facialMatching')}
                    </h1>
                    {!searchResponse && (
                      <div className="flex flex-col gap-4 justify-center items-center">
                        <div className="flex flex-col lg:flex-row items-center gap-2">
                          <p className="text-textAccent tracking-wider">
                            {t('dashboard.facialMatching.addImage')}
                          </p>
                          <div className="flex flex-row gap-2">
                            <div className="rounded-full py-1 px-2 bg-[#C6F5FF] text-xs text-[#037797]">
                              JPG
                            </div>
                            <div className="rounded-full py-1 px-2 bg-[#C8FFD4] text-[#1E924D] text-xs">
                              PNG
                            </div>
                            <div className="rounded-full py-1 px-2 bg-[#FFFDCB] text-[#676427] text-xs">
                              WEBP
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-3">
                          <Button
                            onClick={onPressLoadFile}
                            isLoading={isLoadingFaceUpload}
                            variant={'secondary'}
                          >
                            {t('dashboard.facialMatching.chooseFile')}
                            <DocumentUpload size={18} className="ml-2" />
                          </Button>
                          <Button
                            className="z-50"
                            onClick={makeSearchPhoto}
                            disabled={!uploadPicData}
                          >
                            {t('dashboard.facialMatching.runSearch')}
                          </Button>
                        </div>
                        <p className="text-textAccent">
                          {t('dashboard.facialMatching.orDropFiles')}
                        </p>
                        <p className="text-textAccent">Max. 6MB</p>
                      </div>
                    )}
                    {searchResponse && (
                      <p className="text-textAccent tracking-wider">
                        {fileName}
                      </p>
                    )}
                  </div>
                  {!searchResponse && (
                    <div className=" text-center lg:flex lg:flex-row  lg:justify-end lg:w-full">
                      <p className="text-textAccent text-[8px] lg:ml-1">
                        {t('dashboard.facialMatching.wecheckDoesNotCollect')}{' '}
                        &nbsp;
                        <span
                          className="text-linkColor text-[8px] text-nowrap"
                          role="button"
                          onClick={() =>
                            window.open(
                              t('dashboard.common.termsAndConditionsLink'),
                              '_blank',
                            )
                          }
                          tabIndex={0}
                        >
                          {t('dashboard.facialMatching.termsOfService')}&nbsp;
                        </span>
                        {t('dashboard.facialMatching.and')}&nbsp;
                        <span
                          className="text-linkColor"
                          role="button"
                          onClick={() =>
                            window.open(
                              t('dashboard.facialMatching.privacyPolicyLink'),
                              '_blank',
                            )
                          }
                          tabIndex={0}
                        >
                          {t('dashboard.facialMatching.CCPANotice')}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </Card>
              {searchResponse && (
                <Card className="flex justify-between flex-col  px-4 py-6 basis-1/2 gap-6 w-full">
                  <div className="flex flex-col gap-1">
                    <p className="text-xl font-semibold tracking-wide flex flex-row">
                      {searchResponse.progress}%{' '}
                      {t('dashboard.facialMatching.loading')}
                      <p className="animate-pulse">...</p>
                    </p>
                    <p className="tracking-wider text-textAccent">
                      {searchResponse.message}
                    </p>

                    <Progress
                      value={searchResponse.progress ?? 0}
                      className="h-4 mt-1"
                    />
                  </div>
                </Card>
              )}
            </div>
          </>
        )
      }
    />
  )
}
