export enum AppRoutes {
  //Root
  home = '/',
  aboutUs = '/about-us',
  socialHome = 'https://www.wecheck.ai',
  useCases = '/use-cases',
  blog = '/blog',

  //Login
  forgotPaswordCheckYourInbox = '/login/forgot-pasword-check-your-inbox',
  login = '/login/login-form',
  forgotPassword = '/login/forgot-password',
  createPassword = '/login/create-password',

  //SignUp
  registerStart = '/sign-up/sign-up-start',
  registerEmailVerification = '/sign-up/email-verification',
  registerFirstStep = '/sign-up/first-step',
  registerSecondStep = '/sign-up/second-step',
  registerFinalStep = '/sign-up/final-step',
  inviteFirstStep = '/sign-up/invite-first-step',
  inviteSecondStep = '/sign-up/invite-second-step',

  //Dashboard
  feedBackScreen = '/dashboard/feedback-screen',
  dashboard = '/dashboard/dashboard-home',
  contactUs = '/dashboard/contact-us',
  getCredits = '/dashboard/get-credits',
  feedbackPayment = '/dashboard/feedback-payment',
  historyPlan = '/dashboard/history-plan',
  reports = '/dashboard/reports',
  FaceMatchResults = '/dashboard/face-match-results',
  team = '/dashboard/team',
  account = '/dashboard/settings',
  faceSearch = '/dashboard/face-search',
  reportGeneralInfo = '/dashboard/report-general-info',
  postsInsights = '/dashboard/posts-insights',
  postList = '/dashboard/post-list',
  newSearchInitialReportSetup = '/dashboard/new-search-initial-report-setup',
  newSearchConsent = '/dashboard/new-search-consent',
  newSearchFocusedTopics = '/dashboard/new-search-focused-topics',
  newSearchFormSubjectInfo = '/dashboard/new-search-subject-info',
  newSearchCompanyInfo = '/dashboard/new-search-company-info',
  newSearchLegalDisclaimer = '/dashboard/new-search-legal-disclaimer',
  reportInProgress = '/dashboard/report-in-progress',
}
