import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import 'react-phone-number-input/style.css'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch } from '@/hooks/redux'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useCheckTokenGuard } from '@/hooks/useCheckTokenGuard'
import { useInviteSingUpMutation } from '@/store/api/Auth/Auth.query'
import { setSession } from '@/store/api/Auth/Auth.slice'
import { inviteNameFlowSchema, type inviteNameFlowType } from '@/utils/schemas'

export const InviteScreenFormSecondStep = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [inviteSingUp, { isLoading }] = useInviteSingUpMutation()
  const { state } = useLocation()
  useCheckTokenGuard(state)

  const dispatch = useAppDispatch()

  const form = useForm<inviteNameFlowType>({
    resolver: zodResolver(inviteNameFlowSchema),
    defaultValues: {
      firstname: '',
      lastname: '',
    },
  })

  const {
    handleSubmit,
    control,
    formState: { errors = {} },
  } = form

  const inviteUser = async (firstname: string, lastname: string) => {
    try {
      if (state.password && state.token) {
        const response = await inviteSingUp({
          firstname: firstname,
          lastname: lastname,
          password: state.password,
          token: state.token,
        }).unwrap()
        dispatch(setSession(response.info))

        navigate(AppRoutes.dashboard)
      }
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.onboardingError'))
      navigate(AppRoutes.inviteFirstStep)
      console.warn('error on onboarding', e)
    }
  }

  const onSubmit = (data: any) => {
    inviteUser(data.firstname, data.lastname)
  }

  console.log(errors)

  return (
    <div className="flex flex-col gap-8 w-full max-w-[436px]">
      <div className="flex flex-col gap-5 w-full">
        <div className="flex justify-between">
          <p className="text-[40px] font-bold tracking-wide">
            {t(
              'dashboard.landingSingUpInvite.landingSingUpInviteSecondStep.welcome',
            )}
          </p>
        </div>
        <p className="text-1xl md:min-w-[436px] text-textAccent tracking-tight">
          {t(
            'dashboard.landingSingUpInvite.landingSingUpInviteSecondStep.getStarted',
          )}
        </p>
      </div>

      <Form {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-6 "
        >
          <FormField
            control={control}
            name="firstname"
            render={({ field }) => (
              <FormItem className="w-full  bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t(
                      'dashboard.landingSingUpInvite.landingSingUpInviteSecondStep.firstName',
                    )}
                  />
                </FormControl>
                <FormMessage data-name="firstname" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="lastname"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t(
                      'dashboard.landingSingUpInvite.landingSingUpInviteSecondStep.lastName',
                    )}
                  />
                </FormControl>
                <FormMessage data-name="lastname" />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
            isLoading={isLoading}
          >
            {t(
              'dashboard.landingSingUpInvite.landingSingUpInviteSecondStep.continue',
            )}
          </Button>
        </form>
      </Form>
    </div>
  )
}
