import { Moon, Sun1 } from 'iconsax-react'
import * as React from 'react'

import useTheme from '@/hooks/useSwitchTheme'
import { cn } from '@/lib/utils'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const DarkModeSwitch = () => {
  const { isDarkMode, toggleTheme } = useTheme()
  const [isChecked, setIsChecked] = React.useState(isDarkMode)

  const handleCheckedChange = () => {
    setIsChecked((prev: any) => !prev)
    toggleTheme()
  }

  return (
    <div
      className={cn(
        'relative inline-flex xl:p-3.5 p-3 items-center h-[32px] w-[56px] cursor-pointer rounded-full border border-neutralColor30 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 focus:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 bg-transparent',
      )}
      onClick={handleCheckedChange}
    >
      <div
        className={cn(
          'absolute left-[6px] flex transition-colors duration-300',
        )}
      >
        <Sun1
          size={14}
          color={
            isChecked ? weCheckPalette.neutralColor : weCheckPalette.staticWhite
          }
          className="z-10"
        />
      </div>
      <div
        className={cn(
          'absolute right-[7px] flex items-center justify-center  transition-colors duration-300',
        )}
      >
        <Moon
          size={14}
          color={
            !isChecked
              ? weCheckPalette.neutralColor
              : weCheckPalette.staticWhite
          }
          className="z-10"
        />
      </div>
      <div
        className={cn(
          'absolute h-[24px] w-[24px] rounded-full bg-primaryColor shadow-lg transition-transform',
          isChecked ? 'translate-x-[15px]' : 'translate-x-[-11px]',
        )}
      />
    </div>
  )
}
