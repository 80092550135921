interface EnterpriseUserInfo {
  id: string
  firstname: string
  lastname: string
  phoneNumber: string
  email: string
  status: string
  role: string
  enterprise: string
  permissions: string[]
}

export interface EnterpriseUserResponse {
  info: EnterpriseUserInfo[]
}

export interface EnterpriseUserRequest {
  status: 'active' | 'pending' | 'blocked' | 'pending_on_boarding' | 'deleted'
}

export interface GetEnterpriseRolesResponse {
  message: string
  status: number
  info: {
    permissions: string[]
    roles: string[]
  }
}

export interface updateEnterpriseUserRoleRequest {
  id: string
  role: string
}

export interface deleteEnterpriseUserRequest {
  userId: string
}

export interface inviteEnterpriseNewUserRequest {
  email: string
  role: string
}

export interface socialCheckCrudResponse {
  message: string
  status: number
  info: SocialCheckItem[]
}

export enum EReportType {
  AiAnalyst = 'ai_analyst_report',
  Ai = 'ai_report',
  AdverseMedia = 'adverse_media_report',
  Assited = 'assisted_report',
  FacialMatching = 'facial_matching',
}

export enum EWebhookEvent {
  N_A = 'n/a',
  SENT = 'form.sent',
  VIEWED = 'form.viewed',
  STARTED = 'form.started',
  COMPLETED = 'form.completed',
}

export enum ESocialCheckStatus {
  None = 'None',
  NotReleased = 'NotReleased',
  InQueue = 'InQueue',
  InProgress = 'InProgress',
  Complete = 'Complete',
  Error = 'Error',
  PendingDownloadPdf = 'PendingDownloadPdf',
  PendingReview = 'PendingReview',
  ErroPdf = 'ErrorPdf',
  Cancelled = 'Cancelled',
}

export type SocialCheckResponse = SocialCheckItemWrapper[]

export interface SocialCheckItem {
  status: string
  pdf: string
  requester: {
    firstname: string
    lastname: string
    email: string
  }
  reportType: EReportType
  complianceStatus: EWebhookEvent
  lastUpdate: string
  inputs: SocialCheckInputItem
  reportDate: string
  pdfViewed: boolean
  hiddenDigitalReport: boolean
  id: string
  enterprise: {
    id: string
    name: string
    credits: number
  }
  complianceSigned: string
  disclosureSigned: string
  summarySigned: string
  dynamicSigned: string
  otherAuthorizationSigned: string
}

export interface SocialCheckItemWrapper {
  type: 'socialCheck'
  creation_date: string
  data: SocialCheckItem
}

export interface SocialCheckInputItem {
  name: string
  nickname: string
  city: string
  state: string
  country: string
  email: string
  highSchool: string
  college: string
  phone: string
  twitterUrl: string
  facebookUrl: string
  instagramUrl: string
  linkedInUrl: string
  tikTokUrl: string
  ageOrDateOfBirth: string
}

export interface UpdateEnterpriseData {
  enterpriseId: string
  companyName: string | undefined
  companyAddress: string | undefined
}
export interface listOfInvitesRequest {
  userId: string
}
export interface invitesInfo {
  status: string
  email: string
  role: 'owner' | 'manager' | 'operational_user' | 'view_user'
  sender: string
}

export interface listOfInvitesResponse {
  info: invitesInfo[]
}
