import Lottie from 'lottie-react'
import { useTranslation } from 'react-i18next'
import emptyStateLottie from '@/assets/lotties/emptystate.json'
import { CardContent } from '@/components/ui/card'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'

export const EmptyState = ({
  text,
  alternativeAsset,
}: {
  text?: string
  alternativeAsset?: any | undefined
}) => {
  const isMobile = useMobileMediaQuery()
  return (
    <CardContent className="lg:h-3/4 flex justify-center items-center">
      <div
        id="emptyState"
        className="flex flex-col justify-center items-center p-8"
      >
        {alternativeAsset ? (
          <img
            src={alternativeAsset}
            alt={alternativeAsset}
            height={329}
            width={329}
          />
        ) : (
          <Lottie
            animationData={emptyStateLottie}
            loop={false}
            style={{
              width: isMobile ? 300 : 320,
              height: isMobile ? 300 : 320,
            }}
          />
        )}
        <p
          className={`text-base font-medium text-center neutral-500 text-textAccent tracking-wider ${
            isMobile && 'text-xs'
          }`}
        >
          {text}
        </p>
      </div>
    </CardContent>
  )
}
