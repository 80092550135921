import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'

import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppSelector } from '@/hooks/redux'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  useOtpVerificationMutation,
  useSendOtpEmailMutation,
} from '@/store/api/Auth/Auth.query'
import { selectCredentials } from '@/store/api/SignUp/SignUp.selector'
import type { emailValidationType } from '@/utils/schemas'
import { emailValidationSchema } from '@/utils/schemas'

import { useSignUpFirstStepGuard } from './guards/useSignUpFirstStepGuard'

export const EmailVerification = () => {
  const { t } = useTranslation()
  useSignUpFirstStepGuard()
  const navigate = useNavigate()
  const userEmail = useAppSelector(selectCredentials).email
  const [sendOtpEmail, { isLoading: isLoadingSendEmail }] =
    useSendOtpEmailMutation()
  const [verifyOtpEmail, { isLoading: isLoadingVerifyEmail }] =
    useOtpVerificationMutation()

  const sendEmail = async () => {
    try {
      await sendOtpEmail({ email: userEmail, reason: 'verify_email' }).unwrap()
      toast.success(t('feedback.toast.OTPCode'))
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.errorOTP'))
    }
  }

  const verifyEmail = async (otp: number) => {
    try {
      await verifyOtpEmail({
        email: userEmail,
        reason: 'verify_email',
        otp,
      }).unwrap()
      toast.success(t('feedback.toast.emailVerified'))
      navigate(AppRoutes.registerFirstStep)
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.invalidOTP'))
    }
  }

  useEffect(() => {
    sendEmail()
  }, [])

  const form = useForm<emailValidationType>({
    resolver: zodResolver(emailValidationSchema),
    defaultValues: {
      pin: '',
    },
  })

  const {
    handleSubmit,
    control,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors = {} },
  } = form

  const onSubmit = (data: any) => {
    const otp = parseInt(data.pin, 10)
    verifyEmail(otp)
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pasteData = event.clipboardData.getData('text')

    if (pasteData.length === 6) {
      const inputs = event.currentTarget.form?.elements
      if (inputs) {
        pasteData.split('').forEach((char, index) => {
          ;(inputs[index] as HTMLInputElement).value = char
          ;(inputs[index] as HTMLInputElement).focus()
        })
      }
    }
  }

  return (
    <div className="flex flex-col gap-8 w-full items-center">
      <div className="flex flex-col gap-5 w-full items-center">
        <p className="text-[40px] font-bold tracking-wide w-[456px] max-sm:w-[310px] text-center">
          {t('dashboard.landingSingUpEmailVerification.checkYourInbox')}
        </p>
        <p className="text-base w-[456px] max-sm:w-[310px] font-medium  text-textAccent tracking-tight text-pretty text-center">
          {t('dashboard.landingSingUpEmailVerification.weSentA')} {userEmail}
          {t('dashboard.landingSingUpEmailVerification.pleaseCheckYourEmail')}
        </p>
      </div>

      <Form {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-6 w-[456px] max-sm:w-[310px]"
        >
          <FormField
            control={control}
            name="pin"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputOTP
                    maxLength={6}
                    {...field}
                    disabled={isLoadingSendEmail || isLoadingVerifyEmail}
                    onPaste={() => handlePaste}
                  >
                    <InputOTPGroup className="flex gap-8 w-full max-sm:gap-2">
                      <InputOTPSlot
                        className={
                          'w-14 h-14 border rounded-lg border-slate-300 p-3'
                        }
                        index={0}
                      />
                      <InputOTPSlot
                        className={
                          'w-14 h-14 border rounded-lg border-slate-300 p-3'
                        }
                        index={1}
                      />
                      <InputOTPSlot
                        className={
                          'w-14 h-14 border rounded-lg border-slate-300 p-3'
                        }
                        index={2}
                      />
                      <InputOTPSlot
                        className={
                          'w-14 h-14 border rounded-lg border-slate-300 p-3'
                        }
                        index={3}
                      />
                      <InputOTPSlot
                        className={
                          'w-14 h-14 border rounded-lg border-slate-300 p-3'
                        }
                        index={4}
                      />
                      <InputOTPSlot
                        className={
                          'w-14 h-14 border rounded-lg border-slate-300 p-3'
                        }
                        index={5}
                      />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
              </FormItem>
            )}
          />
          <Button
            variant={'ghost'}
            onClick={async (e) => {
              e.preventDefault()
              sendEmail()
            }}
            className="w-min p-0 h-5"
          >
            <p className="text-dynamicBlue text-sm font-medium tracking-tight bg-white">
              {t('dashboard.landingSingUpEmailVerification.clickToResend')}
            </p>
          </Button>
          <Button
            disabled={!form.formState.isValid || isLoadingVerifyEmail}
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11 w-full"
          >
            {t('dashboard.landingSingUpEmailVerification.continue')}
          </Button>
        </form>
      </Form>
    </div>
  )
}
