import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useRecoverPasswordMutation } from '@/store/api/Auth/Auth.query'
import type { forgotPasswordType } from '@/utils/schemas'
import { forgotPasswordSchema } from '@/utils/schemas'

export const ForgotPasswordForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const params = new URLSearchParams()
  params.append('token', 'holaSoyUnTokenDeRecoverPass')
  const form = useForm<forgotPasswordType>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  })

  const {
    handleSubmit,
    control,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors = {} },
  } = form

  const [recoverPassword, { isLoading }] = useRecoverPasswordMutation()

  const recoverPasswordRequest = async (email: string) => {
    try {
      await recoverPassword({
        email,
      }).unwrap()
      toast.success(t('dashboard.landingForgotPassword.emailSend'))
      navigate(AppRoutes.forgotPaswordCheckYourInbox)
    } catch (e) {
      catchWithSentry(e)
      if (e.data.code === 'INVALID_CREDENTIALS') {
        toast.error(t('dashboard.landingForgotPassword.firstError'))
      } else {
        toast.error(t('dashboard.landingForgotPassword.error'))
      }
      console.warn(e)
    }
  }

  const onSubmit = (data: any) => {
    recoverPasswordRequest(data.email)
  }

  return (
    <div className="flex flex-col gap-8 w-full max-w-[436px]">
      <div className="flex flex-col gap-5">
        <p className="text-[40px] font-bold tracking-wide">
          {t('dashboard.landingForgotPassword.forgotPassword')}
        </p>
        <p className="text-1xl text-textAccent tracking-tight">
          {t('dashboard.landingForgotPassword.forgotPasswordText')}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t('dashboard.landingForgotPassword.example')}
                  />
                </FormControl>
                <FormMessage data-name="email" />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
            isLoading={isLoading}
          >
            {t('dashboard.landingForgotPassword.continue')}
          </Button>
        </form>
      </Form>
    </div>
  )
}
