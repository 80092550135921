import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { useNavigate } from 'react-router-dom'
import 'react-phone-number-input/style.css'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import { selectSignUpFirstStep } from '@/store/api/SignUp/SignUp.selector'
import { setFirstStep } from '@/store/api/SignUp/SignUp.slice'
import type { signUpFirstStepType } from '@/utils/schemas'
import { signUpFirstStepSchema } from '@/utils/schemas'

import { useSignUpFirstStepGuard } from './guards/useSignUpFirstStepGuard'

export const SignUpFirstStep = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  useSignUpFirstStepGuard()
  const signUpFirstStepData = useAppSelector(selectSignUpFirstStep)

  const form = useForm<signUpFirstStepType>({
    resolver: zodResolver(signUpFirstStepSchema),
    defaultValues: {
      firstName: signUpFirstStepData.firstName || '',
      lastName: signUpFirstStepData.lastName || '',
      phoneNumber: signUpFirstStepData.phoneNumber || '',
    },
  })

  const {
    handleSubmit,
    control,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors = {}, isValid },
  } = form

  const onSubmit = (data: any) => {
    dispatch(
      setFirstStep({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
      }),
    )
    navigate(AppRoutes.registerSecondStep)
  }

  return (
    <div className="flex flex-col gap-8 md:w-1/3">
      <div className="flex flex-col gap-5 w-full">
        <div className="flex justify-between items-center">
          <p className="text-[40px] font-bold tracking-wide text-center">
            {t('dashboard.landingSingUp.singUpFistStep.welcome')}
          </p>
          <p className="text-xl font-medium  text-textAccent">1/3</p>
        </div>
        <p className="text-1xl md:min-w-[436px] text-textAccent tracking-tight ">
          {t('dashboard.landingSingUp.singUpFistStep.welcomeText')}
        </p>
      </div>

      <Form {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-6 "
        >
          <FormField
            control={control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="w-full  bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t(
                      'dashboard.landingSingUp.singUpFistStep.firstName',
                    )}
                  />
                </FormControl>
                <FormMessage data-name="firstName" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t(
                      'dashboard.landingSingUp.singUpFistStep.lastName',
                    )}
                  />
                </FormControl>
                <FormMessage data-name="lastName" />
              </FormItem>
            )}
          />
          <FormField
            name="phoneNumber"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full">
                  <PhoneInputWithCountry
                    className="w-full h-11 border border-neutralColor30 rounded-md text-sm shadow-sm px-3 py-1 "
                    rules={{ required: true }}
                    {...field}
                    placeholder={t(
                      'dashboard.landingSingUp.singUpFistStep.phoneNumber',
                    )}
                    name="phoneNumber"
                    defaultCountry="US"
                    limitMaxLength={true}
                    control={control}
                  />
                </FormControl>
                <FormMessage data-name="phoneNumber" />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
            disabled={!isValid}
          >
            {t('dashboard.landingSingUp.singUpFistStep.continue')}
          </Button>
        </form>
      </Form>
    </div>
  )
}
