import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '@/store/store'

import type { UserInfoResponse } from './Users.types'

export const selectUser = (user: UserInfoResponse | undefined) => user

export const selectUserInfo = (state: RootState) => state.userInfo

export const selectPermissions = (state: RootState) =>
  state.userInfo.permissions

export const selectItsDefaultState = (state: RootState) =>
  state.userInfo.email === ''

export const selectIsPending = createSelector(
  selectUser,
  (user?: UserInfoResponse) => user?.status === 'pending',
)

export const selectUserFullname = createSelector(
  selectUser,
  (user?: UserInfoResponse) => `${user?.firstname} ${user?.lastname}`,
)

export const selectEmail = createSelector(
  selectUser,
  (user?: UserInfoResponse) => `${user?.email}`,
)
export const selectOrgName = createSelector(
  selectUser,
  (user?: UserInfoResponse) => `${user?.enterprise.name}`,
)

export const selectPhoneNumber = createSelector(
  selectUser,
  (user?: UserInfoResponse) => `${user?.phoneNumber}`,
)

export const selectAiReportCredits = createSelector(
  selectUser,
  (user?: UserInfoResponse) => user?.enterprise?.credits.aiReportCredits ?? 0,
)

export const selectAiAnalystReportCredits = createSelector(
  selectUser,
  (user?: UserInfoResponse) =>
    user?.enterprise?.credits.aiAnalystReportCredits ?? 0,
)

export const selectAdverseMediaReportCredits = createSelector(
  selectUser,
  (user?: UserInfoResponse) =>
    user?.enterprise?.credits.adverseMediaReportCredits ?? 0,
)

export const selectAssistedReportCredits = createSelector(
  selectUser,
  (user?: UserInfoResponse) =>
    user?.enterprise?.credits.assistedReportCredits ?? 0,
)

export const selectFacialMatchingCredits = createSelector(
  selectUser,
  (user?: UserInfoResponse) =>
    user?.enterprise?.credits.facialMatchingCredits ?? 0,
)

export const selectCreditsFromStore = (state: RootState) =>
  state.userInfo.credits

export const selectUserFirstLetter = createSelector(
  selectUser,
  (user?: UserInfoResponse) => {
    if (user?.firstname) {
      return user.firstname[0]
    } else if (user?.lastname[0]) {
      return user?.lastname[0]
    }
    return ''
  },
)

export const selectFrom = (state: RootState) => state.userInfo.from

export const selectHasMinorSearch = (state: RootState) =>
  state.userInfo.hasMinorSearch
