import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useCheckTokenGuard } from '@/hooks/useCheckTokenGuard'
import { useChangePasswordMutation } from '@/store/api/Auth/Auth.query'
import type { createPasswordType } from '@/utils/schemas'
import { createPasswordSchema } from '@/utils/schemas'

export const CreatePasswordForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token: null | string = params.get('token')

  useCheckTokenGuard(token)

  const form = useForm<createPasswordType>({
    resolver: zodResolver(createPasswordSchema),
    defaultValues: {
      createPassword: '',
      confirmPassword: '',
    },
  })

  const {
    handleSubmit,
    control,
    formState: { errors = {}, dirtyFields },
    watch,
  } = form

  const [changePassword, { isLoading, isSuccess }] = useChangePasswordMutation()

  const changePasswordRequest = async (token: string, newPassword: string) => {
    try {
      await changePassword({
        newPassword,
        token,
      }).unwrap()
      toast.success(t('feedback.toast.passwordChanged'))
      navigate(AppRoutes.login)
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.error'))
      console.warn(e)
    }
  }

  const passNotMatch =
    Object.keys(dirtyFields).includes('confirmPassword') &&
    watch().confirmPassword !== watch().createPassword

  const onSubmit = (data: any) => {
    if (passNotMatch) {
      return
    } else {
      changePasswordRequest(token as string, data.createPassword)
    }
    if (isSuccess) {
      navigate(AppRoutes.login)
    }
  }

  return (
    <div className="flex flex-col gap-8 w-full max-w-[436px]">
      <div className="flex flex-col gap-5">
        <p className="text-[40px] font-bold tracking-wide">
          {t('dashboard.landingCreatePassword.createPassword')}
        </p>
        <p className="text-1xl text-textAccent tracking-tight">
          {t('dashboard.landingCreatePassword.welcomeBack')}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <FormField
            control={control}
            name="createPassword"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder="Create Password"
                    type="password"
                    showPassIcon
                  />
                </FormControl>
                <FormMessage data-name="createPassword" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder="Confirm Password"
                    type="password"
                    showPassIcon
                  />
                </FormControl>
                {passNotMatch && !errors.confirmPassword && (
                  <p className="text-[0.8rem] font-medium text-destructive m-">
                    {t('dashboard.landingCreatePassword.passwordsNotMatch')}
                  </p>
                )}
                <FormMessage data-name="confirmPassword" />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
            isLoading={isLoading}
          >
            {t('dashboard.landingCreatePassword.createPasswordButton')}
          </Button>
        </form>
      </Form>
    </div>
  )
}
