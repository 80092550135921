import React from 'react'

interface UseCaseCardTypes {
  title?: string
  description?: string
}

export const UseCaseCard = ({ title, description }: UseCaseCardTypes) => {
  return (
    <div className="flex flex-col gap-3">
      <p className="text-neutralColor text-base font-medium">{title}</p>
      <p className="text-neutralColor60 text-sm font-medium tracking-tight">
        {description}
      </p>
    </div>
  )
}
