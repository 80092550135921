import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import NewSearchErrorImage from '@/assets/NewSearchErrorImage.svg'
import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppSelector } from '@/hooks/redux'
import { selectHasToken } from '@/store/api/Auth/Auth.selector'

export const Error404 = ({
  title,
  text,
  absolute = true,
}: {
  title?: string
  text?: string
  absolute?: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const hasSession = useAppSelector(selectHasToken)

  return (
    <div
      className={`${
        absolute && 'absolute'
      } inset-0 flex flex-col gap-6 w-full h-full justify-center items-center`}
    >
      <div
        id="emptyState"
        className="flex flex-col justify-center items-center p-8"
      >
        <h1 className="text-center text-textNormal text-[32px] font-base">
          {title ? title : t('dashboard.errorScreen.pageNotAvailable')}
        </h1>
        <div>
          <img src={NewSearchErrorImage} alt="Error404" />
        </div>

        <p className="text-center text-textAccent text-base font-medium my-6">
          {text ? text : t('dashboard.errorScreen.thePage')}
        </p>
        <Button
          onClick={
            hasSession
              ? () => navigate(AppRoutes.dashboard)
              : () => navigate(AppRoutes.login)
          }
          className="px-7 py-5 text-neutral-50 text-base font-medium"
        >
          {t('dashboard.errorScreen.returnToStart')}
        </Button>
      </div>
    </div>
  )
}
