import { ArcElement } from 'chart.js'
import Chart from 'chart.js/auto'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import type { ChannelsValue } from '@/store/api/Person/Person.types'

interface ChannelVolumeTypes {
  channelsValueData: ChannelsValue[] | undefined
  totalPosts: number | undefined
}
export const ChannelVolume = ({
  channelsValueData,
  totalPosts,
}: ChannelVolumeTypes) => {
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()
  Chart.register(ArcElement)
  const { t } = useTranslation()
  const colors = [
    '#EA334B',
    '#3070F5',
    '#AE59DC',
    '#57BEB5',
    '#F5C130',
    '#EA8B34',
    '#A8A8A8',
  ]
  const data = {
    datasets: [
      {
        data: channelsValueData?.map((data) => data.posts),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        cutout: isSmallMobile ? 65 : isMobile ? 90 : 105,
      },
    ],
  }
  return (
    <Card className="flex flex-col lg:flex-row lg:justify-around  gap-6 bg-baseColorHighlight shadow-none border-0 rounded-xl p-6">
      <div className="flex flex-col gap-2 lg:items-start lg:justify-center">
        <p className="text-neutralColor text-xl font-medium tracking-wide">
          {t('dashboard.postsInsights.channelVolume')}
        </p>
        <p className="text-neutralColor80 text-base font-normal tracking-tight">
          {t('dashboard.postsInsights.channelVolumeText')}
        </p>
      </div>
      <div className="flex flex-col items-center justify-center gap-8">
        <div className="max-md:w-[80%] flex items-center justify-center relative">
          <div className="flex flex-col items-center w-22 gap-1 absolute transform-translate-x-[-50%]">
            <p className="text-neutralColor text-xl font-medium max-md:text-lg">
              {t('dashboard.postsInsights.totalPosts')}
            </p>
            <p className="text-neutralColor text-textNormal text-3xl font-semibold max-md:text-2xl">
              {totalPosts}
            </p>
          </div>
          <Doughnut data={data} />
        </div>
        <div className="flex gap-3 ">
          {channelsValueData?.map((data, index) => (
            <div className="flex items-center justfy-center gap-2">
              <div
                style={{ backgroundColor: colors[index] }}
                className="w-2.5 h-2.5"
              />
              <p className="text-neutralColor text-textNormal text-xs font-medium tracking-tight">
                {data.channel}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Card>
  )
}
