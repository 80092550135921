import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft2 } from 'iconsax-react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/text-area'
import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useRudderStack } from '@/hooks/useRudderStack'
import { selectId } from '@/store/api/Auth/Auth.selector'
import { useSetOnboardingMutation } from '@/store/api/SignUp/SignUp.query'
import {
  selectCredentials,
  selectOnboardingRequest,
} from '@/store/api/SignUp/SignUp.selector'
import { clearSignUpState, setLastStep } from '@/store/api/SignUp/SignUp.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { signUpFinalStepType } from '@/utils/schemas'
import { signUpFinalStepSchema } from '@/utils/schemas'

import { useSignUpFinalStepGuard } from './guards/useSignUpFinalStepGuard'

export const SignUpFinalStep = () => {
  const { t } = useTranslation()
  useSignUpFinalStepGuard()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const navigation = useNavigate()

  const { trackEvent } = useRudderStack()

  const userCredentials = useAppSelector(selectCredentials)
  const userOnboarding = useAppSelector(selectOnboardingRequest)
  const userId = useAppSelector(selectId)

  const [makeOnboarding, { isLoading }] = useSetOnboardingMutation()

  const form = useForm<signUpFinalStepType>({
    resolver: zodResolver(signUpFinalStepSchema),
    defaultValues: {
      comment: '',
      backgroundChecksNumber: '',
    },
  })
  const { handleSubmit, control } = form

  const onPressBack = () => {
    navigation(-1)
  }

  const finishOnboarding = async (backgroundChecksNumber: string) => {
    trackEvent(Events.ONBOARDING_END, userId, {
      first_name: userOnboarding.firstName,
      last_name: userOnboarding.lastName,
      phone_number: userOnboarding.phoneNumber,
      email: userCredentials.email,
      company_name: userOnboarding.companyName,
      company_address: userOnboarding.companyAddress,
      number_reports: backgroundChecksNumber,
    })
    navigate(AppRoutes.dashboard, { state: { comesFromOnboarding: true } })

    setTimeout(() => dispatch(clearSignUpState()), 500)
  }

  const onSubmit = async (data: any) => {
    const backgroundChecks = Number(data.backgroundChecksNumber as string)

    try {
      dispatch(
        setLastStep({
          backgroundChecks,
        }),
      )
      await makeOnboarding({
        ...userOnboarding,
        backgroundCredits: backgroundChecks,
        comment: data.comment,
      }).unwrap()

      finishOnboarding(data.backgroundChecksNumber)
    } catch (e) {
      catchWithSentry(e)
      if (e.data.code === 'ENTERPRISE_ALREADY_EXISTS') {
        toast.error(t('feedback.toast.companyNameInUse'))
        navigate(AppRoutes.registerSecondStep)
      } else {
        toast.error(t('feedback.toast.errorOnOnboarding'))
        navigate(AppRoutes.login)
      }
    }
  }

  const Skip = async () => {
    try {
      await makeOnboarding({
        ...userOnboarding,
        backgroundCredits: 0,
        comment: '',
      }).unwrap()
      finishOnboarding('0')
    } catch (e) {
      catchWithSentry(e)
      if (e.data.code === 'ENTERPRISE_ALREADY_EXISTS') {
        toast.error(toast.error(t('feedback.toast.companyNameInUse')))
        navigate(AppRoutes.registerSecondStep)
      } else {
        toast.error(t('feedback.toast.errorOnOnboarding'))
        navigate(AppRoutes.login)
      }
    }
  }

  return (
    <div className="flex flex-col gap-8 md:w-1/3 ">
      <div className="flex flex-col gap-5 w-full">
        <div className="flex flex-row gap-5">
          <Button
            onClick={onPressBack}
            variant={'ghost'}
            type="button"
            className="py-3 px-2 font-medium tracking-widest border"
          >
            <ArrowLeft2 color={weCheckPalette.neutralColor} />
          </Button>
          <p className="text-textNormal text-2xl font-semibold">
            {t('dashboard.landingSingUp.singUpThirdStep.back')}
          </p>
        </div>
        <div className="flex justify-between gap-5 items-center">
          <p className="text-[40px] font-bold tracking-wide max-w-[412px]">
            {t(
              'dashboard.landingSingUp.singUpThirdStep.finalizeYourAccessRequest',
            )}
          </p>
          <p className="text-xl font-medium text-textAccent">3/3</p>
        </div>
        <p className="text-1xl text-textAccent tracking-tight">
          {t('dashboard.landingSingUp.singUpThirdStep.almostDone')}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <FormField
            control={control}
            name="backgroundChecksNumber"
            render={({ field }) => (
              <FormItem className="w-full  bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    type="number"
                    placeholder={t(
                      'dashboard.landingSingUp.singUpThirdStep.numberOfEstimatedReports',
                    )}
                  />
                </FormControl>
                <FormDescription className="ext-sm font-medium tracking-tight ml-1">
                  {t(
                    'dashboard.landingSingUp.singUpThirdStep.estimatedMonthlyReports',
                  )}
                </FormDescription>
                <FormMessage data-name="backgroundChecksNumber" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="comment"
            render={({ field }) => (
              <FormItem className="w-full  bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Textarea
                    {...field}
                    placeholder={t(
                      'dashboard.landingSingUp.singUpThirdStep.whyAreYouInterested',
                    )}
                  />
                </FormControl>
                <FormMessage data-name="comment" />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
            isLoading={isLoading}
          >
            {t('dashboard.landingSingUp.singUpThirdStep.continue')}
          </Button>
          {!isLoading && (
            <Button
              onClick={Skip}
              variant="outline"
              type="submit"
              className="py-3 px-5 font-medium tracking-widest h-11"
            >
              {t('dashboard.landingSingUp.singUpThirdStep.skip')}
            </Button>
          )}
        </form>
      </Form>
    </div>
  )
}
