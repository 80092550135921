import { ArrowRight } from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { AppRoutes } from '@/constants/appRoutes'

import type { FaceSearchResultsTypes } from './FaceSearchResults.types'

export const FaceSearchResult = ({
  data,
  searchedImage,
  onNewSearch,
}: FaceSearchResultsTypes) => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  return (
    <div>
      <Card className="p-6 basis-1/2 gap-6 w-full max-lg:max-w-md max-xl:max-w-3xl overflow-auto  mb-6">
        <div className="flex flex-col lg:flex-row justify-between items-center ">
          <div className="flex content-center items-center gap-4">
            <Avatar>
              <AvatarImage src={searchedImage} />
            </Avatar>
            <p className="text-textNormal text-base font-bold">
              {t('dashboard.facialMatching.faceAnalyzed')}
            </p>
          </div>

          <div className="flex flex-row gap-3 mt-4 lg:mt-0">
            <Button
              variant="secondary"
              className="px-5 py-5"
              onClick={() => navigation(AppRoutes.reports)}
            >
              {t('dashboard.facialMatching.goToReports')}
            </Button>
            <Button className="px-5 py-5" onClick={onNewSearch}>
              {t('dashboard.facialMatching.newSearch')}
            </Button>
          </div>
        </div>
      </Card>

      <div className="flex flex-col lg:flex-row lg:flex-wrap justify-between gap-8">
        {data.map((item) => (
          <div className="flex lg:w-[31%] flex-col gap-3 p-4 bg-baseColorHighlight rounded-lg">
            <div className="flex flex-col gap-3 justify-between">
              <div className="flex flex-row justify-between">
                <p className="text-textAccent text-base  tracking-tight">
                  {t('dashboard.facialMatching.matchingLevel')}
                </p>
                <p className="text-textAccent text-base font-bold tracking-wide">
                  {item.score}%
                </p>
              </div>
              <div>
                <img
                  className="rounded-lg object-cover w-full"
                  src={item.base64}
                  alt={item.guid}
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <div
                  onClick={() => {
                    window.open(item.url, '_blank')
                  }}
                  className="text-dynamicBlue text-sm font-medium truncate max-w-48 2xl:max-w-72"
                  role="button"
                  tabIndex={0}
                >
                  {item.url}
                </div>

                <Button
                  onClick={() => window.open(`${item.url}`, '_blank')}
                  size={'icon'}
                  className="rounded-full justify-center items-center"
                >
                  <ArrowRight />
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
