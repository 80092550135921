import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

import type { newSearchGuardsTokensSliceState } from './NewSeachGuardToken.slice'
import type { NewSearchSliceState } from './NewSearch.slice'

export const selectNewSearch = (state: RootState) => state.newSearch
export const selectNewSearchGuardToken = (state: RootState) =>
  state.newSearchGuardToken

export const selectNewSearchFirstStep = createSelector(
  selectNewSearch,
  (newSearchState: NewSearchSliceState) => newSearchState.firstStep.topics,
)

export const selectNewSearchFourStep = createSelector(
  selectNewSearch,
  (newSearchState: NewSearchSliceState) => newSearchState,
)

export const selectNewSearchGuardsTokens = createSelector(
  selectNewSearchGuardToken,
  (newSearchState: newSearchGuardsTokensSliceState) => newSearchState,
)

export const selectHasSocialMediaData = createSelector(
  selectNewSearch,
  (newSearchState: NewSearchSliceState) =>
    Object.values(newSearchState.socialMedia).some((url) => url !== ''),
)

export const selectSubjectInfo = createSelector(
  selectNewSearch,
  (newSearchState: NewSearchSliceState) => newSearchState.subjectInfo,
)

export const selectCompanyInfo = createSelector(
  selectNewSearch,
  (newSearchState: NewSearchSliceState) => newSearchState.companyInfo,
)
