import {
  selectItsDefaultState,
  selectPermissions,
} from '@/store/api/Users/Users.selector'

import { useAppSelector } from './redux'

interface RolesPermissions {
  handle_billing: boolean
  handle_enterprises: boolean
  handle_users: boolean
  view_reports: boolean
  handle_reports: boolean
}

export const useDisableBasedOnPermissions = () => {
  const userPermissions = useAppSelector(selectPermissions)
  const itsDefaultState = useAppSelector(selectItsDefaultState)

  const checkPermissions = (permission: keyof RolesPermissions) =>
    userPermissions.includes(permission)

  return {
    handleBilling: checkPermissions('handle_billing') || itsDefaultState,
    handleEnterprises:
      checkPermissions('handle_enterprises') || itsDefaultState,
    handleUsers: checkPermissions('handle_users') || itsDefaultState,
    viewReports: checkPermissions('view_reports') || itsDefaultState,
    handleReports: checkPermissions('handle_reports') || itsDefaultState,
  }
}
