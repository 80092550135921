import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type {
  UpdateUserData,
  UpdateUserPdfConfigRequest,
  UserContactUsRequest,
  UserInfoCrudResponse,
  UserInfoResponse,
} from './Users.types'

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    userInfo: builder.query<UserInfoResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v4/users/me`,
        method: 'GET',
      }),
      transformResponse: (response: UserInfoCrudResponse) => {
        return response.info
      },
      providesTags: [RTKTags.userInfo],
    }),
    userContact: builder.mutation<void, UserContactUsRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/forms/contact-us`,
        method: 'POST',
        body: {
          organizationName: request.organizationName,
          personName: request.personName,
          personEmail: request.personEmail,
          phoneNumber: request.phoneNumber,
          message: request.message,
        },
      }),
    }),

    updateUserData: builder.mutation<void, UpdateUserData>({
      query: (request: UpdateUserData) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/users`,
        method: 'PUT',
        body: {
          phoneNumber: request.phoneNumber,
          firstName: request.firstName,
          lastName: request.lastName,
          language: request.language,
        },
      }),
      invalidatesTags: [RTKTags.userInfo],
    }),
    updateUserPdfConfig: builder.mutation<void, UpdateUserPdfConfigRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/users/pdf-config`,
        method: 'PATCH',
        body: {
          pdfCoreFindings: request.pdfCoreFindings,
          pdfFlaggedBehaviors: request.pdfFlaggedBehaviors,
          pdfFocusedTopics: request.pdfFocusedTopics,
          pdfLanguage: request.pdfLanguage,
          pdfNegativePosts: request.pdfNegativePosts,
          pdfNeutralPosts: request.pdfNeutralPosts,
          pdfPositivePosts: request.pdfPositivePosts,
          pdfSentimentAnalysis: request.pdfSentimentAnalysis,
          pdfSocialMediaAccounts: request.pdfSocialMediaAccounts,
          pdfWebSearchPosts: request.pdfWebSearchPosts,
        },
      }),
      invalidatesTags: [RTKTags.userInfo],
    }),
  }),
})

export const {
  useUserInfoQuery,
  useUserContactMutation,
  useUpdateUserDataMutation,
  useLazyUserInfoQuery,
  useUpdateUserPdfConfigMutation,
} = usersApi
