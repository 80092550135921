import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import DevTag from '@/assets/logo/BetaDistinctionDev.svg?react'
import WeCheckLogoDark from '@/assets/logo/dark.svg?react'
import WeCheckLogoLight from '@/assets/logo/light.svg?react'
import { AppRoutes } from '@/constants/appRoutes'
import env from '@/env/env'
import { useAppSelector } from '@/hooks/redux'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'
import { selectIsDarkMode } from '@/store/api/ui/ui.selector'

import { Button } from '../ui/button'
import { NavigationMenu, NavigationMenuItem } from '../ui/navigation-menu'

interface FormsScreensHeaderTypes {
  login?: boolean
  onlyLogo?: boolean
  signUp?: boolean
}

export const FormsScreensHeader = ({
  login,
  onlyLogo,
  signUp,
}: FormsScreensHeaderTypes) => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()
  const isDarkMode = useAppSelector(selectIsDarkMode)
  const isTablet = useTabletMediaQuery()
  const isHide = false
  const enviroment = env.NODE_ENV

  return (
    <>
      {isMobile || isSmallMobile || isTablet ? (
        <div className="lg:hidden flex flex-1 justify-end items-center mt-6 bg-white">
          <div className="w-full flex justify-between items-center max-w-7xl mx-auto ml-4">
            <Link to={AppRoutes.socialHome}>
              {enviroment === 'production' ? (
                isDarkMode ? (
                  <WeCheckLogoDark />
                ) : (
                  <WeCheckLogoLight />
                )
              ) : (
                <DevTag />
              )}
            </Link>
          </div>
          {!isHide && (
            <div>
              {login && (
                <Link to={AppRoutes.login}>
                  <Button variant="link">{t('dashboard.landing.logIn')}</Button>
                </Link>
              )}
              {signUp && (
                <Link to={AppRoutes.registerStart}>
                  <Button variant="link" className="mr-2">
                    {t('dashboard.landing.signUp')}
                  </Button>
                </Link>
              )}
            </div>
          )}
        </div>
      ) : (
        <NavigationMenu
          className={
            'pl-12 max-w-8xl pb-4 pt-4 bg-transparent absolute  w-full'
          }
        >
          <div className="w-full pl-1 lg:pl-0 xl:pl-10 2xl:pl-20">
            <Link to={AppRoutes.socialHome}>
              {enviroment === 'production' ? (
                isDarkMode ? (
                  <WeCheckLogoDark />
                ) : (
                  <WeCheckLogoLight />
                )
              ) : (
                <DevTag />
              )}
            </Link>
          </div>
          {!isHide && (
            <NavigationMenuItem
              className={`${onlyLogo && 'hidden'} 
           flex space-x-4 
          `}
            >
              {login && (
                <Link to={AppRoutes.login}>
                  <Button variant="default" className="mr-20 max-lg:mr-4">
                    {t('dashboard.landing.logIn')}
                  </Button>
                </Link>
              )}
              {signUp && (
                <Link to={AppRoutes.registerStart}>
                  <Button variant={'default'} className="mr-20 max-lg:mr-8">
                    {t('dashboard.landing.signUp')}
                  </Button>
                </Link>
              )}
            </NavigationMenuItem>
          )}
        </NavigationMenu>
      )}
    </>
  )
}
