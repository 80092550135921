import { api } from '@/store/api'

import Env from '../../../env/env'

import type {
  SetOnboardingRequest,
  SetOnboardingResponse,
} from './SignUp.types'

export const signUpApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setOnboarding: builder.mutation<
      SetOnboardingResponse,
      SetOnboardingRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v3/users/onboarding`,
        method: 'PUT',
        body: request,
      }),
    }),
  }),
})

export const { useSetOnboardingMutation } = signUpApi
