import React from 'react'

import { Chip } from '../../pages/Home/components/Chip/Chip'

interface SheetCardTypes {
  content: string | undefined
}

export const InputCardChip = ({ content }: SheetCardTypes) => {
  return content && <Chip blue text={content} />
}
