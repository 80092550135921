import { zodResolver } from '@hookform/resolvers/zod'
import { Trash } from 'iconsax-react'
import { isEmpty } from 'ramda'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import {
  setFirstStepGuardToken,
  setSubjectInfoStepGuardToken,
} from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import { selectNewSearch } from '@/store/api/NewSearch/NewSearch.selector'
import { resetConsent, setConsent } from '@/store/api/NewSearch/NewSearch.slice'
import { fileToBase64 } from '@/utils/binary'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { consentSchemaType } from '@/utils/schemas'
import { consentSchema } from '@/utils/schemas'

import { GenerateReportHeader } from './Components/GenerateReportHeader'
import { businessLocationData } from './Const/BusinessLocationData'
import { subjectLocationData } from './Const/SubjectLocationData'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const NewSearchConsent = () => {
  useHasCurrentStepToken('consent')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const newSearchState = useSelector(selectNewSearch)

  const consentDefaultValues = newSearchState.consent

  const [selectedSubjectLocation, setSelectedSubjectLocation] =
    useState<string>(consentDefaultValues.subjectLocationName)

  const [selectedBusinessLocation, setSelectedBusinessLocation] =
    useState<string>(consentDefaultValues.businessLocationName)

  const handleSubjectLocationChange = (value: string) => {
    setSelectedSubjectLocation(value)
  }

  const handleBusinessLocationChange = (value: string) => {
    setSelectedBusinessLocation(value)
  }

  const form = useForm<consentSchemaType>({
    resolver: zodResolver(consentSchema),
    defaultValues: {
      email: consentDefaultValues.consentEmail,
    },
    mode: 'onChange',
  })

  const { handleSubmit, control, formState } = form

  const { errors } = formState

  const isEmailValid = form.watch('email')?.trim().length > 0 && !errors.email

  const selectedFile =
    typeof consentDefaultValues.file === 'string' &&
    consentDefaultValues.file.length > 0

  const disabledContinueButton = !(
    (isEmailValid && selectedSubjectLocation && selectedBusinessLocation) ||
    selectedFile
  )

  const onDrop = async (acceptedFiles: any) => {
    if (!acceptedFiles || isEmpty(acceptedFiles)) return

    fileToBase64(acceptedFiles[0], (base64File) => {
      dispatch(
        setConsent({
          file: base64File,
          fileName: acceptedFiles[0].name,
          consentEmail: '',
          subjectLocationValue: '',
          subjectLocationName: '',
        }),
      )
    })
  }

  const onDropRejected = () => {
    toast.error(t('dashboard.newSearch.consent.fileError'))
  }

  const { getInputProps, inputRef } = useDropzone({
    onDrop,
    onDropRejected: onDropRejected,
    disabled: false,
    noClick: true,
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    maxSize: 6000000,
  })

  const onSubmit = (data: any) => {
    const selectedLocation = subjectLocationData.find(
      (location) => location.name === selectedSubjectLocation,
    )

    const selectedBusinessLocationData = businessLocationData.find(
      (location) => location.name === selectedBusinessLocation,
    )

    if (data.email !== '') {
      dispatch(
        setConsent({
          file: null,
          consentEmail: data.email,
          subjectLocationValue: selectedLocation?.value,
          subjectLocationName: selectedLocation?.name,
          businessLocationValue: selectedBusinessLocationData?.value,
          businessLocationName: selectedBusinessLocationData?.name,
        }),
      )
    }
    if (newSearchState.selectSearchType.searchType === 'adverse_media_report') {
      dispatch(
        setSubjectInfoStepGuardToken({ subjectInfoStepGuardToken: true }),
      )
      navigate(AppRoutes.newSearchFormSubjectInfo)
    } else {
      dispatch(setFirstStepGuardToken({ firstStepGuardToken: true }))
      navigate(AppRoutes.newSearchFocusedTopics)
    }
  }

  const onPressLoadFile = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  const textEllipsis = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text
  }

  const deleteFile = () => {
    dispatch(resetConsent())
    if (inputRef && inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <>
      <GenerateReportHeader
        onClickNext={handleSubmit(onSubmit)}
        disabled={disabledContinueButton}
      />
      <WeScreen
        title={''}
        cardTransparent
        cardContent={
          <div className="flex flex-col items-center gap-3 xl:gap-6 h-full">
            <div className="flex flex-col items-center justify-center xl:mt-10">
              <Form {...form}>
                <form className="flex flex-col items-center max-md:gap-6 gap-6">
                  <div className="flex flex-col gap-3">
                    <p className="text-center text-xl font-medium text-neutralColor">
                      {t('dashboard.newSearch.consent.title')}
                    </p>
                    <p className="text-center font-normal text-base text-neutralColor80">
                      {t('dashboard.newSearch.consent.subtitle')}
                    </p>
                    <FormField
                      control={control}
                      name="email"
                      render={({ field }) => (
                        <FormItem className="max-sm:w-[4/5] ">
                          <div className="flex flex-col gap-1.5">
                            <FormLabel className="text-sm font-medium tracking-tight text-neutralColor">
                              {t('dashboard.newSearch.consent.email')}
                            </FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                className="bg-baseColorHighlight"
                                disabled={selectedFile}
                              />
                            </FormControl>
                          </div>

                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Select onValueChange={handleSubjectLocationChange}>
                      <div className="flex flex-col gap-1.5">
                        <FormLabel className="text-sm font-medium tracking-tight text-neutralColor">
                          {t('dashboard.newSearch.consent.subjectLocation')}
                        </FormLabel>
                        <SelectTrigger disabled={!isEmailValid}>
                          {selectedSubjectLocation !== ''
                            ? t(selectedSubjectLocation)
                            : t('dashboard.newSearch.consent.subjectLocation')}
                        </SelectTrigger>
                      </div>

                      <SelectContent>
                        <SelectGroup>
                          {subjectLocationData?.map((location, index) => (
                            <SelectItem key={index} value={location.name}>
                              {t(location.name)}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <Select onValueChange={handleBusinessLocationChange}>
                      <div className="flex flex-col gap-1.5">
                        <FormLabel className="text-sm font-medium tracking-tight text-neutralColor">
                          {t('dashboard.newSearch.consent.businessLocation')}
                        </FormLabel>
                        <SelectTrigger disabled={!isEmailValid}>
                          {selectedBusinessLocation !== ''
                            ? t(selectedBusinessLocation)
                            : t('dashboard.newSearch.consent.businessLocation')}
                        </SelectTrigger>
                      </div>

                      <SelectContent>
                        <SelectGroup>
                          {businessLocationData?.map((location, index) => (
                            <SelectItem key={index} value={location.name}>
                              {t(location.name)}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>

                  <p className="text-center text-neutralColor80 text-base font-normal">
                    {t('dashboard.newSearch.consent.or')}
                  </p>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="gap-3 flex flex-col items-center">
                      <p className="text-center text-neutralColor text-xl font-medium">
                        {t('dashboard.newSearch.consent.upload')}
                      </p>
                      <p className="text-center text-neutralColor80 text-base font-normal">
                        {t('dashboard.newSearch.consent.consentForm')}
                      </p>
                    </div>

                    <Button
                      onClick={onPressLoadFile}
                      asChild
                      size={'lg'}
                      disabled={isEmailValid}
                      className="mt-4"
                      type="button"
                      variant={'outline'}
                    >
                      <div className="flex px-4">
                        <p className="text-base">
                          {t('dashboard.newSearch.consent.chooseFile')}
                        </p>
                      </div>
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
            <div className="flex flex-1 flex-col justify-center items-center">
              {consentDefaultValues.fileName && (
                <div className="flex flex-row gap-4 items-center">
                  <p>{textEllipsis(consentDefaultValues.fileName, 30)}</p>
                  <Button onClick={deleteFile} size={'icon'} variant={'ghost'}>
                    <Trash
                      color={weCheckPalette.dynamicRed}
                      size={22}
                      variant={'Bold'}
                    />
                  </Button>
                </div>
              )}
            </div>

            <p className="text-center text-neutralColor60 text-xs font-medium w-3/5 max-md:w-full">
              {t('dashboard.newSearch.consent.wecheckDisclaimer')}
            </p>
          </div>
        }
      />
      <input {...getInputProps()} />
    </>
  )
}
