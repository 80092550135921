import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { WeScreenV2 } from '@/components/WeScreen/WeScreenV2'
import { AppRoutes } from '@/constants/appRoutes'
import {
  setConsentToken,
  setFirstStepGuardToken,
  setSubjectInfoStepGuardToken,
  setCompanyInfoStepGuardToken,
} from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import { useListOfUseCasesQuery } from '@/store/api/NewSearch/NewSearch.query'
import { selectNewSearch } from '@/store/api/NewSearch/NewSearch.selector'
import {
  clearNewSearch,
  setSearchType,
  setUseCases,
} from '@/store/api/NewSearch/NewSearch.slice'
import { selectUserInfo } from '@/store/api/Users/Users.selector'

import { ReportTypeSection } from './Components/ReportTypeSection'
import { UseCaseSection } from './Components/UseCaseSection'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const NewSearchInitialReportSetup = () => {
  useHasCurrentStepToken('initialReportSetup')
  const { state } = useLocation()
  const clearForm = state?.clearForm ?? false

  useEffect(() => {
    if (clearForm) {
      dispatch(clearNewSearch())
    }
  }, [])

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    i18n: { language },
  } = useTranslation()

  const useCasesDefaultValues = useSelector(selectNewSearch).useCases
  const defaultSearchType =
    useSelector(selectNewSearch).selectSearchType.searchType
  const defaultConfigReport = useSelector(selectUserInfo).enterprise?.credits
  const [selectedUseCaseId, setSelectedUseCaseId] = useState<string>(
    useCasesDefaultValues.selectedUseCaseId,
  )
  const [selectedUseCaseName, setSelectedUseCaseName] = useState<
    string | undefined
  >(useCasesDefaultValues.selectedUseCaseName)
  const [consentSwitchValue, setConsentSwitchValue] = useState<boolean>(
    useCasesDefaultValues.consentSwitchValue,
  )
  const [isForCompanySearch, setIsForCompanySearch] = useState<boolean>(
    useCasesDefaultValues.isForCompanySearch,
  )
  const [isSwitchDisabled, setIsSwitchDisabled] = useState<boolean>(
    useCasesDefaultValues.isSwitchDisabled,
  )
  const [selectedCard, setSelectedCard] = useState<string>(defaultSearchType)

  const { data, isLoading, isError } = useListOfUseCasesQuery()

  const handleUseCaseChange = (useCase: string) => {
    const selected = data?.info.find((item) => item._id === useCase)

    if (selected) {
      const selectedName = selected?.translations.find(
        (t) => t.language === language,
      )
      setSelectedUseCaseName(selectedName?.name)
      setSelectedUseCaseId(selected._id)
      setIsForCompanySearch(selected.isForCompanySearch)

      if (selected.isForCompanySearch) {
        setConsentSwitchValue(false)
        setIsSwitchDisabled(true)
      } else {
        setConsentSwitchValue(selected.requiredComplianceForm)
        setIsSwitchDisabled(selected.requiredComplianceForm)
      }
    }
  }

  const handleSwitchChange = (value: boolean) => {
    if (!isSwitchDisabled) {
      setConsentSwitchValue(value)
    }
  }

  const handleCardSelect = (cardId: string) => {
    setSelectedCard(cardId)
  }

  const handleOnclick = () => {
    dispatch(
      setUseCases({
        selectedUseCaseId: selectedUseCaseId,
        selectedUseCaseName: selectedUseCaseName,
        consentSwitchValue: consentSwitchValue,
        isSwitchDisabled: isSwitchDisabled,
        isForCompanySearch: isForCompanySearch,
      }),
    )
    dispatch(
      setSearchType({
        searchType: selectedCard,
      }),
    )

    if (consentSwitchValue) {
      dispatch(setConsentToken({ setConsentToken: true }))
      navigate(AppRoutes.newSearchConsent)
    } else if (
      selectedCard === 'ai_analyst_report' ||
      selectedCard === 'assisted_report'
    ) {
      dispatch(setFirstStepGuardToken({ firstStepGuardToken: true }))
      navigate(AppRoutes.newSearchFocusedTopics, {
        state: { useDefaultValues: true },
      })
    } else if (isForCompanySearch) {
      dispatch(
        setCompanyInfoStepGuardToken({ companyInfoStepGuardToken: true }),
      )
      navigate(AppRoutes.newSearchCompanyInfo)
    } else {
      dispatch(
        setSubjectInfoStepGuardToken({ subjectInfoStepGuardToken: true }),
      )
      navigate(AppRoutes.newSearchFormSubjectInfo)
    }
  }

  return (
    <WeScreenV2
      title={''}
      isLoading={isLoading}
      isError={isError}
      isDisabledNextButton={
        (selectedUseCaseId || selectedUseCaseName) === '' || selectedCard === ''
      }
      onClickNextButton={handleOnclick}
    >
      <div className="flex flex-col items-center gap-14 justify-center max-md:h-full max-md:gap-2 h-[85vh] p-6">
        <UseCaseSection
          data={data}
          language={language}
          handleUseCaseChange={handleUseCaseChange}
          selectedUseCaseName={selectedUseCaseName}
          handleSwitchChange={handleSwitchChange}
          consentSwitchValue={consentSwitchValue}
          isSwitchDisabled={isSwitchDisabled}
        />
        <ReportTypeSection
          selectedCard={selectedCard}
          handleCardSelect={handleCardSelect}
          defaultConfigReport={defaultConfigReport}
        />
      </div>
    </WeScreenV2>
  )
}
