export enum TermsAndConditions {
  TERMS_AND_CONDITIONS = 'https://wecheck-static-files.s3.amazonaws.com/static/wecheckSocial/WeCheck+-+Terms+of+Service+20-08.pdf',
}

export enum PrivacyPolicy {
  PRIVACY_POLICY = 'https://static-files.wecheck.ai/static/wecheckSocial/PrivacyPolicy-06_24.pdf',
}

export enum CCPANotice {
  CCPA_NOTICE = 'https://static-files.wecheck.ai/static/wecheckSocial/CCPA-Notice.pdf',
}

export enum TermsAndConditionsSpanish {
  TERMS_AND_CONDITIONS_SPANISH = 'https://wecheck-static-files.s3.amazonaws.com/static/wecheckSocial/WeCheck+-+Te%CC%81rminos+y+Condiciones+20-08.pdf',
}

export enum PrivacyPolicySpanish {
  PRIVACY_POLICY_SPANISH = 'https://static-files.wecheck.ai/static/wecheckSocial/WeCheck-PP-Esp-07-17.pdf',
}
