import Lottie from 'lottie-react'

import LoaderLottie from '../../assets/Loader.json'

export const LottieSpinner = ({
  width = 70,
  height = 70,
  text,
}: {
  width?: number
  height?: number
  text?: string
}) => {
  return (
    <div>
      <Lottie animationData={LoaderLottie} loop width={width} height={height} />
      <p className="text-base font-medium  text-center neutral-500 text-textAccent tracking-wider pb-6">
        {text}
      </p>
    </div>
  )
}
