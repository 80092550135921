export enum Events {
  // ONBOARDING EVENTS
  ONBOARDING_START = 'EV_Onboarding_Start',
  ONBOARDING_END = 'EV_Onboarding_End',
  HOME_VIEW = 'EV_Home_View',
  CRYPTO_VIEW = 'EV_Crypto_View',
  REPORTS_VIEW = 'EV_Reports_View',
  REPORTS_VIEW_POST_CATEGORY = 'EV_Reports_View_Post_Category',
  REPORTS_VIEW_EXTERNAL_POST = 'EV_Reports_View_External_Post',
  REPORT_NEW = 'FX_Report_New',
}
