import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

import type { ConfigState } from './Config.slice'

export const selectConfig = (state: RootState) => state.config
export const selectState = (state: RootState) => state

export const selectInMaintenance = createSelector(
  selectConfig,
  (config: ConfigState) => config.inMaintenance,
)

export const selectForceUpdateVersion = createSelector(
  selectConfig,
  (config: ConfigState) => config.forceUpdate,
)
