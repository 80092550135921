import React from 'react'
import { useTranslation } from 'react-i18next'

interface SocialMetricsProps {
  following: number
  followers: number
  posts: number
  hiddenFollowSection?: boolean
}

export const SocialMetrics = ({
  following,
  followers,
  posts,
  hiddenFollowSection,
}: SocialMetricsProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row gap-5">
      <div className="flex gap-1 max-md:flex-col max-md:items-center">
        <p className="text-textNormal text-base font-bold tracking-tight">
          {followers}
        </p>
        <p className="text-textAccent text-base font-normal tracking-tight">
          {t('dashboard.header.followers')}
        </p>
      </div>
      <div className="flex gap-1 max-md:flex-col max-md:items-center">
        <p className="text-textNormal text-base font-bold tracking-tight">
          {following}
        </p>
        <p className="text-textAccent text-base font-normal tracking-tight">
          {t('dashboard.header.following')}
        </p>
      </div>
      <div className="flex gap-1 max-md:flex-col max-md:items-center">
        <p className="text-textNormal text-base font-bold tracking-tight">
          {posts}
        </p>
        <p className="text-textAccent text-base font-normal tracking-tight">
          {t('dashboard.header.posts')}
        </p>
      </div>
    </div>
  )
}
