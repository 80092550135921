import React from 'react'

import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'

import type { FeedBackScreenTypes } from './FeedBackScreen.types'

export const FeedBackScreen = ({
  title,
  image,
  message,
  buttontext,
  onClickButton,
  cardStyles,
}: FeedBackScreenTypes) => {
  return (
    <div>
      <Card
        className={`flex justify-center items-center mt-6 pt-44 pb-44 ${cardStyles}`}
      >
        <div className="flex flex-col gap-6">
          <div>
            <h1 className="text-center text-textNormal text-[32px] font-light tracking-wide">
              {title}
            </h1>
          </div>
          <div className="flex justify-center">
            <img src={image} alt="homeImage" width={343} height={240} />
          </div>
          <div>
            <p className="text-center text-textAccent text-base ">{message}</p>
          </div>
          <div className="flex justify-center">
            {buttontext && (
              <Button
                onClick={onClickButton}
                className="h-[46px] px-5 py-3 bg-neutral-800 rounded-md "
              >
                <p className="text-neutral-50 text-base font-medium tracking-widest">
                  {buttontext}
                </p>
              </Button>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}
