import { ArrowLeft2 } from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface GenerateReportHeaderProps {
  customButton?: React.ReactNode
  disabled?: boolean
  useContinueButton?: boolean
  onClickNext?: () => void
  isLoading?: boolean
  title?: string
  backButton?: boolean
  form?: string
}

export const GenerateReportHeader: React.FC<GenerateReportHeaderProps> = ({
  customButton,
  onClickNext,
  disabled,
  useContinueButton = true,
  isLoading,
  title,
  backButton = true,
  form,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className="flex justify-between bg-transparent items-center p-4">
      {backButton && (
        <Button asChild size={'icon'} variant={'ghost'} onClick={goBack}>
          <div>
            <ArrowLeft2 color={weCheckPalette.neutralColor} />
          </div>
        </Button>
      )}

      {title && (
        <div key={`title-${title}`} className="pl-2">
          <h1 className="text-xl font-medium text-neutralColor">{title}</h1>
        </div>
      )}

      <div className="flex flex-row gap-4">
        {customButton && customButton}
        {useContinueButton && (
          <Button
            variant="default"
            type={onClickNext ? 'button' : 'submit'}
            role="button"
            disabled={disabled}
            isLoading={isLoading}
            form={form}
            onClick={onClickNext}
          >
            <p className="text-base font-medium tracking-wide">
              {t('dashboard.newSearch.common.continue')}
            </p>
          </Button>
        )}
      </div>
    </div>
  )
}
