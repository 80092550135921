import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogFooter,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { AppRoutes } from '@/constants/appRoutes'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'

export const GetStartedCard = ({
  title,
  text,
  desktopImage,
  mobileImage,
  aiReportCredits = 0,
  aiAnalystReportCredits = 0,
  adverseMediaReportCredits = 0,
  assistedReportCredits = 0,
  facialMatchingCredits = 0,
  onClick,
}: {
  title: string
  text: string
  desktopImage: any
  mobileImage: any
  aiReportCredits?: number
  aiAnalystReportCredits?: number
  adverseMediaReportCredits?: number
  assistedReportCredits?: number
  facialMatchingCredits?: number

  onClick: any
}) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const isSmallMobile = useSmallMobileMediaQuery()
  const isMobile = useMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const { t } = useTranslation()

  return (
    <Card className="p-4 bg-baseColorHighlight border-0 shadow-none flex flex-col flex-1">
      <img
        src={isSmallMobile || isMobile || isTablet ? mobileImage : desktopImage}
        alt="SocialaAnalysisImage"
        className="mb-4 lg:h-64 lg:object-cover rounded-lg"
        loading="lazy"
      />
      <div className=" min-h-32">
        <p className="text-neutralColor text-[32px] font-medium mb-1">
          {title}
        </p>
        <p className="text-neutralColor80 text-base font-normal">{text}</p>
      </div>
      <div className="flex flex-row gap-5 justify-end items-end mt-3">
        {aiReportCredits > 0 ||
        aiAnalystReportCredits > 0 ||
        adverseMediaReportCredits > 0 ||
        assistedReportCredits > 0 ||
        facialMatchingCredits > 0 ? (
          <Button onClick={onClick} variant={'default'} className="px-5 py-5">
            {t('dashboard.home.runSearch')}
          </Button>
        ) : (
          <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
              <Button variant={'default'} className="px-5 py-5">
                {t('dashboard.home.runSearch')}
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="flex flex-col w-6/12 max-md:w-11/12">
              <AlertDialogHeader>
                <AlertDialogTitle className="text-textNormal text-[32px] font-medium tracking-wide max-md:text-left max-md:text-[30px]">
                  {t('dashboard.home.insufficientCredits')}
                </AlertDialogTitle>
                <AlertDialogDescription className="text-textNormal text-base font-medium tracking-tight max-md:text-left">
                  {t('dashboard.home.insufficientCreditsText')}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <Button
                  type="submit"
                  variant="secondary"
                  onClick={() => setOpen(false)}
                >
                  {t('dashboard.home.cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="default"
                  className="ml-4"
                  onClick={() => navigate(AppRoutes.contactUs)}
                >
                  {t('dashboard.home.contactSales')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </div>
    </Card>
  )
}
