import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'

import { Enterprise } from './components/Enterprise/Enterprise'
import { Profile } from './components/Profile/Profile'
import { ReportSettings } from './components/ReportSettings/ReportSettings'

export const Settings = () => {
  const { data } = useUserInfoQuery()
  const { t } = useTranslation()

  return (
    <div className="h-full pb-12 lg:pb-0 ">
      <Card>
        <Tabs defaultValue="account" className="rounded-2xl p-6">
          <TabsList className="flex lg:flex-row flex-col gap-2 lg:gap-0 items-start justify-start bg-transparent p-0 mb-5">
            <TabsTrigger
              value="account"
              className="px-4 py-3 mr-5 text-sm font-medium"
            >
              {t('dashboard.account.account')}
            </TabsTrigger>
            {data?.permissions.map(
              (item) =>
                item === 'handle_enterprises' && (
                  <TabsTrigger
                    value="password"
                    className="px-4 py-3 mr-5 text-sm font-medium"
                  >
                    {t('dashboard.account.company')}
                  </TabsTrigger>
                ),
            )}
            {data?.role !== 'view_user' && (
              <TabsTrigger
                value="reportSettings"
                className="px-4 py-3 mr-5 text-sm font-medium"
              >
                {t('dashboard.account.reportSettingsTitle')}
              </TabsTrigger>
            )}
          </TabsList>
          <TabsContent value="account">
            <div className="mb-6">
              <Profile />
            </div>
          </TabsContent>
          <TabsContent value="password">
            <div className="mb-6">
              <Enterprise />
            </div>
          </TabsContent>
          <TabsContent value="reportSettings">
            <div className="mb-6">
              <ReportSettings />
            </div>
          </TabsContent>
        </Tabs>
      </Card>
    </div>
  )
}
