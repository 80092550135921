import { useTranslation } from 'react-i18next'
export const ForgotPaswordCheckYourInbox = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-8 w-full max-w-[436px]">
      <div className="flex flex-col gap-5">
        <p className="text-[40px] font-bold tracking-wide">
          {t('dashboard.landingForgotPassword.checkYourInbox')}
        </p>
        <p className="text-1xl text-textAccent tracking-tight">
          {t('dashboard.landingForgotPassword.checkYourInboxText')}
        </p>
      </div>
    </div>
  )
}
