import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import emptyProfilePhoto from '@/assets/reportInfo/emptyProfilePhoto.png'
import { BackHeader } from '@/components/BackHeader/BackHeader'
import { Card } from '@/components/ui/card'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import { useRudderStack } from '@/hooks/useRudderStack'
import useScrollRestoration from '@/hooks/useScrollRestoration'
import { useGetSubjectBasicInformationQuery } from '@/store/api/Person/Person.query'
import { selectUserInfo } from '@/store/api/Users/Users.selector'

import { CardWithButton } from './Components/CardWithButton'
import { CoreFindingsSection } from './Components/CoreFindingsSection'
import { Header } from './Components/Header'
import { SocialMediaButtons } from './Components/SocialMediaButtons'

export const ReportGeneralInfo = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()
  const reportId = state
  const { data, isLoading, isError } = useGetSubjectBasicInformationQuery({
    id: reportId,
  })
  const userInfo = useSelector(selectUserInfo)
  const { trackEvent } = useRudderStack()

  useScrollRestoration()

  useEffect(() => {
    trackEvent(Events.REPORTS_VIEW, userInfo.userId, {
      email: userInfo.email,
    })
  }, [])

  return (
    <WeScreen
      isError={isError}
      isLoading={isLoading}
      title={''}
      cardTransparent
      content={
        <div className="flex flex-col gap-6">
          <BackHeader
            backText={''}
            onPressBack={() => navigate(AppRoutes.reports)}
          />
          <Card className="flex flex-col gap-8 p-4 bg-baseColor">
            <Header
              subjectInputsSheet
              fullName={data?.info.fullName ? data?.info.fullName : 'N/A'}
              followers={data?.info.followers}
              following={data?.info.following}
              posts={data?.info.posts}
              profileImage={
                data?.info.profileImgUrl
                  ? data?.info.profileImgUrl
                  : emptyProfilePhoto
              }
              inputs={data?.info.inputs}
            />
            <div className="flex gap-4 flex-col">
              <div className="flex gap-3 flex-col">
                <p className="text-neutralColor text-xl font-medium tracking-wide">
                  {t('dashboard.header.socialMediaProfiles')}
                </p>
                <p className="text-neutralColor text-base font-normal tracking-tight">
                  {t('dashboard.header.socialMediaProfilesText')}
                </p>
              </div>
              <SocialMediaButtons sources={data?.info.sources} id={reportId} />
            </div>
          </Card>
          <CardWithButton
            topText={t('dashboard.reportGeneralInfo.postsAnalysis')}
            bottomText={t('dashboard.reportGeneralInfo.postsAnalysisText')}
            onClick={() => navigate(AppRoutes.postsInsights, { state })}
            bgColor="bg-baseColorHighlight"
            button
          />
          <CoreFindingsSection Summary={data?.info.summary} />
        </div>
      }
    />
  )
}
