import React from 'react'
import { useTranslation } from 'react-i18next'

import type { NewSearchSliceState } from '@/store/api/NewSearch/NewSearch.slice'

import { InputCardChip } from '../../../components/InputCard/InputCardChip'

interface InputsSectionTypes {
  searchInfo: NewSearchSliceState
}

export const InputsSection = ({ searchInfo }: InputsSectionTypes) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-wrap gap-4 justify-center items-center">
      <InputCardChip content={searchInfo?.useCases.selectedUseCaseName} />
      <InputCardChip content={searchInfo?.consent.fileName} />
      <InputCardChip content={searchInfo?.consent.consentEmail} />
      <InputCardChip content={t(searchInfo?.consent.subjectLocationName)} />
      <InputCardChip content={t(searchInfo?.consent.businessLocationName)} />
      <InputCardChip
        content={searchInfo?.selectSearchType.searchType
          .toLowerCase()
          .replace(/_/g, ' ')
          .replace(/\b\w/g, (char) => char.toUpperCase())}
      />
      <InputCardChip
        content={
          searchInfo?.subjectInfo.firstName &&
          `${searchInfo?.subjectInfo.firstName} ${searchInfo.subjectInfo.middleName} ${searchInfo.subjectInfo.lastName}`
        }
      />
      <InputCardChip
        content={
          searchInfo?.subjectInfo.countryLongName &&
          `
          ${
            searchInfo?.subjectInfo.cityLongName
              ? searchInfo.subjectInfo.cityLongName
              : ''
          }
          ${
            searchInfo?.subjectInfo.cityLongName &&
            searchInfo?.subjectInfo.stateLongName
              ? ', '
              : ''
          }
          ${
            searchInfo?.subjectInfo.stateLongName
              ? searchInfo.subjectInfo.stateLongName
              : ''
          }
          ${
            searchInfo?.subjectInfo.cityLongName &&
            searchInfo?.subjectInfo.stateLongName &&
            searchInfo?.subjectInfo.countryLongName
              ? ', '
              : ''
          }
          ${
            searchInfo?.subjectInfo.countryLongName
              ? searchInfo.subjectInfo.countryLongName
              : ''
          }
        `
        }
      />
      <InputCardChip
        content={
          searchInfo?.companyInfo.countryLongName &&
          `
          ${
            searchInfo?.companyInfo.cityLongName
              ? searchInfo.companyInfo.cityLongName
              : ''
          }
          ${
            searchInfo?.companyInfo.cityLongName &&
            searchInfo?.companyInfo.stateLongName
              ? ', '
              : ''
          }
          ${
            searchInfo?.companyInfo.stateLongName
              ? searchInfo.companyInfo.stateLongName
              : ''
          }
          ${
            searchInfo?.companyInfo.cityLongName &&
            searchInfo?.companyInfo.stateLongName &&
            searchInfo?.companyInfo.countryLongName
              ? ', '
              : ''
          }
          ${
            searchInfo?.companyInfo.countryLongName
              ? searchInfo.companyInfo.countryLongName
              : ''
          }
        `
        }
      />
      <InputCardChip content={searchInfo?.companyInfo.industry} />
      <InputCardChip content={searchInfo?.companyInfo.website} />

      <InputCardChip content={searchInfo?.subjectInfo.dob} />
      <InputCardChip content={searchInfo?.subjectInfo.companyName} />
      <InputCardChip content={searchInfo?.subjectInfo.collage} />
      <InputCardChip content={searchInfo?.subjectInfo.phoneNumber} />

      <InputCardChip content={searchInfo?.subjectInfo.alias} />
      <InputCardChip content={searchInfo?.socialMedia.facebook} />
      <InputCardChip content={searchInfo?.socialMedia.instagram} />
      <InputCardChip content={searchInfo?.socialMedia.linkedIn} />
      <InputCardChip content={searchInfo?.socialMedia.tikTok} />
      <InputCardChip content={searchInfo?.socialMedia.x} />
    </div>
  )
}
