export const weCheckPalette = {
  baseColor: 'var(--baseColor)',
  baseColorHighlight: 'var(--baseColorHighlight)',
  primary: 'var(--primary0)',
  primary5: 'var(--primary5)',
  primary30: 'var(--primary30)',
  primary60: 'var(--primary60)',
  neutralColor: 'var(--neutral)',
  neutralColor80: 'var(--neutral80)',
  neutralColor60: 'var(--neutral60)',
  neutralColor30: 'var(--neutral30)',

  text: 'var(--neutral)',
  textAccent: 'var(--neutral60)',
  disabled: 'var(--neutral60)',
  icon: 'var(--neutral)',
  appGradient: {
    start: 'var(--gradientStart)',
    end: 'var(--gradientEnd)',
  },

  staticWhite: 'var(--staticWhite)',
  staticBlack: 'var(--staticBlack)',

  dynamicYellow: 'var(--dynamicYellow)',
  alertYellowBase: '#fffdcb',
  alertYellowSecondary: '#dfb014',

  dynamicBlue: 'var(--dynamicBlue)',
  alertBlueBase: '#c6f5ff',
  alertBlueSecondary: '#037797',

  dynamicGreen: 'var(--dynamicGreen)',
  alertGreenBase: '#C8FFD4',
  alertGreenSecondary: '#1E924D',

  dynamicRed: 'var(--dynamicRed)',
  alertRedBase: '#FFCCCC',
  alertRedSecondary: '#853030',
}
