import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { AppRoutes } from '@/constants/appRoutes'

export const useCheckTokenGuard = (token: string | null) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (token === null || token === '') {
      setTimeout(() => toast.warning(t('feedback.toast.invalidToken')), 500)
      navigate(AppRoutes.login)
    }
  }, [token])
}
