import React from 'react'
import { useTranslation } from 'react-i18next'

import type { Credits } from '@/store/api/Users/Users.types'

import { SearchTypeCard } from './SearchTypeCard'

interface ReportTypeSectionTypes {
  selectedCard: string
  handleCardSelect: any
  defaultConfigReport: Credits | undefined
}

export const ReportTypeSection = ({
  selectedCard,
  handleCardSelect,
  defaultConfigReport,
}: ReportTypeSectionTypes) => {
  const { t } = useTranslation()
  return (
    <div className="w-full flex flex-col gap-6 items-center justify-center max-md:gap-5  h-min">
      <div className="flex flex-col gap-3 justify-center items-center mt-14">
        <p className="text-center text-neutralColor text-xl font-medium">
          {t('dashboard.newSearch.selectSearchType.weCheckReport')}
        </p>
        <p className="text-center text-neutralColor80 text-base font-normal">
          {t('dashboard.newSearch.selectSearchType.selectTheType')}
        </p>
      </div>
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-6 w-full justify-center items-center max-lg:flex-col max-md:gap-5">
        {/* Adverse Media */}
        <SearchTypeCard
          title={t('dashboard.newSearch.selectSearchType.adverseMedia')}
          text={t('dashboard.newSearch.selectSearchType.adverseMediaText')}
          creditBalance={defaultConfigReport?.adverseMediaReportCredits}
          isSelected={selectedCard === 'adverse_media_report'}
          onSelect={() => handleCardSelect('adverse_media_report')}
          disabled={defaultConfigReport?.adverseMediaReportCredits === 0}
        />
        {/* AI */}
        <SearchTypeCard
          title={t('dashboard.newSearch.selectSearchType.aIReport')}
          text={t('dashboard.newSearch.selectSearchType.aIReportText')}
          creditBalance={defaultConfigReport?.aiReportCredits}
          isSelected={selectedCard === 'ai_report'}
          onSelect={() => handleCardSelect('ai_report')}
          disabled={defaultConfigReport?.aiReportCredits === 0}
        />
        {/* AI + Analyst */}
        <SearchTypeCard
          title={t('dashboard.newSearch.selectSearchType.AI+Analyst')}
          text={t('dashboard.newSearch.selectSearchType.AI+AnalystText')}
          creditBalance={defaultConfigReport?.aiAnalystReportCredits}
          isSelected={selectedCard === 'ai_analyst_report'}
          onSelect={() => handleCardSelect('ai_analyst_report')}
          disabled={defaultConfigReport?.aiAnalystReportCredits === 0}
        />
        {/* Assisted Report */}
        <SearchTypeCard
          title={t('dashboard.newSearch.selectSearchType.assistedReport')}
          text={t('dashboard.newSearch.selectSearchType.assistedReportSubText')}
          creditBalance={defaultConfigReport?.assistedReportCredits}
          isSelected={selectedCard === 'assisted_report'}
          onSelect={() => handleCardSelect('assisted_report')}
          disabled={defaultConfigReport?.assistedReportCredits === 0}
          isAssistedReport
        />
      </div>
    </div>
  )
}
