import WeCheckLogo from '@/assets/sidebar/weCheckLogoProd.svg?react'

import type { ProdDevAssetsTypes } from './WecheckEnviromentDistinction.types'

export const WecheckEnviromentDistinction = ({
  enviroment,
}: ProdDevAssetsTypes) => {
  return (
    <>
      {enviroment !== 'production' ? (
        <div className="flex flex-row">
          <div className="flex gap-2 items-center">
            <WeCheckLogo />
            <p className="text-sm font-medium tracking-tight">
              WeCheck AI <span className="text-dynamicGreen">DEV</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-row">
          <div className="flex gap-2 items-center">
            <WeCheckLogo />
            <p className="text-sm font-medium tracking-tight">WeCheck AI </p>
          </div>
        </div>
      )}
    </>
  )
}
