import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type {
  SearchHistoryCrudResponse,
  SearchHistoryResponse,
  SearchPhotoRequest,
  SearchPhotoResponse,
  UploadFaceResponse,
} from './SearchFace.types'

export const searchFaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadPic: builder.mutation<UploadFaceResponse, any>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/face/upload-pic`,
        method: 'POST',
        body: request,
      }),
      transformResponse: (response: any) => {
        return response.info
      },
    }),
    searchResults: builder.mutation<SearchPhotoResponse, SearchPhotoRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/face/search`,
        method: 'POST',
        body: request,
      }),
      transformResponse: (response: any) => {
        return response.info
      },
    }),
    searchHistoryEnterprise: builder.query<SearchHistoryResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/face-checks`,
        method: 'GET',
      }),
      transformResponse: (response: SearchHistoryCrudResponse) => {
        return response.info.map((element) => {
          return {
            type: 'faceMatch',
            creation_date: element.createdAt,
            data: element,
          }
        })
      },
      keepUnusedDataFor: 0,
    }),
    searchHistoryUser: builder.query<SearchHistoryResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/face/history`,
        method: 'GET',
      }),
      transformResponse: (response: SearchHistoryCrudResponse) => {
        return response.info.map((element) => {
          return {
            type: 'faceMatch',
            creation_date: element.createdAt,
            data: element,
          }
        })
      },
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.historyEnterprice],
    }),
    searchResultById: builder.query<SearchPhotoResponse, SearchPhotoRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/face/face-check/${request.searchId}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.info
      },
    }),
  }),
})

export const {
  useUploadPicMutation,
  useSearchResultsMutation,
  useSearchHistoryEnterpriseQuery,
  useSearchHistoryUserQuery,
  useLazySearchResultByIdQuery,
} = searchFaceApi
