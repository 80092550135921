import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'

import { api } from './api'
import authReducer from './api/Auth/Auth.slice'
import configReducer from './api/Config/Config.slice'
import newSearchGuardTokenReducer from './api/NewSearch/NewSeachGuardToken.slice'
import newSeachReducer from './api/NewSearch/NewSearch.slice'
import signUpReducer from './api/SignUp/SignUp.slice'
import uiReducer from './api/ui/ui.slice'
import userInfoReducer from './api/Users/Users.slice'

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  () => (next) => (action: any) => {
    if (isRejectedWithValue(action)) {
      // is an known api error
      console.log(action)
    }

    return next(action)
  }

// Setup redux-first-history
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() })
export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development' ? true : false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([api.middleware, routerMiddleware]),
  reducer: combineReducers({
    router: routerReducer,
    auth: authReducer,
    signUp: signUpReducer,
    userInfo: userInfoReducer,
    ui: uiReducer,
    newSearch: newSeachReducer,
    newSearchGuardToken: newSearchGuardTokenReducer,
    config: configReducer,
    [api.reducerPath]: api.reducer,
  }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
export const history = createReduxHistory(store)
