export const businessLocationData = [
  {
    name: 'dashboard.newSearch.consent.restOfTheWorld',
    value: 'Rest Of World',
  },
  {
    name: 'New York',
    value: 'New York',
  },
  {
    name: 'California',
    value: 'California',
  },
  {
    name: 'Washington',
    value: 'General',
  },
  {
    name: 'Alabama',
    value: 'General',
  },
  {
    name: 'Alaska',
    value: 'General',
  },
  {
    name: 'Arizona',
    value: 'General',
  },
  {
    name: 'Arkansas',
    value: 'General',
  },

  {
    name: 'Colorado',
    value: 'General',
  },
  {
    name: 'Connecticut',
    value: 'General',
  },
  {
    name: 'Delaware',
    value: 'General',
  },
  {
    name: 'District of Columbia',
    value: 'General',
  },
  {
    name: 'Florida',
    value: 'General',
  },
  {
    name: 'Georgia',
    value: 'General',
  },
  {
    name: 'Hawaii',
    value: 'General',
  },
  {
    name: 'Idaho',
    value: 'General',
  },
  {
    name: 'Illinois',
    value: 'General',
  },
  {
    name: 'Indiana',
    value: 'General',
  },
  {
    name: 'Iowa',
    value: 'General',
  },
  {
    name: 'Kansas',
    value: 'General',
  },
  {
    name: 'Kentucky',
    value: 'General',
  },
  {
    name: 'Louisiana',
    value: 'General',
  },
  {
    name: 'Maine',
    value: 'General',
  },
  {
    name: 'Maryland',
    value: 'General',
  },
  {
    name: 'Massachusetts',
    value: 'General',
  },
  {
    name: 'Michigan',
    value: 'General',
  },
  {
    name: 'Minnesota',
    value: 'General',
  },
  {
    name: 'Mississippi',
    value: 'General',
  },
  {
    name: 'Missouri',
    value: 'General',
  },
  {
    name: 'Montana',
    value: 'General',
  },
  {
    name: 'Nebraska',
    value: 'General',
  },
  {
    name: 'Nevada',
    value: 'General',
  },
  {
    name: 'New Hampshire',
    value: 'General',
  },
  {
    name: 'New Jersey',
    value: 'General',
  },
  {
    name: 'New Mexico',
    value: 'General',
  },

  {
    name: 'North Carolina',
    value: 'General',
  },
  {
    name: 'North Dakota',
    value: 'General',
  },
  {
    name: 'Ohio',
    value: 'General',
  },
  {
    name: 'Oklahoma',
    value: 'General',
  },
  {
    name: 'Oregon',
    value: 'General',
  },
  {
    name: 'Pennsylvania',
    value: 'General',
  },
  {
    name: 'Rhode Island',
    value: 'General',
  },
  {
    name: 'South Carolina',
    value: 'General',
  },
  {
    name: 'South Dakota',
    value: 'General',
  },
  {
    name: 'Tennessee',
    value: 'General',
  },
  {
    name: 'Texas',
    value: 'General',
  },
  {
    name: 'Utah',
    value: 'General',
  },
  {
    name: 'Vermont',
    value: 'General',
  },
  {
    name: 'Virginia',
    value: 'General',
  },

  {
    name: 'West Virginia',
    value: 'General',
  },
  {
    name: 'Wisconsin',
    value: 'General',
  },
  {
    name: 'Wyoming',
    value: 'General',
  },
]
