import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'

import { Error404 } from '@/components/ErrorState/Error404'
import { Card, CardContent } from '@/components/ui/card'

import { EmptyState } from '../EmptyState/EmptyState'
import { LottieSpinner } from '../LottieSpinner/LottieSpinner'

export const WeScreen = ({
  title,
  isLoading = false,
  isError = false,
  emptyStateText,
  emptyStateAlternativeAsset,
  cardContent,
  cardHeader,
  cardTransparent = false,
  customVh = '85',
  content,
}: {
  title: string
  isLoading?: boolean
  isError?: boolean
  emptyStateText?: string
  emptyStateAlternativeAsset?: React.ReactNode | undefined
  cardContent?: React.ReactNode
  cardHeader?: React.ReactNode | undefined
  cardTransparent?: boolean
  customVh?: string
  content?: React.ReactNode
}) => {
  const screenId = title.replace(/\s/g, '')
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-6 w-full" key={`screen-${screenId}`}>
      {title && (
        <div key={`title-${screenId}`} className="pl-2">
          <h1 className="text-2xl font-semibold text-neutralColor">{title}</h1>
        </div>
      )}

      {!isLoading && !isError && content}

      {isError ? (
        <div
          className="flex justify-center items-center w-full h-full"
          key={`error-${screenId}`}
        >
          <Error404
            title={t('dashboard.error404.unexpectedError')}
            text={t('dashboard.error404.weReSorry')}
            absolute={false}
          />
        </div>
      ) : isLoading ? (
        <div
          className="flex justify-center items-center w-full h-full"
          key={`loader-${screenId}`}
        >
          <LottieSpinner
            width={100}
            height={100}
            text={t('dashboard.loading.loading')}
          />
        </div>
      ) : (
        <Card
          key={`content-${screenId}`}
          className={
            cardTransparent
              ? 'bg-transparent shadow-none border-none m-0 p-0'
              : 'bg-baseColorHighlight'
          }
          hidden={!cardContent}
        >
          {!!cardHeader && cardHeader}

          {isEmpty(cardContent) ? (
            <EmptyState
              text={emptyStateText}
              alternativeAsset={emptyStateAlternativeAsset}
            />
          ) : (
            <CardContent
              className={`overflow-y-auto overflow-x-hidden flex flex-col lg:scrollbar p-3 lg:p-6`}
              style={{ maxHeight: `${customVh}vh` }}
            >
              {cardContent}
            </CardContent>
          )}
        </Card>
      )}
    </div>
  )
}
