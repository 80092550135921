import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import SuccessImage from '@/assets/Podcast.png'
import ErrorImage from '@/assets/videoPark.png'
import { AppRoutes } from '@/constants/appRoutes'

import { FeedBackScreen } from '../FeedBackScreen/FeedBackScreen'

export const FeedBackPayment = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const successParam = params.get('success')
  const success = successParam === 'true'

  return (
    <div>
      {success ? (
        <FeedBackScreen
          image={SuccessImage}
          title={t('dashboard.feedBackPayment.transactionSuccessful')}
          message={t('dashboard.feedBackPayment.yourCreditTransaction')}
          buttontext={t('dashboard.feedBackPayment.continue')}
          onClickButton={() => {
            navigate(AppRoutes.dashboard)
          }}
        />
      ) : (
        <FeedBackScreen
          title={t('dashboard.feedBackPayment.somethingWentWrong')}
          image={ErrorImage}
          message={t('dashboard.feedBackPayment.thereWasAnError')}
          buttontext={t('dashboard.feedBackPayment.tryAgain')}
          onClickButton={() => {
            navigate(AppRoutes.getCredits)
          }}
        />
      )}
    </div>
  )
}
