import { createSlice } from '@reduxjs/toolkit'

import type { Enterprise, pdfCustomConfig } from './Users.types'

const defaultPdfCustomConfig: pdfCustomConfig = {
  pdfCoreFindings: false,
  pdfFlaggedBehaviors: false,
  pdfFocusedTopics: false,
  pdfLanguage: 'en',
  pdfNegativePosts: false,
  pdfNeutralPosts: false,
  pdfPositivePosts: false,
  pdfSentimentAnalysis: false,
  pdfSocialMediaAccounts: false,
  pdfWebSearchPosts: false,
}
export interface userSliceState {
  userId: string
  firstname: string
  lastname: string
  email: string
  enterprise: Enterprise | undefined
  phoneNumber: string
  role: string
  permissions: string[]
  enterpriseName: string
  hasMinorSearch: boolean
  credits: number
  status: string
  from: string
  findPeoplePrice: string
  analyzePeoplePrice: string | null
  pdfConfig: pdfCustomConfig
  aiReportCredits: number
  aiAnalystReportCredits: number
  adverseMediaReportCredits: number
  assistedReportCredits: number
  facialMatchingCredits: number
}

export const userInfoSliceInitialState: userSliceState = {
  userId: '',
  firstname: '',
  lastname: '',
  email: '',
  enterprise: undefined,
  phoneNumber: '',
  role: '',
  permissions: [],
  enterpriseName: '',
  credits: 0,
  status: '',
  from: '',
  hasMinorSearch: false,
  findPeoplePrice: '',
  analyzePeoplePrice: null,
  pdfConfig: defaultPdfCustomConfig,
  aiReportCredits: 0,
  aiAnalystReportCredits: 0,
  adverseMediaReportCredits: 0,
  assistedReportCredits: 0,
  facialMatchingCredits: 0,
}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: userInfoSliceInitialState,
  reducers: {
    setUserInfo: (state, action) => {
      const userInfo = action.payload
      state.userId = userInfo.id
      state.firstname = userInfo.firstname
      state.lastname = userInfo.lastname
      state.email = userInfo.email
      state.enterprise = userInfo.enterprise
      state.phoneNumber = userInfo.phoneNumber
      state.role = userInfo.role
      state.permissions = userInfo.permissions
      state.enterpriseName = userInfo.enterprise.name
      state.credits = userInfo.enterprise.credits
      state.status = userInfo.status
      state.hasMinorSearch = userInfo.enterprise.minorSearch
      state.findPeoplePrice = userInfo.enterprise.findPeoplePrice
      state.analyzePeoplePrice = userInfo.enterprise.analyzePeoplePrice
      state.pdfConfig = userInfo.pdfConfig
      state.facialMatchingCredits =
        userInfo.enterprise.credits.facialMatchingCredits
      state.adverseMediaReportCredits =
        userInfo.enterprise.credits.adverseMediaReportCredits
      state.aiAnalystReportCredits =
        userInfo.enterprise.credits.aiAnalystReportCredits
      state.assistedReportCredits =
        userInfo.enterprise.credits.assistedReportCredits
      state.findPeoplePrice = userInfo.enterprise.credits.findPeoplePrice
    },
    setUserOrigin: (state, action) => {
      state.from = action.payload
    },
  },
})
export const { setUserInfo, setUserOrigin } = userInfoSlice.actions

export default userInfoSlice.reducer
