import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { AppRoutes } from '@/constants/appRoutes'
import { useAppSelector } from '@/hooks/redux'
import { selectCredentials } from '@/store/api/SignUp/SignUp.selector'
import { signUpSliceInitialState } from '@/store/api/SignUp/SignUp.slice'

export const useSignUpFinalStepGuard = () => {
  const { t } = useTranslation()
  const signUpInfo = useAppSelector(selectCredentials)
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !signUpInfo ||
      signUpInfo.email === signUpSliceInitialState.userAuth.email
    ) {
      setTimeout(() => toast.warning(t('feedback.toast.pleaseLogin')), 500)

      navigate(AppRoutes.login)
    }
  }, [signUpInfo])
}
