import { useTranslation } from 'react-i18next'

import { Chip } from '@/pages/Home/components/Chip/Chip'

import AvatarPending from '../../../assets/Avatar-pending.svg?react'
interface InviteMembersItemTypes {
  email: string
  role: string
}

export const InviteMembersItem = ({ email, role }: InviteMembersItemTypes) => {
  const { t } = useTranslation()
  return (
    <div className="bg-baseColor rounded-lg flex justify-center lg:justify-between xl:justify-normal p-3 mb-4 items-center max-md:flex-col gap-4 md:h-16 max-md:h-32">
      <div className="flex w-1/3 max-md:w-full">
        <div className="pr-3">
          <AvatarPending />
        </div>
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            <p className="font-medium tracking-wide">{email}</p>
            <Chip text={t('dashboard.team.pending')} blue />
          </div>
          <p className="capitalize text-neutralColor60">
            {(role === 'operational_user' && 'Operational') ||
              (role === 'view_user' && 'Reader') ||
              (role === 'manager' && 'Manager') ||
              role}
          </p>
        </div>
      </div>
    </div>
  )
}
