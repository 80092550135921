import type { Dispatch } from 'react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from '@/components/ui/form'
import type { listPaginatedOfTopicsResponse } from '@/store/api/NewSearch/NewSearch.types'

import { BehaviorDetectionSheet } from './BehaviorDetectionSheet'
import { TopicSwitchCard } from './TopicSwitchCard'

interface SwitchSectionTypes {
  activeSwitchesCount: number
  setActiveSwitchesCount: Dispatch<React.SetStateAction<number>>
  data?: listPaginatedOfTopicsResponse
  form: any
  selectedTopics: string[]
}

export const SwitchSection = ({
  activeSwitchesCount,
  setActiveSwitchesCount,
  data,
  form,
  selectedTopics,
}: SwitchSectionTypes) => {
  const {
    i18n: { language, t },
  } = useTranslation()

  useEffect(() => {
    const activeCount = Object.values(form.getValues()).filter(Boolean).length
    setActiveSwitchesCount(activeCount)
  }, [form.getValues(), setActiveSwitchesCount])

  const shouldDisableSwitches = activeSwitchesCount > 1

  const getTranslation = (topic: any, key: 'name' | 'description') => {
    const translation = topic.translations?.find(
      (t: any) => t.language === language,
    )
    return translation ? translation[key] : topic[key]
  }

  const selectedTopicsSet = new Set(selectedTopics)

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <div className="flex justify-center gap-2">
          <p className="text-neutralColor text-center text-xl font-medium">
            {t('dashboard.newSearch.focusedTopics.title')}
          </p>
          <p className="text-center text-neutralColor60 text-xl font-medium">
            {activeSwitchesCount}/2
          </p>
        </div>

        <p className="text-center text-neutralColor80 text-base font-normal">
          {t('dashboard.newSearch.focusedTopics.subtitle1')}
          <span className="font-semibold">
            {t('dashboard.newSearch.focusedTopics.subtitle2')}
          </span>
          {t('dashboard.newSearch.focusedTopics.subtitle3')}
          <span className="font-semibold">
            {t('dashboard.newSearch.focusedTopics.subtitle4')}
          </span>
          {t('dashboard.newSearch.focusedTopics.subtitle5')}
          <BehaviorDetectionSheet />
        </p>
      </div>

      <Form {...form}>
        <form className="flex gap-6 w-full flex-wrap justify-center">
          {data?.info.list.map((topic) => (
            <TopicSwitchCard
              defaultValue={selectedTopicsSet.has(topic.name)}
              key={topic._id}
              title={getTranslation(topic, 'name')}
              description={getTranslation(topic, 'description')}
              formName={topic.name}
              disabled={shouldDisableSwitches}
            />
          ))}
        </form>
      </Form>
    </div>
  )
}
