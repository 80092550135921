import React from 'react'

interface CoreFindingsCardTypes {
  title: string
  text: string
}

export const CoreFindingsCard = ({ title, text }: CoreFindingsCardTypes) => {
  return (
    <div className="flex p-4 bg-primaryColor5 rounded-xl flex-col justify-start items-start gap-2">
      <p className="text-textNormal text-xl font-medium tracking-wide">
        {title}
      </p>
      <p className="text-textAccent text-base font-normal">{text}</p>
    </div>
  )
}
