import { ReloadIcon } from '@radix-ui/react-icons'

import type { SidebarCreditsTypes } from './SidebarCredits.types'

export const SidebarCredits = ({
  credits,
  isLoading,
  text,
  icon,
}: SidebarCreditsTypes) => {
  return (
    <div
      className={`flex flex-row items-center justify-between rounded-lg bg-transparent gap-1`}
    >
      <p className="text-xs font-medium text-neutralColor60">{text}</p>

      {isLoading && <ReloadIcon className="h-4 w-4 animate-spin" />}
      {!isLoading && (
        <div className="flex gap-1.5 items-center">
          <p className="text-xs font-medium">{credits || 0}</p>
          {icon}
        </div>
      )}
    </div>
  )
}
