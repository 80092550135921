import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

interface ConsentFileItemTypes {
  fileName: string
  onClick: any
  withoutDivider?: boolean
}

export const ConsentFileItem = ({
  fileName,
  onClick,
  withoutDivider = false,
}: ConsentFileItemTypes) => {
  const { t } = useTranslation()
  return (
    <>
      {!withoutDivider && (
        <div className="w-full h-0 border-t border-neutralColor30 my-6" />
      )}
      <div className="flex h-8 items-center gap-2 w-full">
        <p className="w-full">{fileName}</p>
        <Button asChild size={'default'} onClick={onClick} className="w-[50px]">
          <p className="text-xs font-medium text-neutralColor">
            {t('dashboard.reportsScreen.view')}
          </p>
        </Button>
      </div>
    </>
  )
}
