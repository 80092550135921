import { createSlice } from '@reduxjs/toolkit'

export interface uiSliceState {
  collapsed: boolean
  isDarkMode: any
}

export const uiSliceInitialState: uiSliceState = {
  collapsed: false,
  isDarkMode: (() => {
    const storedValue = localStorage.getItem('isDarkMode')
    if (storedValue === null) {
      return true
    }
    return JSON.parse(storedValue)
  })(),
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: uiSliceInitialState,
  reducers: {
    collapseNavBar: (state) => {
      state.collapsed = true
    },
    expandNavBar: (state) => {
      state.collapsed = false
    },
    switchTheme: (state, action) => {
      state.isDarkMode = action.payload
      localStorage.setItem('isDarkMode', JSON.stringify(action.payload))
    },
  },
})
export const { collapseNavBar, expandNavBar, switchTheme } = uiSlice.actions

export default uiSlice.reducer
