import { useState, useEffect } from 'react'
import { load, ready, identify, track } from 'rudder-sdk-js'
import { v4 as uuidv4 } from 'uuid'

import { selectFrom } from '@/store/api/Users/Users.selector'
import type { userSliceState } from '@/store/api/Users/Users.slice'

import Env from '../env/env'

import { useAppSelector } from './redux'

export const useRudderStack = () => {
  const [isRudderStackLoaded, setIsRudderStackLoaded] = useState(false)
  const [userAnnonId, setUserAnnonId] = useState<string | undefined>(undefined)
  const from = useAppSelector(selectFrom)

  useEffect(() => {
    if (!isRudderStackLoaded) {
      load(Env.RUDDERSTACK_WRITE_KEY, Env.RUDDERSTACK_DATA_PLANE_URL)
      ready(() => {
        setIsRudderStackLoaded(true)
      })
    }
  }, [])

  const getAnnonymousUUID = () => {
    if (userAnnonId) return userAnnonId

    const uuid = uuidv4()
    setUserAnnonId(uuid)

    return uuid
  }

  const identifyUser = (user: Partial<userSliceState>) => {
    const {
      userId,
      firstname,
      lastname,
      phoneNumber,
      email,
      role,
      status,
      permissions,
      enterpriseName,
    } = user

    identify(userId || getAnnonymousUUID(), {
      firstName: firstname,
      lastName: lastname,
      phone: phoneNumber,
      email,
      role,
      status,
      permissions,
      enterpriseName,
    })
  }

  const trackEvent = (
    eventName: string,
    userId: string | undefined,
    meta: Record<string, string | undefined>,
  ) => {
    track(eventName, {
      ...meta,
      user_id: userId || getAnnonymousUUID(),
      time_stamp: new Date().toISOString(),
      browser: navigator.userAgent,
      language: navigator.language,
      from: meta.from ?? from,
    })
  }

  return { identifyUser, trackEvent }
}
