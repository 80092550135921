import React from 'react'
import { useTranslation } from 'react-i18next'

import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import type { Inputs } from '@/store/api/Person/Person.types'

import emptyAvatar from '../../../assets/emptyAvatar.png'

import { SocialMetrics } from './SocialMetrics'
import { SubjectInputsSheet } from './SubjectInputsSheet'

export const Header = ({
  children,
  fullName,
  profileImage,
  followers,
  following,
  posts,
  inputs,
  subjectInputsSheet,
  isPrivate,
  hiddenFollowSection,
}: {
  children?: React.JSX.Element
  fullName: string | undefined
  profileImage: string | undefined
  followers: number | undefined
  following: number | undefined
  posts: number | undefined
  inputs?: Inputs | undefined
  subjectInputsSheet?: boolean
  isPrivate?: boolean
  hiddenFollowSection?: boolean
}) => {
  const isMobile = useMobileMediaQuery()
  const { t } = useTranslation()
  return (
    <div className="flex justify-between flex-row items-center max-md:flex-col max-md:gap-5 bg-baseColorHighlight">
      <div className="flex flex-row gap-6 max-md:items-center bg-baseColorHighlight">
        <div
          className={
            'w-[90px] max-h-[90px] bg-baseColorHighlight rounded-full border-4 border-white items-center'
          }
        >
          <img
            src={profileImage || emptyAvatar}
            alt={fullName}
            width={88}
            height={72}
            className="rounded-full"
          />
        </div>
        <div className="flex flex-col gap-3 ">
          <div className="flex flex-col gap-1">
            <p className="text-textAccent text-sm font-medium tracking-wide">
              {t('dashboard.header.analyzedProfile')}
            </p>
            <div className="flex gap-1 lg:gap-2 items-center max-sm:flex-col max-sm:items-start">
              <p className="text-textNormal text-2xl font-medium tracking-wide">
                {fullName}
              </p>
              <p className="text-textAccent text-xl font-medium">
                {isPrivate && t('dashboard.header.private')}
              </p>
              {subjectInputsSheet && (
                <SubjectInputsSheet subjectInputs={inputs} />
              )}
            </div>
          </div>
          {!isMobile && (
            <SocialMetrics
              followers={followers || 0}
              following={following || 0}
              posts={posts || 0}
              hiddenFollowSection={hiddenFollowSection}
            />
          )}
        </div>
      </div>
      {isMobile && (
        <SocialMetrics
          followers={followers || 0}
          following={following || 0}
          posts={posts || 0}
        />
      )}
      {children && <div className="max-md:w-full">{children}</div>}
    </div>
  )
}
