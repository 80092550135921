import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from '@/components/ui/select'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  useUpdateUserPdfConfigMutation,
  useUserInfoQuery,
} from '@/store/api/Users/Users.query'
import type { pDFReportSectionType } from '@/utils/schemas'
import { pDFReportSectionSchema } from '@/utils/schemas'

import { SwitchCard } from './SwitchCard'

export const ReportSettings = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useUserInfoQuery()
  const [language, setLanguage] = useState<string>(
    data?.pdfConfig.pdfLanguage || 'en',
  )

  const form = useForm<pDFReportSectionType>({
    resolver: zodResolver(pDFReportSectionSchema),
    defaultValues: {
      coreFindings: data?.pdfConfig.pdfCoreFindings,
      socialMediaAccounts: data?.pdfConfig.pdfSocialMediaAccounts,
      sentimentAnalysis: data?.pdfConfig.pdfSentimentAnalysis,
      flaggedBehaviors: data?.pdfConfig.pdfFlaggedBehaviors,
      positivePosts: data?.pdfConfig.pdfPositivePosts,
      negativePosts: data?.pdfConfig.pdfNegativePosts,
      neutralPosts: data?.pdfConfig.pdfNeutralPosts,
      webNewsPosts: data?.pdfConfig.pdfWebSearchPosts,
      language: data?.pdfConfig.pdfLanguage,
    },
  })

  const [updateUserPdfConfig, { isLoading: isLoadingUpdateUserPdfConfig }] =
    useUpdateUserPdfConfigMutation()

  const {
    handleSubmit,
    formState: { isDirty },
  } = form

  const handleUpdateUserData = async (formData: pDFReportSectionType) => {
    try {
      await updateUserPdfConfig({
        pdfCoreFindings: formData.coreFindings,
        pdfFlaggedBehaviors: formData.flaggedBehaviors,
        pdfFocusedTopics: false,
        pdfLanguage: language,
        pdfNegativePosts: formData.negativePosts,
        pdfNeutralPosts: formData.neutralPosts,
        pdfPositivePosts: formData.positivePosts,
        pdfSentimentAnalysis: formData.sentimentAnalysis,
        pdfSocialMediaAccounts: formData.socialMediaAccounts,
        pdfWebSearchPosts: formData.webNewsPosts,
      }).unwrap()
      toast.success(t('feedback.toast.pdfUpdated'))
    } catch (error) {
      toast.error(t('feedback.toast.errorSavingChanges'))
      catchWithSentry(error)
    }
  }

  const onSubmit = (formData: pDFReportSectionType) => {
    handleUpdateUserData(formData)
  }

  if (isLoading) {
    return (
      <LottieSpinner
        width={320}
        height={320}
        text={t('feedback.common.loading')}
      />
    )
  }

  const handleLanguageChange = (newLanguage: string) => {
    setLanguage(newLanguage)
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col mb-6">
            <p className="text-neutralColor text-xl font-semibold tracking-wide font-satoshi mb-4">
              {t('dashboard.account.reportSettings.language')}
            </p>
            <p className="text-neutralColor80 text-base font-medium mb-1">
              {t('dashboard.account.reportSettings.reportingLanguage')}
            </p>
            <p className="text-neutralColor80 text-sm font-medium mb-4">
              {t('dashboard.account.reportSettings.reportingLanguageText')}
            </p>
            <div className="flex gap-2">
              <Select onValueChange={handleLanguageChange}>
                <SelectTrigger className="w-full lg:w-2/6">
                  {language === 'es'
                    ? t('dashboard.account.spanish')
                    : t('dashboard.account.english')}
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel className="px-2 py-1.5 text-textNormal text-base font-medium tracking-tight">
                      {t('dashboard.account.selectLanguage')}
                    </SelectLabel>
                    <SelectItem value="es">
                      {t('dashboard.account.spanish')}
                    </SelectItem>
                    <SelectItem value="en">
                      {t('dashboard.account.english')}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div>
            <p className="text-neutralColor text-xl font-semibold tracking-wide font-satoshi mb-4">
              {t('dashboard.account.reportSettings.pDFReportSections')}
            </p>
            <p className="text-neutralColor80 text-xs font-medium">
              {t('dashboard.account.reportSettings.pDFReportSectionsText')}
            </p>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <SwitchCard
              title={t('dashboard.account.reportSettings.coreFindings')}
              description={t(
                'dashboard.account.reportSettings.coreFindingsText',
              )}
              formName="coreFindings"
              defaultValue={data?.pdfConfig.pdfCoreFindings}
            />
            <SwitchCard
              title={t('dashboard.account.reportSettings.socialMediaAccounts')}
              description={t(
                'dashboard.account.reportSettings.socialMediaAccountsText',
              )}
              formName="socialMediaAccounts"
              defaultValue={data?.pdfConfig.pdfSocialMediaAccounts}
            />
            <SwitchCard
              title={t('dashboard.account.reportSettings.sentimentAnalysis')}
              description={t(
                'dashboard.account.reportSettings.sentimentAnalysisText',
              )}
              formName="sentimentAnalysis"
              defaultValue={data?.pdfConfig.pdfSentimentAnalysis}
            />
            <SwitchCard
              title={t('dashboard.account.reportSettings.flaggedBehaviors')}
              description={t(
                'dashboard.account.reportSettings.flaggedBehaviorsText',
              )}
              formName="flaggedBehaviors"
              defaultValue={data?.pdfConfig.pdfFlaggedBehaviors}
            />
            <SwitchCard
              title={t('dashboard.account.reportSettings.positivePosts')}
              description={t(
                'dashboard.account.reportSettings.positivePostsText',
              )}
              formName="positivePosts"
              defaultValue={data?.pdfConfig.pdfPositivePosts}
            />
            <SwitchCard
              title={t('dashboard.account.reportSettings.negativePosts')}
              description={t(
                'dashboard.account.reportSettings.negativePostsText',
              )}
              formName="negativePosts"
              defaultValue={data?.pdfConfig.pdfNegativePosts}
            />
            <SwitchCard
              title={t('dashboard.account.reportSettings.neutralPosts')}
              description={t(
                'dashboard.account.reportSettings.neutralPostsText',
              )}
              formName="neutralPosts"
              defaultValue={data?.pdfConfig.pdfNeutralPosts}
            />
            <SwitchCard
              title={t('dashboard.account.reportSettings.webNewsPosts')}
              description={t(
                'dashboard.account.reportSettings.webNewsPostsText',
              )}
              defaultValue={data?.pdfConfig.pdfWebSearchPosts}
              formName="webNewsPosts"
            />
          </div>

          <div className="flex lg:justify-end lg:items-end">
            <Button
              disabled={!isDirty && language === data?.pdfConfig.pdfLanguage}
              type="submit"
              className="mt-6 w-full lg:w-[69px] px-4 py-2"
              isLoading={isLoadingUpdateUserPdfConfig}
            >
              {t('dashboard.account.reportSettings.save')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
