import { ArrowRight } from 'iconsax-react'
import React from 'react'

import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'

import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import { Chip } from '@/pages/Home/components/Chip/Chip'

interface CardWithButtonTypes {
  topText: string
  bottomText: string
  onClick?: () => void
  bgColor: string
  chipNumber?: string
  button?: boolean
}

export const CardWithButton = ({
  topText,
  bottomText,
  onClick,
  bgColor,
  chipNumber,
  button,
}: CardWithButtonTypes) => {
  return (
    <Card
      className={`flex justify-between p-4 ${bgColor} shadow-none border-0 rounded-xl items-center`}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center">
          <p className='text-neutralColor text-textNormal text-xl font-medium tracking-wide"'>
            {topText}
          </p>
          {chipNumber && (
            <Chip
              primaryBlue={chipNumber !== '0'}
              disabled={chipNumber === '0'}
              text={chipNumber}
            />
          )}
        </div>
        <p className="text-neutralColor80 text-base font-normal tracking-tight">
          {bottomText}
        </p>
      </div>
      {button && (
        <Button
          onClick={onClick}
          className="w-11 h-11 p-3 bg-primaryColor5 shadow-none rounded-full"
        >
          <ArrowRight size="32" color={weCheckPalette.neutralColor} />
        </Button>
      )}
    </Card>
  )
}
